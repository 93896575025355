import { useRef, useCallback, useState } from "react";
import axios from "axios";
import { Cookies } from "react-cookie";
import { API } from "../Helpers/action";
import { useNavigate } from "react-router-dom";

export const usePolicy = () => {
    const [error, setError] = useState(null);
    const [err, setErr] = useState(null);
    const [isLoading, setIsLoading] = useState(null);
    const [buyLoading, setIsBuyLoading] = useState(null);
    const [userid, setUserId] = useState(0);
    const [data, setData] = useState([]);
    const [config, setConfig] = useState({
        reference: "",
        email: "",
        amount: "",
        publicKey: "",
        channels: ["card", "bank_transfer"],
    });
    // const [virtualInfo, setVirtualInfo] = useState(null)
    const [healthconfig, setHealthConfig] = useState({
        reference: "",
        email: "",
        amount: "",
        publicKey: "",
        // split: {
        //     "type": "percentage",
        //     "bearer_type": "subaccounts",
        //     "subaccounts": []
        // },
        channels: ["card", "bank_transfer"],
    });
    let navigate = useNavigate();

    // console.log(window.location);

    const policy = useCallback(async () => {
        setIsLoading(true);
        setError(null);

        const cookies = new Cookies();
        let token = cookies.get("xhrToken");

        await axios
            .get(`${API}/vehicle/policy/agent`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
            })
            .then((response) => {
                setData(response.data.data);
                setIsLoading(false);
            })
            .catch((err) => {
                setIsLoading(false);
                setError(err.response.data.message);
                // console.log(err.response.data.message)
            });
    }, []);

    const healthpolicy = useCallback(async () => {
        setIsLoading(true);
        setError(null);

        const cookies = new Cookies();
        let token = cookies.get("xhrToken");

        await axios
            .get(`${API}/hmo/policy`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
            })
            .then((response) => {
                setData(response.data.data);
                setIsLoading(false);
            })
            .catch((err) => {
                setIsLoading(false);
                setError(err.response.data.message);
                // console.log(err.response.data.message)
            });
    }, []);

    const buy_thirdparty_policy = useCallback(
        async (data, setModal, mode, setEmailModal) => {
            setIsBuyLoading(true);
            setError(null);

            const cookies = new Cookies();
            let token = cookies.get("xhrToken");

            if (mode === "pac") {
                await axios
                    .post(`${API}/vehicle/thirdparty/agent/create`, data, {
                        headers: {
                            Authorization: `Bearer ${token}`,
                            "Content-Type": "application/json",
                        },
                    })
                    .then((response) => {
                        setData(response.data.data);
                        let data = {
                            user_vehicle_id: response.data.data.user_vehicle_id,
                        };
                        if (response.data.data) {
                            axios
                                .post(`${API}/vehicle/payment/init`, data, {
                                    headers: {
                                        Authorization: `Bearer ${token}`,
                                        "Content-Type": "application/json",
                                    },
                                })
                                .then((response) => {
                                    // console.log(response.data.data);
                                    setConfig({
                                        ...config,
                                        reference: response.data.data.reference,
                                        amount: response.data.data.amount,
                                        email: response.data.data.email,
                                        publicKey: response.data.data.public_key,
                                    });
                                    setModal(true);
                                });
                        }
                        setIsBuyLoading(false);
                    })
                    .catch((err) => {
                        setIsBuyLoading(false);
                        setError(err.response.data.message);
                        console.log(err.response);
                    });
            } else {
                await axios
                    .post(`${API}/vehicle/thirdparty/agent/create`, data, {
                        headers: {
                            Authorization: `Bearer ${token}`,
                            "Content-Type": "application/json",
                        },
                    })
                    .then((response) => {
                        setData(response.data.data);
                        let data = {
                            user_vehicle_id: response.data.data.user_vehicle_id,
                        };
                        if (response.data.data) {
                            axios
                                .post(`${API}/broker/email/payment`, data, {
                                    headers: {
                                        Authorization: `Bearer ${token}`,
                                        "Content-Type": "application/json",
                                    },
                                })
                                .then((response) => {
                                    // console.log(response.data.data);
                                    setEmailModal(true);
                                });
                        }
                        setIsBuyLoading(false);
                    })
                    .catch((err) => {
                        setIsBuyLoading(false);
                        setError(err.response.data.message);
                        console.log(err.response);
                    });
            }
        },
        [config]
    );

    const create_hmo_policy = useCallback(
        async (data) => {
            setIsLoading(true);
            setError(null);

            const cookies = new Cookies();
            let token = cookies.get("xhrToken");

            try {
                const response = await axios.post(`${API}/hmo/policy/bulk`, data, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "multipart/form-data",
                    },
                });

                console.log(response.data.data);
                setIsLoading(false);
                return response.data.data; // Return the data
            } catch (err) {
                setIsLoading(false);
                setError(err.response.data.message);
                console.log(err.response);
                throw err; // Rethrow the error if necessary
            }
        },
        [setIsLoading, setError]
    );

    const update_hmo_policy = useCallback(
        async (data) => {
            setIsLoading(true);
            setError(null);

            const cookies = new Cookies();
            let token = cookies.get("xhrToken");

            try {
                const response = await axios.patch(`${API}/hmo/policy/passport`, data, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "multipart/form-data",
                    },
                });

                console.log(response.data.data);
                setIsLoading(false);
                return response.data.data; // Return the data
            } catch (err) {
                setIsLoading(false);
                setError(err.response.data.message);
                console.log(err.response);
                throw err; // Rethrow the error if necessary
            }
        },
        []
    );

    const getListOfBulkHmo = useCallback(
        async (ref) => {
            setIsLoading(true);
            setError(null);

            const cookies = new Cookies();
            let token = cookies.get("xhrToken");

            try {
                const response = await axios.get(`${API}/health/bulkpolicy/${ref}`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "multipart/form-data",
                    },
                });

                console.log(response.data.data);
                setIsLoading(false);
                return response.data.data; // Return the data
            } catch (err) {
                setIsLoading(false);
                setError(err.response.data.message);
                console.log(err.response);
                throw err; // Rethrow the error if necessary
            }
        },
        []
    );

    const verifyhmopolicy = useCallback(
        async (data) => {
            setIsLoading(true);
            setError(null);

            const cookies = new Cookies();
            let token = cookies.get("xhrToken");

            try {
                const response = await axios.post(`${API}/hmo/payment/bulk/verify`, data, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "application/json",
                    },
                });

                console.log(response.data.data);
                setIsLoading(false);
                return response.data.data; // Return the data
            } catch (err) {
                setIsLoading(false);
                setError(err.response.data.message);
                console.log(err.response);
                throw err; // Rethrow the error if necessary
            }
        },
        []
    );


    const healthbulk = useCallback(
        async (data) => {
            setIsLoading(true);
            setError(null);

            const cookies = new Cookies();
            let token = cookies.get("xhrToken");

            try {
                const response = await axios.post(`${API}/broker/hmo/bulk/email/payment`, data, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "application/json",
                    },
                });

                console.log(response.data.data);
                setIsLoading(false);
                return response.data.data; // Return the data
            } catch (err) {
                setIsLoading(false);
                setError(err.response.data.message);
                console.log(err.response);
                throw err; // Rethrow the error if necessary
            }
        },
        []
    );

    const Resend_Email = useCallback(async (user_id, setEmailModal) => {
        setIsLoading(true);
        setError(null);

        let data = {
            user_vehicle_id: user_id,
        };

        const cookies = new Cookies();
        let token = cookies.get("xhrToken");

        await axios
            .post(`${API}/broker/email/payment`, data, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
            })
            .then((response) => {
                setIsLoading(false);
                // console.log(response.data.data);
                setEmailModal(true);
            })
            .catch((err) => {
                setIsLoading(false);
                setError(err.response.data.message);
                // console.log(err.response.data.message)
            });
    }, []);

    const Health_Resend_Email = useCallback(async (user_id, setEmailModal) => {
        setIsLoading(true);
        setError(null);

        let data = {
            user_hmo_id: user_id,
        };

        const cookies = new Cookies();
        let token = cookies.get("xhrToken");

        await axios
            .post(`${API}/broker/hmo/email/payment`, data, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
            })
            .then((response) => {
                setIsLoading(false);
                // console.log(response.data.data);
                setEmailModal(true);
            })
            .catch((err) => {
                setIsLoading(false);
                setError(err.response.data.message);
                // console.log(err.response.data.message)
            });
    }, []);

    const buy_vehicle_policy = useCallback(async (data, setTransferModal) => {
        setIsBuyLoading(true);
        setError(null);

        const cookies = new Cookies();
        let token = cookies.get("xhrToken");

        if (data.vehicle_category_id === 1) {
            await axios
                .post(`${API}/vehicle/thirdparty/agent/create`, data, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "application/json",
                    },
                })
                .then((response) => {
                    // setData(response.data.data)
                    setUserId(response.data.data.user_vehicle_id);
                    console.log(response);
                    setTransferModal(true);
                    setIsBuyLoading(false);
                    // navigate('/policy/vehicle/all')
                })
                .catch((err) => {
                    setIsBuyLoading(false);
                    setError(err.response.data.message);
                    // console.log(err.response.data.message)
                });
        } else {
            await axios
                .post(`${API}/vehicle/comprehensive/agent/create`, data, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "application/json",
                    },
                })
                .then((response) => {
                    console.log(response);
                    setUserId(response.data.data.user_vehicle_id);
                    setIsBuyLoading(false);
                    setTransferModal(true);
                    // navigate('/policy/vehicle/all')
                })
                .catch((err) => {
                    setIsBuyLoading(false);
                    setError(err.response.data.message);
                    // console.log(err.response.data.message)
                });
        }
    }, []);

    const buy_comprehensive_policy = useCallback(
        async (data, setModal, mode, setEmailModal) => {
            setIsBuyLoading(true);
            setError(null);

            const cookies = new Cookies();
            let token = cookies.get("xhrToken");

            if (mode === "pac") {
                await axios
                    .post(`${API}/vehicle/comprehensive/agent/create`, data, {
                        headers: {
                            Authorization: `Bearer ${token}`,
                            "Content-Type": "application/json",
                        },
                    })
                    .then((response) => {
                        setData(response.data.data);
                        let data = {
                            user_vehicle_id: response.data.data.user_vehicle_id,
                        };
                        if (response.data.data) {
                            axios
                                .post(`${API}/vehicle/payment/init`, data, {
                                    headers: {
                                        Authorization: `Bearer ${token}`,
                                        "Content-Type": "application/json",
                                    },
                                })
                                .then((response) => {
                                    // console.log(response.data.data);
                                    setConfig({
                                        ...config,
                                        reference: response.data.data.reference,
                                        amount: response.data.data.amount,
                                        email: response.data.data.email,
                                        publicKey: response.data.data.public_key,
                                    });
                                    setModal(true);
                                });
                        }
                        setIsBuyLoading(false);
                    })
                    .catch((err) => {
                        setIsBuyLoading(false);
                        setError(err.response.data.message);
                        // console.log(err.response.data.message)
                    });
            } else {
                await axios
                    .post(`${API}/vehicle/comprehensive/agent/create`, data, {
                        headers: {
                            Authorization: `Bearer ${token}`,
                            "Content-Type": "application/json",
                        },
                    })
                    .then((response) => {
                        setData(response.data.data);
                        let data = {
                            user_vehicle_id: response.data.data.user_vehicle_id,
                        };
                        if (response.data.data) {
                            axios
                                .post(`${API}/broker/email/payment`, data, {
                                    headers: {
                                        Authorization: `Bearer ${token}`,
                                        "Content-Type": "application/json",
                                    },
                                })
                                .then((response) => {
                                    // console.log(response.data.data);
                                    setEmailModal(true);
                                });
                        }
                        setIsBuyLoading(false);
                    })
                    .catch((err) => {
                        setIsBuyLoading(false);
                        setError(err.response.data.message);
                        // console.log(err.response.data.message)
                    });
            }
        },
        [config]
    );

    const virtualInfo = useRef({})
    const buy_health_policy = useCallback(
        async (data, setmodal, mode, setEmailModal, setShowModal) => {
            setIsLoading(true);
            setError(null);
            console.log(mode)
            const cookies = new Cookies();
            let token = cookies.get("xhrToken");

            if (mode === "pac") {
                await axios
                    .post(`${API}/hmo/policy/create`, data, {
                        headers: {
                            Authorization: `Bearer ${token}`,
                            "Content-Type": "application/json",
                        },
                    })
                    .then((response) => {
                        setData(response.data.data);
                        // console.log(response);
                        let data = {
                            user_hmo_id: response.data.data.user_hmo_id,
                        };
                        if (response.data.data) {
                            axios
                                .post(`${API}/hmo/payment/init`, data, {
                                    headers: {
                                        Authorization: `Bearer ${token}`,
                                        "Content-Type": "application/json",
                                    },
                                })
                                .then((response) => {
                                    // console.log(response.data.data);
                                    // console.log('yesyes');
                                    let percent =
                                        response.data.data.flatfee / response.data.data.amount;
                                    percent = percent * 1000;
                                    console.log(percent);
                                    setHealthConfig({
                                        ...config,
                                        reference: response.data.data.reference,
                                        amount: response.data.data.amount,
                                        email: response.data.data.email,
                                        publicKey: response.data.data.public_key,
                                        subaccount: response.data.data.subaccount,
                                        transaction_charge: response.data.data.flatfee,
                                        bearer: "subaccount",
                                        // bearer_subaccount: response.data.data.subaccount,
                                        // split: {
                                        //     "type": "percentage",
                                        //     "bearer_type": "all",
                                        //     "subaccounts": [
                                        //         {
                                        //             "subaccount": response.data.data.subaccount,
                                        //             "share": response.data.data.flatfee,
                                        //         }
                                        //     ]
                                        // }
                                        // bearer: "subaccount"
                                    });
                                    setmodal(true);
                                });
                        }
                        setIsLoading(false);
                    })
                    .catch((err) => {
                        setIsLoading(false);
                        // console.log(err, 'w');
                        setErr(err.response.data.message);
                        // console.log(err.response.data.message)
                    });
            }
            else if (mode === "pat") {
                await axios
                    .post(`${API}/hmo/policy/create`, data, {
                        headers: {
                            Authorization: `Bearer ${token}`,
                            "Content-Type": "application/json",
                        },
                    })
                    .then((response) => {
                        virtualInfo.current = response.data.data;
                        setShowModal(true)
                        let data =  response.data.data
                        setIsLoading(false);
                        return data
                    })
                    .catch((err) => {
                        setIsLoading(false);
                        // console.log(err, 'r');
                        setErr(err.response.data.message);
                        // console.log(err.response.data.message)
                    });
            } else {
                await axios
                    .post(`${API}/hmo/policy/create`, data, {
                        headers: {
                            Authorization: `Bearer ${token}`,
                            "Content-Type": "application/json",
                        },
                    })
                    .then((response) => {
                        // setData(response.data.data)
                        let data = {
                            user_hmo_id: response.data.data.user_hmo_id,
                        };
                        if (response.data.data) {
                            axios
                                .post(`${API}/broker/hmo/email/payment`, data, {
                                    headers: {
                                        Authorization: `Bearer ${token}`,
                                        "Content-Type": "application/json",
                                    },
                                })
                                .then((response) => {
                                    // console.log(response);
                                    setIsLoading(false);
                                    setEmailModal(true);
                                });
                        }
                    })
                    .catch((err) => {
                        setIsLoading(false);
                        // console.log(err, 'r');
                        setErr(err.response.data.message);
                        // console.log(err.response.data.message)
                    });
            }
        },
        [config]
    );

    const renew_health_policy = useCallback(
        async (data, setEmailModal) => {
            setIsLoading(true);
            setError(null);

            const cookies = new Cookies();
            let token = cookies.get("xhrToken");
                await axios
                    .post(`${API}/hmo/policy/renew`, data, {
                        headers: {
                            Authorization: `Bearer ${token}`,
                            "Content-Type": "application/json",
                        },
                    })
                    .then((response) => {
                        // setData(response.data.data)
                        let data = {
                            user_hmo_id: response.data.data.user_hmo_id,
                        };
                        if (response.data.data) {
                            axios.post(`${API}/broker/hmo/email/payment`, data, {
                                    headers: {
                                        Authorization: `Bearer ${token}`,
                                        "Content-Type": "application/json",
                                    },
                                })
                                .then((response) => {
                                    // console.log(response);
                                    setIsLoading(false);
                                    setEmailModal(true)
                                });
                        }
                    })
                    .catch((err) => {
                        setIsLoading(false);
                        // console.log(err, 'r');
                        setErr(err.response.data.message);
                        // console.log(err.response.data.message)
                    });
        },
        []
    );

    const complete_payment = useCallback(
        async (data, setModal) => {
            setIsLoading(true);
            setError(null);

            const cookies = new Cookies();
            let token = cookies.get("xhrToken");

            let user = {
                user_vehicle_id: data,
            };
            axios
                .post(`${API}/vehicle/payment/init`, user, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "application/json",
                    },
                })
                .then((response) => {
                    setConfig({
                        ...config,
                        reference: response.data.data.reference,
                        amount: response.data.data.amount,
                        email: response.data.data.email,
                        publicKey: response.data.data.public_key,
                    });
                    setIsLoading(false);
                    setModal(true);
                })
                .catch((err) => {
                    setIsLoading(false);
                    setError(err.response.data.message);
                    // console.log(err.response.data.message)
                });
        },
        [config]
    );

    const verifyhealthpolicy = useCallback(
        async (data) => {
            setIsLoading(true);
            setError(null);

            let reference = {
                reference: data,
            };

            const cookies = new Cookies();
            let token = cookies.get("xhrToken");

            await axios
                .post(`${API}/hmo/payment/verify`, reference, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "application/json",
                    },
                })
                .then((response) => {
                    navigate("/policy/health");
                    setIsLoading(false);
                })
                .catch((err) => {
                    setIsLoading(false);
                    setError(err.response.data.message);
                    // console.log(err.response.data.message)
                });
        },
        [navigate]
    );

    const cancelpolicy = useCallback(async (data, setModal) => {
        setIsLoading(true);
        setError(null);

        const cookies = new Cookies();
        let token = cookies.get("xhrToken");

        // let user = {
        //     "user_vehicle_id": data,
        //     'reason': data
        // }
        axios
            .post(`${API}/vehicle/policy/cancel`, data, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
            })
            .then((response) => {
                setIsLoading(false);
                setModal(true);
            })
            .catch((err) => {
                setIsLoading(false);
                setError(err.response.data.message);
                // console.log(err.response.data.message)
            });
    }, []);

    const individual_healthpolicy = useCallback(async (data) => {
        setIsLoading(true);
        setError(null);

        const cookies = new Cookies();
        let token = cookies.get("xhrToken");

        axios
            .get(`${API}/broker/health/${data}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
            })
            .then((response) => {
                // console.log(response);
                setIsLoading(false);
                setData(response.data.data);
            })
            .catch((err) => {
                setIsLoading(false);
                setError(err.response.data.message);
                // console.log(err.response.data.message)
            });
    }, []);

    const individual_vehiclepolicy = useCallback(async (data) => {
        setIsLoading(true);
        setError(null);

        const cookies = new Cookies();
        let token = cookies.get("xhrToken");

        axios
            .get(`${API}/broker/vehicle/${data}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
            })
            .then((response) => {
                // console.log(response);
                setIsLoading(false);
                setData(response.data.data.records);
            })
            .catch((err) => {
                setIsLoading(false);
                setError(err.response.data.message);
                // console.log(err.response.data.message)
            });
    }, []);

    const verifypaymentreciept = useCallback(
        async (userid, data, page) => {
            setIsBuyLoading(true);
            // setIsLoading(true)
            setError(null);

            let reference = {
                user_vehicle_id: userid,
                receipt_image: data.receipt_image,
            };
            // console.log(reference);

            const cookies = new Cookies();
            let token = cookies.get("xhrToken");

            await axios
                .patch(`${API}/vehicle/receipt`, reference, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "application/json",
                    },
                })
                .then((response) => {
                    // navigate('/policy/health')
                    setIsBuyLoading(false);
                    if (page === "policy") {
                        window.location.reload();
                    } else {
                        navigate("/policy/vehicle/all");
                    }
                })
                .catch((err) => {
                    setIsBuyLoading(false);
                    // setIsLoading(false)
                    setError(err.response.data.message);
                    // console.log(err.response.data.message)
                });
        },
        [navigate]
    );

    return {
        cancelpolicy,
        policy,
        getListOfBulkHmo,
        verifypaymentreciept,
        create_hmo_policy,
        userid,
        buy_vehicle_policy,
        err,
        Health_Resend_Email,
        Resend_Email,
        buy_thirdparty_policy,
        individual_vehiclepolicy,
        individual_healthpolicy,
        complete_payment,
        healthpolicy,
        buy_comprehensive_policy,
        buy_health_policy,
        data,
        verifyhealthpolicy,
        config,
        healthconfig,
        isLoading,
        buyLoading,
        error,
        update_hmo_policy,
        healthbulk,
        verifyhmopolicy,
        renew_health_policy,
        virtualInfo,
    };
};
