import React, { useState } from 'react'
import '../Stylesheets/layout.css';
import { Route, Routes } from "react-router-dom";
import Dashboard from './Dashboard';
import Sidebar from '../Components/Layout/Sidebar';
// import Navbar from '../Components/Navbar';
import  Navbarimg from '../Assets/Images/navbarimg.svg'
import  { ReactComponent as Footerimg } from '../Assets/Images/footerimage.svg'
import Customer from './Customer';
import Claims from './Claims';
import Policy from './Policy';
import Wallet from './Wallet';
// import Transaction from './Transaction';
import Profile from './Profile';
import Navbar from '../Components/Layout/Navbar';
import Vechiclepolicy from './Vehiclepolicy';
import Healthpolicy from './Healthpolicy';
import Vehicleclaim from './Vehicleclaim';
import Buyvehicle from './Buy-vehiclepolicy';
import Buyhealth from './Buy-healthpolicy';
import Buyhealthcoporate from './Buy-healthcoporate';
import Vehicle from './Vehicle';
import Health from './Health';
import Travel from './Travel/Travel';
import Makeclaim from './Make-claim';
import Buytravel from "./Travel/buypolicy";
import Travelpolicy from "./Travel/Allpolicies";
import Gadget from "./Gadget/Gadget";
import Buygadget from "./Gadget/buypolicy";
import Gadgetpolicy from "./Gadget/Allpolicies";

function Layout() {
    const [show, setShow] = useState(true)
    const [mobileshow, setMobileShow] = useState(false)
  return (
    <div>
        <div className={`layout ${show ? 'close' : 'open'} `}>
            <div className='sidebar'>
                <Sidebar setShow={setShow} show={show} mobileshow={mobileshow} setMobileShow={setMobileShow} />
            </div>
            <div className='maincontent'>
                <img src={Navbarimg} alt='back' />
                <Navbar setMobileShow={setMobileShow} show={show}/>
                <Routes>
                    <Route path='dashboard' index exact element={<Dashboard/>} />
                    <Route path='customer' index exact element={<Customer/>} />
                    <Route path='claims' index exact element={<Claims />} />
                    <Route path='make-claims' index exact element={<Makeclaim />} />
                    <Route path='policy' index exact element={<Policy />} />
                    <Route path='policy/vehicle' index exact element={<Vehicle />} />
                    <Route path='policy/health' index exact element={<Health />} />
                    <Route path='policy/travel' index exact element={<Travel/>} />
                    <Route path='policy/gadget' index exact element={<Gadget/>} />
                    <Route path='policy/vehicle/all' index exact element={<Vechiclepolicy />} />
                    <Route path='policy/health/all' index exact element={<Healthpolicy />} />
                    <Route path='policy/vehicle/claim' index exact element={<Vehicleclaim />} />
                    <Route path='policy/travel/all' index exact element={<Travelpolicy />} />
                    <Route path='policy/travel/buy' index exact element={<Buytravel />} />
                    <Route path='policy/gadget/all' index exact element={<Gadgetpolicy />} />
                    <Route path='policy/gadget/buy' index exact element={<Buygadget />} />
                    <Route path='policy/vehicle/buy' index exact element={<Buyvehicle />} />
                    <Route path='policy/health/buy' index exact element={<Buyhealth />} />
                    <Route path='policy/health/buy-coporate' index exact element={<Buyhealthcoporate />} />
                    <Route path='account' index exact element={<Wallet />} />
                    {/* <Route path='transaction' index exact element={<Transaction />} /> */}
                    <Route path='profile' index exact element={<Profile />} />
                </Routes>
                <div className='footer-container'>
                    <div className='footer-layer1'>
                        <Footerimg />
                    </div>
                    <div className='footer-layer2'>
                        <Footerimg />
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Layout



