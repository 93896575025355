import React, {useEffect, useState} from "react";
import Modal from "react-bootstrap/Modal";
import { useCustomer } from "../../Hooks/customer";
import Loader from "../Loader/Loader";
import {Cookies} from "react-cookie";

function Editcustomer(props) {
    const { editCustomer, isLoading, error } = useCustomer();
    const [lga, setLga] = useState([]);
    const [data, setData] = useState({
        firstname: "",
        lastname: "",
        email: "",
        phone: "",
        address: "",
        state_id: 0,
        lga_id: 0,
        dob: "",
        gender_id: ""

    });
    const cookies = new Cookies()
    const [stateAvailable, setStateAvailable] = useState(false)

    const stateOnchange = (e) => {
        setStateAvailable(true)
        setData({ ...data, state_id: JSON.parse(e.target.value) });
        const searchObject = props.states.find(
            (state) => state.id === JSON.parse(e.target.value)
        );
        console.log(searchObject);
        setLga(searchObject.lgas);
    };

    const ValidatePhone = (e) => {
        const inputValue = e.target.value;
        // Define a regular expression to match a valid phone number format
        const phoneRegex = /^[0-9]{0,11}$/; // Assuming a 10-digit phone number

        if (phoneRegex.test(inputValue)) {
            // If the input matches the regex, update the state
            setData({ ...data, [e.target.name]: inputValue })
        }
    };
    let getinfo = cookies.get('xirhnfo')
    // const setLgas = () => {
    //     let info = JSON.parse(atob(getinfo))
    //     const searchObject = props.states.find(
    //         (state) => state.id === info.state_id
    //     );
    //     console.log(searchObject);
    //     setLga(searchObject?.lgas);
    // };

    const editDetails = async(e) => {
        e.preventDefault();
        let info = {
            user_id: data.id,
            firstname: data.firstname,
            lastname: data.lastname,
            email: data.email,
            phone:data.phone,
            address: data.address,
            state_id: data.state_id,
            lga_id: data.lga_id,
            gender_id: data.gender_id,
            date_of_birth: data.dob,
        }
        await editCustomer(info)
        props.onHide()
        // window.location.reload(true)
    }

    useEffect(() => {
        if (getinfo) {
            let info = JSON.parse(atob(getinfo))
            console.log(info)
            if (info) {
                let newData = {};
                for (let key in info) {
                    newData[key] = info[key];
                }
                setData(newData);
            }
        }
    }, [getinfo])

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Body>
                <form className="form-tab">
                    <div className="input-group">
                        <div>
                            <label>First Name</label>
                            <input
                                type="text"
                                name="firstname"
                                value={data?.firstname}
                                onChange={(e) =>
                                    setData({ ...data, [e.target.name]: e.target.value })
                                }
                            />
                        </div>
                        <div>
                            <label>Last Name</label>
                            <input
                                type="text"
                                placeholder="Obasa"
                                name="lastname"
                                value={data?.lastname}
                                onChange={(e) =>
                                    setData({ ...data, [e.target.name]: e.target.value })
                                }
                            />
                        </div>
                    </div>
                    <div className="input-group">
                        <div>
                            <label>Email</label>
                            <input
                                type="email"
                                placeholder="email@email.com"
                                name="email"
                                value={data?.email}
                                onChange={(e) =>
                                    setData({ ...data, [e.target.name]: e.target.value })
                                }
                            />
                        </div>
                        <div>
                            <label>Phone Number</label>
                            <input
                                type="tel"
                                placeholder="08012345678"
                                name="phone"
                                value={data.phone || ''}
                                onChange={ValidatePhone}
                            />
                        </div>
                    </div>
                    <div className="input-group">
                        <div>
                            <label>States of residence</label>
                            <select value={data?.state_id} onChange={stateOnchange}>
                                <option>Select state</option>
                                {props.states.map((state, index) => {
                                    return (
                                        <option key={index} value={state.id}>
                                            {state.name}
                                        </option>
                                    );
                                })}
                            </select>
                        </div>
                        <div>
                            <label>LGA</label>
                            <select value={data?.lga_id} onChange={(e) => setData({...data, lga_id: JSON.parse(e.target.value)})}>
                                <option>Select lga</option>
                                {stateAvailable ?
                                    lga.map((lga, index) => {
                                    return (
                                        <option key={index} value={lga.id}>
                                            {lga.name}
                                        </option>
                                    );
                                }) :
                                    props.lga.map((lga, index) => {
                                        return (
                                            <option key={index} value={lga.id}>
                                                {lga.name}
                                            </option>
                                        );
                                    })}
                            </select>
                        </div>
                    </div>
                    <div className="input-group">
                        <div>
                            <label>Date of Birth</label>
                            <input type="date" value={data?.dob} onChange={(e) => setData({...data, dob: e.target.value})} />
                            {/* <select onChange={stateOnchange}>
                                <option>Select state</option>
                                {props.states.map((state, index) => {
                                    return (
                                        <option key={index} value={state.id}>
                                            {state.name}
                                        </option>
                                    );
                                })}
                            </select> */}
                        </div>
                        <div>
                            <label>Gender</label>
                            <select value={data?.gender_id} onChange={(e) => setData({...data, gender_id: JSON.parse(e.target.value)})}>
                                <option>Select gender</option>
                                {props.genders.map((gender, index) => {
                                    return (
                                        <option key={index} value={gender.id}>
                                            {gender.name}
                                        </option>
                                    );
                                })}
                            </select>
                        </div>
                    </div>
                    <div>
                        <label>address</label>
                        <textarea
                            placeholder="Enter address"
                            rows={3}
                            name="address"
                            value={data?.address}
                            onChange={(e) =>
                                setData({ ...data, [e.target.name]: e.target.value })
                            }
                        />
                    </div>

                    <div>
                        <p>{error}</p>
                    </div>
                    <div className="customer-button">
                        <button onClick={editDetails}>Submit</button>
                    </div>
                </form>
            </Modal.Body>
            {isLoading ? <Loader /> : ""}
        </Modal>
    );
}

export default Editcustomer;
