import { useCallback, useState } from 'react'
import axios from 'axios'
import { Cookies } from 'react-cookie';
import { API } from '../Helpers/action';

export const useTravel = () => {
    const [error, setError] = useState(null)
    const [quoteError, setQuoteError] = useState(null)
    const [isLoading, setIsLoading] = useState(null)
    const [bookingType, setBookingType] = useState([])
    const [data, setData] = useState([])
    const [title, setTitle] = useState([])


    const getCountry = useCallback(
        async () => {
            setIsLoading(true)
            setError(null)

            const cookies = new Cookies();
            let token =cookies.get('xhrToken')

            await axios.get(`${API}/country`,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                } ).then((response) => {
                // console.log(response);
                setData(response.data.data)
                setIsLoading(false)
                return response.data.data
            }).catch((err) => {
                setIsLoading(false)
                setError(err.response.data.message)
                // console.log(err.response.data.message)
            })
        }, []
    )

    const getBookingType = useCallback(
        async () => {
            setIsLoading(true)
            setError(null)

            const cookies = new Cookies();
            let token =cookies.get('xhrToken')

            await axios.get(`${API}/travel-category`,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                } ).then((response) => {
                // console.log(response);
                setBookingType(response.data.data)
                setIsLoading(false)
                return response.data.data
            }).catch((err) => {
                setIsLoading(false)
                setError(err.response.data.message)
                // console.log(err.response.data.message)
            })
        }, []
    )
    const [quote, setQuote] = useState()

    const travelQuote = useCallback(
        async (data, setPreviewQuote) => {
            setIsLoading(true)
            setError(null)

            const cookies = new Cookies();
            let token =cookies.get('xhrToken')

            await axios.post(`${API}/allianz/travel/quote`, data,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                } ).then((response) => {
                // console.log(response);
                setQuote(response.data.data)
                setPreviewQuote(true)
                setIsLoading(false)

            }).catch((err) => {
                setIsLoading(false)
                setQuoteError(err.response.data.message)
                // console.log(err.response.data.message)
            })
        }, []
    )

    const getTitle = useCallback(
        async () => {
            setIsLoading(true)
            setError(null)

            const cookies = new Cookies();
            let token =cookies.get('xhrToken')

            await axios.get(`${API}/titles`,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                } ).then((response) => {
                console.log(response.data.data);
                setTitle(response.data.data)
                // console.log(resources.title)
                setIsLoading(false)
            }).catch((err) => {
                setIsLoading(false)
                setError(err.response.data.message)
                // console.log(err.response.data.message)
            })
        }, []
    )

    const [states,setState] =  useState([])
    const getStates = useCallback(
        async () => {
            setIsLoading(true)
            setError(null)

            const cookies = new Cookies();
            let token =cookies.get('xhrToken')

            await axios.get(`${API}/states`,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                } ).then((response) => {
                // console.log(response);
                setState( response.data.data.states)
                setIsLoading(false)
            }).catch((err) => {
                setIsLoading(false)
                setError(err.response.data.message)
                // console.log(err.response.data.message)
            })
        }, []
    )

    const [gender, setGender] = useState([])
    const getGender = useCallback(
        async () => {
            setIsLoading(true)
            setError(null)

            const cookies = new Cookies();
            let token =cookies.get('xhrToken')

            await axios.get(`${API}/gender`,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                } ).then((response) => {
                // console.log(response);
                setGender(response.data.data)
                setIsLoading(false)
            }).catch((err) => {
                setIsLoading(false)
                setError(err.response.data.message)
                // console.log(err.response.data.message)
            })
        }, []
    )

    const [maritalstatus, setMaritalStatus] = useState([])
    const getMaritalStatus = useCallback(
        async () => {
            setIsLoading(true)
            setError(null)

            const cookies = new Cookies();
            let token =cookies.get('xhrToken')

            await axios.get(`${API}/marital-status`,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                } ).then((response) => {
                // console.log(response);
                setMaritalStatus( response.data.data)
                setIsLoading(false)
            }).catch((err) => {
                setIsLoading(false)
                setError(err.response.data.message)
                // console.log(err.response.data.message)
            })
        }, []
    )

    const buyTravel = useCallback(
            async (data, setEmailModal) => {
                setIsLoading(true)
                setError(null)

                const cookies = new Cookies();
                let token =cookies.get('xhrToken')

                await axios.post(`${API}/allianz/travel/policy`, data,
                    {
                        headers: {
                            'Authorization': `Bearer ${token}`,
                            'Content-Type': 'application/json'
                        }
                    } ).then(async (response) => {
                    console.log(response.data.data);
                    // setQuote(response.data.data)
                    let emaildata = {
                        "user_travel_id": response.data.data.user_travel_id,
                        "email": data.email
                    }
                    await axios.post(`${API}/broker/travel/email/payment`, emaildata,
                        {
                            headers: {
                                'Authorization': `Bearer ${token}`,
                                'Content-Type': 'application/json'
                            }
                        }).then((response) => {
                        console.log(response.data.data);
                        // setQuote(response.data.data)
                        setEmailModal(true)
                        setIsLoading(false)

                    }).catch((err) => {
                        setIsLoading(false)
                        setError(err.response.data.message)
                        // console.log(err.response.data.message)
                    })
                    // setEmailModal(true)
                    // setIsLoading(false)

                }).catch((err) => {
                    setIsLoading(false)
                    setError(err.response.data.message)
                    // console.log(err.response.data.message)
                })
            }, []
        )

    const buyFamilyTravel = useCallback(
        async (data, setEmailModal, email) => {
            setIsLoading(true)
            setError(null)

            const cookies = new Cookies();
            let token =cookies.get('xhrToken')

            await axios.post(`${API}/allianz/travel/family/policy`, data,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                } ).then(async (response) => {
                console.log(response.data.data);
                // setQuote(response.data.data)
                let data = {
                    "user_travel_id": response.data.data.user_travel_id,
                    "email": email
                }
                await axios.post(`${API}/broker/travel/email/payment`, data,
                    {
                        headers: {
                            'Authorization': `Bearer ${token}`,
                            'Content-Type': 'application/json'
                        }
                    }).then((response) => {
                    console.log(response.data.data);
                    // setQuote(response.data.data)
                    setEmailModal(true)
                    setIsLoading(false)

                }).catch((err) => {
                    setIsLoading(false)
                    setError(err.response.data.message)
                    // console.log(err.response.data.message)
                })
                // setEmailModal(true)
                // setIsLoading(false)

            }).catch((err) => {
                setIsLoading(false)
                setError(err.response.data.message)
                // console.log(err.response.data.message)
            })
        }, []
    )

    const [policy, setPolicy] = useState([])
    const getAllPolicies = useCallback(
        async () => {
            setIsLoading(true)
            setError(null)

            const cookies = new Cookies();
            let token =cookies.get('xhrToken')

            await axios.get(`${API}/travel/policy`,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                } ).then((response) => {
                // console.log(response);
                setPolicy(response.data.data)
                setIsLoading(false)
            }).catch((err) => {
                setIsLoading(false)
                setError(err.response.data.message)
                // console.log(err.response.data.message)
            })
        }, []
    )
    return { getCountry, data, getBookingType, bookingType, policy, getAllPolicies, travelQuote, quote, isLoading, error, getTitle, title, getGender, getStates, getMaritalStatus, states, gender, maritalstatus, buyTravel, buyFamilyTravel, quoteError}
}
