import React, { useEffect, useState } from 'react'
import Table from 'react-bootstrap/Table';
import { ReactComponent as Arrow } from "../Assets/icons/back-arrow.svg";
import { Link, useNavigate } from "react-router-dom";
import { useClaims } from '../Hooks/claims';
import ReactPaginate from 'react-paginate';
import { ReactComponent as Nexticon } from "../Assets/icons/nextarrw.svg";
import { ReactComponent as Previcon } from "../Assets/icons/prevarrw.svg";
import Loader from '../Components/Loader/Loader';
import { Cookies } from 'react-cookie';

function Vehicleclaim() {
    const navigate = useNavigate()
    const cookies = new Cookies()
    const { getclaim, data, isLoading, individual_claims } = useClaims()
    const [itemOffset, setItemOffset] = useState(0);
    let itemsPerPage = 7
    const endOffset = itemOffset + itemsPerPage;
    const pageCount = Math.ceil(data.length / itemsPerPage);
    let getinfo = cookies.get('xirhnfo')

    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % data.length;

        setItemOffset(newOffset);
    };

    useEffect(() => {
        if (getinfo !== undefined) {
            let info = JSON.parse(atob(getinfo))
            // console.log(info);
            individual_claims(info.enrollee_id)
        } else {
            getclaim()
        }
    }, [getclaim, getinfo, individual_claims])

    return (
        <div>
            <div className="navigate-back">
                <Link onClick={() => navigate(-1)}>
                    <Arrow />
                </Link>
            </div>
            <div className="vehicle-policy-container">
                <h4 className="dashboard-title">Vechicle Claims</h4>
                <div className='claims-table-container'>
                    <Table>
                        <thead>
                            <tr>
                                <th>S/N</th>
                                <th>Customer</th>
                                <th>Email</th>
                                <th>Policy Number</th>
                                <th>Policy Type</th>
                                <th>Start Date</th>
                                <th>Underwriter</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        {
                            data.length === 0 ? <tbody>
                                <tr >
                                    <td colSpan={8} className='no-data-table' >
                                        No data available in table
                                    </td>
                                </tr>
                            </tbody> : <tbody className='table-content'>
                                {
                                    data.slice(itemOffset, endOffset).map((data, index) => {
                                        return (
                                            <tr key={index}>
                                                <td>{itemOffset + 1 + index++}</td>
                                            <td>{data.user.lastname + " " + data.user.firstname}</td>
                                            <td>{data.user.email}</td>
                                            <td>{data.metadata.policy_number}</td>
                                            <td>{data.policy_type}</td>
                                            <td>{data.timestamp}</td>
                                            <td>{data.underwriter.name}</td>
                                            <td>{data.status}</td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        }

                    </Table>
                    <ReactPaginate
                    breakLabel="..."
                    previousLabel={<Previcon />}
                    nextLabel={<Nexticon />}
                    pageCount={pageCount}
                    onPageChange={handlePageClick}
                    containerClassName={"paginatecontainer"}
                    previousLinkClassName={"prevbtn"}
                    pageClassName={"pageli"}
                    nextLinkClassName={"nextbtn"}
                    disabledClassName={"paginationdisabled"}
                    activeClassName={"paginationactive"}
                    breakClassName={"breakli"}
                    renderOnZeroPageCount={null}
                    pageRangeDisplayed={3}
                    marginPagesDisplayed={2}
                />
                </div>
                {isLoading ? <Loader /> : ''}
            </div>
        </div>
    )
}

export default Vehicleclaim