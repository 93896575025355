import { useCallback, useState } from 'react'
import axios from 'axios'
import { Cookies } from 'react-cookie';
import { API } from '../Helpers/action';
import { useNavigate } from 'react-router-dom';

export const useClaims = () => {
    const [error, setError] = useState(null)
    const [isLoading, setIsLoading] = useState(null)
    const [data, setData] = useState([])
    const navigate = useNavigate()

    const getclaim = useCallback(
        async () => {
            setIsLoading(true)
            setError(null)

            const cookies = new Cookies();
            let token =cookies.get('xhrToken')
    
            await axios.get(`${API}/claims`,
            {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            } ).then((response) => {
                // console.log(response);
                setData(response.data.data)
                    setIsLoading(false)
                
            }).catch((err) => {
                setIsLoading(false)
                setError(err.response.data.message)
                // console.log(err.response.data.message)
            })
        }, []
    )
    
    const addclaims = useCallback(
        async (data) => {
            setIsLoading(true)
            setError(null)

            const cookies = new Cookies();
            let token =cookies.get('xhrToken')
    
            await axios.post(`${API}/claim/vehicle`, data,
            {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            } ).then((response) => {
                // console.log(response);
                    setIsLoading(false)
                    navigate('/claims')
                
            }).catch((err) => {
                setIsLoading(false)
                setError(err.response.data.message)
                // console.log(err.response.data.message)
            })
        }, [navigate]
    )

    const individual_claims = useCallback(
        async (data) => {
            setIsLoading(true)
            setError(null)

            const cookies = new Cookies();
            let token = cookies.get('xhrToken')

            axios.get(`${API}/broker/claim/${data}`,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                }).then((response) => {
                    // console.log(response);
                    setIsLoading(false)
                    setData(response.data.data)
                }).catch((err) => {
                    setIsLoading(false)
                    setError(err.response.data.message)
                    // console.log(err.response.data.message)
                })

        }, []
    )

    return { getclaim, data, addclaims, isLoading, error, individual_claims}
}
