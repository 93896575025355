import React, { useRef, useState } from 'react'
import { ReactComponent as Arrow } from "../Assets/icons/back-arrow.svg";
import { Link, useNavigate } from 'react-router-dom';
import { useClaims } from '../Hooks/claims';
import Loader from '../Components/Loader/Loader';

function Makeclaim() {
    const navigate = useNavigate();
    const [page, setPage] = useState('section1')
    const click = useRef("");
    const click1 = useRef("");
    const [filename, setFilename] = useState({
        "vehicle_image_with_plate_number": "",
        "vehicle_image_with_plate_number2":"",
    })
    const [claimdata, setClaimdata] = useState({
        "user_vehicle_id": JSON.parse(localStorage.getItem('claimid')),
        "purpose_of_use_at_accident":"",
        "purpose_of_use_at_accident_extra":"",
        "driver_name": "",
        "driver_age": 0,
        "driver_years_experience": 0,
        "driver_address": "",
        "driver_license_number": "",
        "driver_date_of_expiry": "",
        "driver_previous_accident": "",
        "driver_previous_accident_details": "",
        "driver_prosecuted": "",
        "driver_prosecuted_details": "",
        "driver_employment": "",
        "driver_employment_capacity": "",
        "driver_employment_length": "",
        "accident_date": "",
        "accident_time": "",
        "accident_place": "",
        "accident_weather_condition": "",
        "headlamps": "",
        "road_width": "",
        "vehicle_speed": "",
        "driver_warning": "",
        "other_driver_warning": "",
        "accident_description": "",
        "witness_name": "",
        "witness_address": "",
        "damaged_parts": "",
        "vehicle_location": "",
        "cost_of_repairs": 0,
        "repairer_name": "",
        "repairer_address": "",
        "repairer_phone": "",
        "other_vehicle_insurance": "",
        "third_party_name": "",
        "third_party_address": "",
        "third_party_property": "",
        "third_party_injury": "",
        "third_party_make": "yes",
        "third_party_reg_no": "yes",
        "third_party_inspection_address": "",
        "police_report": "",
        "police_report_station": "",
        "police_report_officer_name": "",
        "police_report_officer_number": "",
        "police_action": "",
        "claim_notice": "",
        "victim_name": "",
        "victim_hospital": "",
        "vehicle_image_with_plate_number": "",
        "vehicle_image_with_plate_number2":"",
        "driver_side_image":"",
        "passenger_side_image":"",
        "affected_car_center_image":"",
        "incident_details":""
    
    })
    const { addclaims, isLoading, error } = useClaims()
    const [damage, setDamage] = useState('')
    function encodeImageFileAsURL(e) {
        let file = e.target.files[0];
        let reader = new FileReader();
        setFilename({ ...filename, [e.target.name]: file.name })
        reader.onloadend = function () {
        setClaimdata({ ...claimdata, [e.target.name]: reader.result })
        }
        reader.readAsDataURL(file);
    }

    const file = () => {
        click.current.click();
    };

    const file1 = () => {
        click1.current.click();
    };

    const claimpolicy = async () => {
        await addclaims(claimdata)
    }
    return (
        <div>
            <div className="navigate-back">
        <Link onClick={() => navigate(-1)}>
          <Arrow />
        </Link>
      </div>
      <div className="vehicle-policy-container">
            <div className="claims-table-container buy-vehicle-box">
                <form className="health-form buy-form" autoComplete='off'>
                    <div className={`${page === 'section1' ? '' : "d-none"}`}>
                        <div className="quotaion-title">
                            <h6>Section 1: Vehicle</h6>
                            
                        </div>
                        <div className="input-group input-group-claims">
                            <div>
                                <label>Purpose of use at time of accident</label>
                                <select
                                    name="purpose_of_use_at_accident"
                                    defaultValue="Select Customer"
                                    onChange={(e) => setClaimdata({...claimdata, [e.target.name]: e.target.value})}
                                >
                                    <option>Select Option</option>
                                    <option>Private</option>
                                    <option>Commercial</option>
                                </select>
                            </div>
                            { claimdata.purpose_of_use_at_accident === 'Commercial' ?
                            <div>
                                <label>If Business/Commercial, what kind of carriage</label>
                                <select
                                    name="purpose_of_use_at_accident_extra"
                                    defaultValue="Select Customer"
                                    onChange={(e) => setClaimdata({...claimdata, [e.target.name]: e.target.value})}
                                >
                                    <option>Select Carriage</option>
                                    <option>Goods</option>
                                    <option>Persons</option>
                                </select>
                            </div> :
                            <div>
                                <label></label>
                                <input hidden/>
                            </div>
                            }
                        </div>
                        <div className="page-control">

                            <div className={`next-box ${claimdata.purpose_of_use_at_accident === '' ? 'non-active' : ''}`}>
                                <div onClick={() => {claimdata.purpose_of_use_at_accident === '' ? setPage('section1') : setPage('section2')}} >Next</div>
                            </div>
                        </div>
                    </div>
                    <div className={`${page === "section2" ? "" : "d-none"}`}>
                        <div className="quotaion-title">
                            <h6>Section 2: Driver</h6>

                        </div>
                        <div className="input-group input-group-claims">
                            <div>
                                <label>Name</label>
                                <input
                                    type="text"
                                    name="driver_name"
                                    onChange={(e) => setClaimdata({...claimdata, [e.target.name]: e.target.value})}
                                />
                            </div>
                            <div>
                                <label>Age</label>
                                <input
                                    type="number"
                                    name="driver_age"
                                    onChange={(e) => {e.target.value === '' ? setClaimdata({...claimdata, [e.target.name]: 1}) : setClaimdata({...claimdata, [e.target.name]: JSON.parse(e.target.value)})}}
                                />
                            </div>
                        </div>
                        <div className="input-group input-group-claims">
                            <div>
                                <label>Years of experience</label>
                                <input
                                    type="numbers"
                                    name="driver_years_experience"
                                    onChange={(e) => {e.target.value === '' ? setClaimdata({...claimdata, [e.target.name]: ''}) : setClaimdata({...claimdata, [e.target.name]: JSON.parse(e.target.value)})}}
                                />
                            </div>
                            <div>
                                <label>Address</label>
                                <textarea rows={2} name='driver_address' onChange={(e) => setClaimdata({...claimdata, [e.target.name]: e.target.value})} />
                            </div>
                        </div>
                        <div className="input-group input-group-claims">
                            <div>
                                <label>Driver's License number</label>
                                <input
                                    type="text"
                                    name="driver_license_number"
                                    onChange={(e) => setClaimdata({...claimdata, [e.target.name]: e.target.value})}
                                />
                            </div>
                            <div>
                                <label>Date of expiry</label>
                                <input type='date' name='driver_date_of_expiry' onChange={(e) => setClaimdata({...claimdata, [e.target.name]: e.target.value})}/>
                                
                            </div>
                        </div>
                        <div className="input-group input-group-claims">
                            <div>
                                <label>Has the driver been involved in a previous accident?</label>
                                <select
                                    name="driver_previous_accident"
                                    onChange={(e) => setClaimdata({...claimdata, [e.target.name]: e.target.value})}
                                >
                                    <option>Select </option>
                                    <option>Yes</option>
                                    <option>No</option>
                                </select>
                            </div>
                            { claimdata.driver_previous_accident === 'Yes' ?
                            <div>
                            <label>If so give detail of accident</label>
                            <textarea rows={2} name='driver_previous_accident_details' onChange={(e) => setClaimdata({...claimdata, [e.target.name]: e.target.value})}/>
                        </div> :
                            <div>
                                <label></label>
                                <input hidden/>
                            </div>
                            }
                            
                        </div>
                        <div className="input-group input-group-claims">
                            <div>
                                <label>Has driver been prosecuted for any motoring offense?</label>
                                <select
                                    name="driver_prosecuted"
                                    defaultValue="Select Customer"
                                    onChange={(e) => setClaimdata({...claimdata, [e.target.name]: e.target.value})}
                                >
                                    <option>Select </option>
                                    <option>Yes</option>
                                    <option>No</option>
                                </select>
                            </div>
                            { claimdata.driver_prosecuted === 'Yes' ?
                            <div>
                            <label>If so give detail of accident</label>
                            <textarea rows={2} name='driver_prosecuted_details' onChange={(e) => setClaimdata({...claimdata, [e.target.name]: e.target.value})}/>
                        </div> :
                            <div>
                                <label></label>
                                <input hidden/>
                            </div>
                            }
                        </div>
                        <div className="input-group input-group-claims">
                            <div>
                                <label>Is the driver in your employment ?</label>
                                <select
                                    name="driver_employment"
                                    defaultValue="Select Customer"
                                    onChange={(e) => setClaimdata({...claimdata, [e.target.name]: e.target.value})}
                                >
                                    <option>Select </option>
                                    <option>Yes</option>
                                    <option>No</option>
                                </select>
                            </div>
                            { claimdata.driver_employment === 'Yes' ?
                            <div className="input-group input-group-claims input-group-claims-sm">
                            <div>
                                <label>If so in what capacity?</label>
                                <input
                                    type="text"
                                    name="driver_employment_capacity"
                                    onChange={(e) => setClaimdata({...claimdata, [e.target.name]: e.target.value})}
                                />
                            </div>
                            <div>
                                <label>For how long?</label>
                                <input
                                    type="number"
                                    name="driver_employment_length"
                                    onChange={(e) => setClaimdata({...claimdata, [e.target.name]: e.target.value})}
                                />
                            </div>
                        </div> :
                            <div>
                                <label></label>
                                <input hidden/>
                            </div>
                            }
                            
                        </div>
                        <div className="page-control">
                            <span onClick={() => setPage("")}>Back</span>
                            <div className={`next-box ${claimdata.driver_years_experience === 0 || claimdata.driver_license_number === '' || claimdata.driver_previous_accident === '' || claimdata.driver_prosecuted === '' || claimdata.driver_employment === '' ? 'non-active': ''}`}>
                                <div onClick={() => {claimdata.driver_years_experience === 0 || claimdata.driver_license_number === '' || claimdata.driver_previous_accident === '' || claimdata.driver_prosecuted === '' || claimdata.driver_employment === '' ? setPage('section2') : setPage('section3')}}>Next</div>
                            </div>
                        </div>
                    </div>
                    <div className={`${page === "section3" ? "" : "d-none"}`}>
                        <div className="quotaion-title">
                            <h6>Section 3: Circumstance of loss</h6>
                            
                        </div>
                        <div className="input-group input-group-claims">
                            <div>
                                <label>Date of accident</label>
                                <input type='date' name='accident_date' onChange={(e) => setClaimdata({...claimdata, [e.target.name]: e.target.value})} />
                            </div>
                            <div>
                                <label>Time of accident</label>
                                <input type='time' name='accident_time' onChange={(e) => setClaimdata({...claimdata, [e.target.name]: e.target.value})} />
                            </div>
                        </div>
                        <div className="input-group input-group-claims">
                            <div>
                                <label>Weather condtion</label>
                                <input type='text'  name='accident_weather_condition' onChange={(e) => setClaimdata({...claimdata, [e.target.name]: e.target.value})}/>
                            </div>
                            <div>
                                <label> Place of accident</label>
                                <input type='text' name='accident_place' onChange={(e) => setClaimdata({...claimdata, [e.target.name]: e.target.value})}/>
                            </div>
                        </div>
                        <div className="input-group input-group-claims">
                            <div>
                                <label>If dark, were your headlamps on ?</label>
                                <select
                                    name="headlamps"
                                    defaultValue="Select Customer"
                                    onChange={(e) => setClaimdata({...claimdata, [e.target.name]: e.target.value})}
                                >
                                    <option>Select </option>
                                    <option>Yes</option>
                                    <option>No</option>
                                </select>
                            </div>
                            <div>
                                <label>Width of the road</label>
                                <input type='text' name='road_width' onChange={(e) => setClaimdata({...claimdata, [e.target.name]: e.target.value})}/>
                            </div>
                        </div>
                        <div className="input-group input-group-claims">
                            <div>
                                <label>Speed of the vehicle?</label>
                                <input type='text' name='vehicle_speed' onChange={(e) => setClaimdata({...claimdata, [e.target.name]: e.target.value})}/>
                            </div>
                            <div>
                                <label></label>
                                <input type='text' hidden />
                            </div>
                        </div>
                        <div className="input-group input-group-claims">
                            <div>
                                <label>What warning did your driver give ?</label>
                                <textarea rows={3} name='driver_warning' onChange={(e) => setClaimdata({...claimdata, [e.target.name]: e.target.value})}/>
                            </div>
                            <div>
                                <label>What warning did the other driver give ?</label>
                                <textarea rows={3} name='other_driver_warning'onChange={(e) => setClaimdata({...claimdata, [e.target.name]: e.target.value})} />
                            </div>
                        </div>
                        <div className="input-group input-group-claims personal-single">
                            <div>
                                <label>Full description of accident and events leading up to it</label>
                                <textarea rows={3} name='accident_description' onChange={(e) => setClaimdata({...claimdata, [e.target.name]: e.target.value, incident_details: e.target.value})}/>
                            </div>
                        </div>
                        <p className='claim-sub-topic'>Witnesses or occupants of your vehicle </p>
                        <div className="input-group input-group-claims">
                            <div>
                                <label>Name</label>
                                <input type='text' name='witness_name' onChange={(e) => setClaimdata({...claimdata, [e.target.name]: e.target.value})} />
                            </div>
                            <div>
                                <label>Address</label>
                                <textarea rows={2} name='witness_address' onChange={(e) => setClaimdata({...claimdata, [e.target.name]: e.target.value})} />
                            </div>
                        </div>
                        <div className="page-control">
                            <span onClick={() => setPage("section2")}>Back</span>
                            <div className={`next-box ${claimdata.accident_date === '' || claimdata.accident_place === '' || claimdata.headlamps === '' || claimdata.vehicle_speed === '' || claimdata.driver_warning === '' || claimdata.other_driver_warning === '' || claimdata.accident_description === ''? 'non-active': ''}`}>
                                <div onClick={() => {claimdata.accident_date === '' || claimdata.accident_place === '' || claimdata.headlamps === '' || claimdata.vehicle_speed === '' || claimdata.driver_warning === '' || claimdata.other_driver_warning === '' || claimdata.accident_description === ''? setPage('section2'):  setPage('section4')}}>Next</div>
                            </div>
                        </div>
                    </div>
                    <div className={`${page === "section4" ? "" : "d-none"}`}>
                        <div className="quotaion-title">
                            <h6>Section 4: Damage to Insured vehicle</h6>
                            
                        </div>
                        <div className="personal-info-form">
                                <div id="first">
                                    <div className="input-group input-group-claims personal-single">
                                        <div>
                                            <label>Full details of damaged parts*</label>
                                            <textarea rows={5} name='damaged_parts' onChange={(e) => setClaimdata({...claimdata, [e.target.name]: e.target.value})} />
                                        </div>
                                    </div>
                                    <div className="input-group input-group-claims personal-single">
                                        <div>
                                            <label>Front Image of damaged car with plate number*</label>
                                            <input
                                                type="file"
                                                name="vehicle_image_with_plate_number"
                                                ref={click}
                                                onChange={encodeImageFileAsURL}
                                                hidden
                                            />
                                            <span className="fileinput" onClick={file}>Choose File</span><span className="filename">{filename.vehicle_image_with_plate_number}</span>
                                        </div>
                                    </div>
                                </div>
                                <div id="second">
                                    <div className="input-group input-group-claims personal-single">
                                        <div>
                                            <label>Present Location of vehicle*</label>
                                            <input
                                                type="text"
                                                name="vehicle_location"
                                                onChange={(e) => setClaimdata({...claimdata, [e.target.name]: e.target.value})}
                                            />
                                        </div>
                                    </div>
                                    <div className="input-group input-group-claims personal-single">
                                        <div>
                                            <label>Probable cost of repairs*</label>
                                            <input
                                                type="number"
                                                name="cost_of_repairs"
                                                onChange={(e) => {e.target.value === '' ? setClaimdata({...claimdata, [e.target.name]: ''}) : setClaimdata({...claimdata, [e.target.name]: JSON.parse(e.target.value)})}}
                                            />
                                        </div>
                                    </div>
                                    <div className="input-group input-group-claims personal-single">
                                        <div>
                                            <label>Rear Image of damaged car with plate number*</label>
                                            <input
                                                type="file"
                                                name="vehicle_image_with_plate_number2"
                                                ref={click1}
                                                onChange={encodeImageFileAsURL}
                                                hidden
                                            />
                                            <span className="fileinput" onClick={file1}>Choose File</span><span className="filename">{filename.vehicle_image_with_plate_number2}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <p className='claim-sub-topic'>
                                Repairer’s Information*
                            </p>
                            <div className="input-group input-group-claims">
                                <div>
                                    <label>Name</label>
                                    <input
                                        type="text"
                                        name="repairer_name"
                                        onChange={(e) => setClaimdata({...claimdata, [e.target.name]: e.target.value})}
                                    />
                                </div>
                                <div>
                                    <label>Phone</label>
                                    <input
                                        type="tel"
                                        name="repairer_phone"
                                        onChange={(e) => setClaimdata({...claimdata, [e.target.name]: e.target.value})}
                                    />
                                </div>
                            </div>
                            <div className="input-group input-group-claims">
                                <div>
                                    <label>Address</label>
                                    <textarea rows={2} name='repairer_address' onChange={(e) => setClaimdata({...claimdata, [e.target.name]: e.target.value})}/>
                                </div>
                                <div>
                                    <label>Is the vehicle insured somewhere else?</label>
                                    <select
                                        name="other_vehicle_insurance"
                                        defaultValue="Select Customer"
                                        onChange={(e) => setClaimdata({...claimdata, [e.target.name]: e.target.value})}
                                    >
                                        <option>Select </option>
                                        <option>Yes</option>
                                        <option>No</option>
                                    </select>
                                </div>
                            </div>
                        <div className="page-control">
                            <span onClick={() => setPage("section3")}>Back</span>
                            <div className={`next-box ${claimdata.damaged_parts === '' || claimdata.cost_of_repairs === '' || claimdata.repairer_name === '' || claimdata.repairer_phone === '' || claimdata.repairer_address === '' || claimdata.other_vehicle_insurance === '' || claimdata.vehicle_location === '' ? 'non-active': ''}`}>
                                <div onClick={() => {claimdata.damaged_parts === '' || claimdata.cost_of_repairs === '' || claimdata.repairer_name === '' || claimdata.repairer_phone === '' || claimdata.repairer_address === '' || claimdata.other_vehicle_insurance === '' || claimdata.vehicle_location === '' || claimdata.vehicle_image_with_plate_number === '' || claimdata.vehicle_image_with_plate_number2 === '' ? setPage('section4'): setPage('section5')}}>Next</div>
                            </div>
                        </div>
                    </div>
                    <div className={`${page === "section5" ? "" : "d-none"}`}>
                        <div className="quotaion-title">
                            <h6>Section 5: Full Details of Third Parties Involved In Accident</h6>
                        </div>
                        <div>
                            <div className="input-group input-group-claims">
                                <div>
                                    <label>Name</label>
                                    <input
                                        type="text"
                                        name="third_party_name"
                                        onChange={(e) => setClaimdata({...claimdata, [e.target.name]: e.target.value})}
                                    />
                                </div>
                                <div>
                                    <label>Address</label>
                                    <textarea rows={2} name='third_party_address' onChange={(e) => setClaimdata({...claimdata, [e.target.name]: e.target.value})}/>
                                </div>
                            </div>
                            <div className="input-group input-group-claims">
                                <div>
                                    <label>Type of third-party damage(property/injury) ?</label>
                                    <select
                                        name="third_party_property-select"
                                        defaultValue="Select Customer"
                                        onChange={(e) => setDamage( e.target.value)}
                                    >
                                        <option>Select </option>
                                        <option>Property</option>
                                        <option>Injury</option>
                                    </select>
                                </div>
                                { damage === 'Injury' ?
                             <div>
                             <label>Type of injury</label>
                             <input type='text' name='third_party_injury' onChange={(e) => setClaimdata({...claimdata, [e.target.name]: e.target.value})}/>
                         </div>:
                            <div>
                                <label></label>
                                <input hidden/>
                            </div>
                            }
                                
                            </div>

                            <div className={`input-group input-group-claims ${damage === 'Property' ? '' : 'd-none'}`}>
                                <div>
                                    <label>Type of damaged property</label>
                                    <input
                                        type="text"
                                        name="third_party_property"
                                        onChange={(e) => setClaimdata({...claimdata, [e.target.name]: e.target.value})}
                                    />
                                </div>
                                <div className="input-group input-group-claims input-group-claims-sm">
                                <div>
                                    <label>If vehicle, make</label>
                                    <input
                                        type="text"
                                        name="third_party_make"
                                        onChange={(e) => setClaimdata({...claimdata, [e.target.name]: e.target.value})}
                                    />
                                </div>
                                <div>
                                    <label> reg no*</label>
                                    <input type='text' name='third_party_reg_no' onChange={(e) => setClaimdata({...claimdata, [e.target.name]: e.target.value})}/>
                                </div>
                            </div>
                            </div>
                            <div className="input-group input-group-claims">
                                <div>
                                    <label>Address where vehicle can be inspected*</label>
                                    <input type='text' name='third_party_inspection_address' onChange={(e) => setClaimdata({...claimdata, [e.target.name]: e.target.value})} />
                                </div>
                                <div>
                                    <label>Has the accident been reported to the police ?</label>
                                    <select
                                        name="police_report"
                                        defaultValue="Select Customer"
                                        onChange={(e) => setClaimdata({...claimdata, [e.target.name]: e.target.value})}
                                    >
                                        <option>Select </option>
                                        <option>Yes</option>
                                        <option>No</option>
                                    </select>
                                </div>
                            </div>
                            <div className={`input-group input-group-claims input-group-claims-sm ${claimdata.police_report === 'Yes' ? '' : 'd-none'}`}>
                                <div>
                                    <label>If yes, which station</label>
                                    <input
                                        type="text"
                                        name="police_report_station"
                                        onChange={(e) => setClaimdata({...claimdata, [e.target.name]: e.target.value})}
                                    />
                                </div>
                                <div className="input-group input-group-claims">
                                <div>
                                    <label>Officer’s name </label>
                                    <input
                                        type="text"
                                        name="police_report_officer_name"
                                        onChange={(e) => setClaimdata({...claimdata, [e.target.name]: e.target.value})}
                                    />
                                </div>
                                <div>
                                    <label>Officer’s Phone</label>
                                    <input type='tel' name='police_report_officer_number' onChange={(e) => setClaimdata({...claimdata, [e.target.name]: e.target.value})} />
                                </div>
                            </div>
                            </div>
                            <div className="input-group input-group-claims">
                                <div>
                                    <label>what action are/has the Police taking in respect of the accident?</label>
                                    <input type='text' name='police_action' onChange={(e) => setClaimdata({...claimdata, [e.target.name]: e.target.value})}/>
                                </div>
                                <div>
                                    <label>Any notice of any claim been given to you?</label>
                                    <select
                                        name="claim_notice"
                                        defaultValue="Select Customer"
                                        onChange={(e) => setClaimdata({...claimdata, [e.target.name]: e.target.value})}
                                    >
                                        <option>Select </option>
                                        <option>Yes</option>
                                        <option>No</option>
                                    </select>
                                </div>
                            </div>
                            <div className="input-group input-group-claims">
                                <div>
                                    <label>Victim Name</label>
                                    <input type='text' name='victim_name' onChange={(e) => setClaimdata({...claimdata, [e.target.name]: e.target.value})} />
                                </div>
                                <div>
                                    <label>Victim Hospital</label>
                                    <input type='text' name='victim_hospital' onChange={(e) => setClaimdata({...claimdata, [e.target.name]: e.target.value})} />
                                </div>
                            </div>
                            <div>
                                <p>
                                    {error}
                                </p>
                            </div>
                        </div>
                        <div className="page-control">
                            <span onClick={() => setPage("section4")}>Back</span>
                            <div className={`next-box ${claimdata.third_party_name === '' || claimdata.police_report === '' || claimdata.police_action === '' || claimdata.claim_notice === ''  ? 'non-active': ''} `}>
                                <div onClick={() => {claimdata.third_party_name === '' || claimdata.police_report === '' || claimdata.police_action === '' || claimdata.claim_notice === ''  ? setPage('section5'): claimpolicy()}}>Claim</div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            {isLoading ? <Loader /> : '' }
            </div>
        </div>
    )
}

export default Makeclaim