import React from "react";
import "../../Stylesheets/policy.css";
import { ReactComponent as Healthicon } from "../../Assets/icons/healthpolicy.svg";
import { ReactComponent as Healthclaim } from "../../Assets/icons/healthclaims.svg";
import { ReactComponent as Buypolicy } from "../../Assets/icons/buypolicy.svg";
import { Link, useNavigate } from "react-router-dom";
import { ReactComponent as Arrow } from "../../Assets/icons/back-arrow.svg";

function Gadget() {
    let Navigate = useNavigate()
    // const back = () => {
    //   Navigate('/policy')
    // }

    return (
        <div className="policy-container">
            <div className="navigate-back px-0">
                <Link onClick={() => Navigate(-1)}>
                    <Arrow />
                </Link>
            </div>
            <div className="policies-card-container">
                <h4 className="dashboard-title policy-title">Gadget Policy</h4>
                <div className="policy-card-container">
                    <Link to="all" className="policy-card">
                        <div>
                            <h4>All Policies</h4>
                            <span>View polices</span>
                        </div>
                        <Healthicon />
                    </Link>
                    <Link to="buy" className="policy-card">
                        <div>
                            <h4>Buy Policy</h4>
                            <span>Purchase</span>
                        </div>
                        <Buypolicy />
                    </Link>
                    <div className="policy-card nonactive-policy-card">
                        <div>
                            <h4>Claims </h4>
                            <span>View Claims</span>
                        </div>
                        <Healthclaim />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Gadget;
