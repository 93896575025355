import React, {useEffect, useRef, useState} from "react";
import {ReactComponent as Arrow} from "../../Assets/icons/back-arrow.svg";
import {Link, useNavigate} from "react-router-dom";
import {useCustomer} from "../../Hooks/customer";
import Table from "react-bootstrap/Table";
import {useUnderwriter} from "../../Hooks/underwriter";
import Loader from "../../Components/Loader/Loader";
import Dropdown from "react-bootstrap/Dropdown";
import {ReactComponent as Nexticon} from "../../Assets/icons/nextarrw.svg";
import {ReactComponent as Previcon} from "../../Assets/icons/prevarrw.svg";
import ReactPaginate from "react-paginate";
import {Cookies} from "react-cookie";
import CurrencyInput from "react-currency-input-field";
// import PreviewQuote from "./previewQuote";
import Emailsent from "../../Components/modal/Email-modal";
import {useGadget} from "../../Hooks/gadget";
import { ReactComponent as Upload } from "../../Assets/icons/upload.svg";


function Buygadget() {
    const navigate = useNavigate();
    const cookies = new Cookies();
    const [page, setPage] = useState("");
    const [emailmodal, setEmailModal] = useState(false);
    const {customer, data} = useCustomer();
    const [gadgetList, setGadgetList] = useState(1)
    const [gadgetDetails, setGadgetDetails] = useState({})
    const handleInputChange = (index, key, value) => {
        setGadgetDetails((prevState) => ({
            ...prevState,
            [`${key}_${index}`]: value,
        }));
    };
    console.log(gadgetList, gadgetDetails)
    const {buyGadget, isLoading,} = useGadget()
    const click = useRef()

    const file = () => {
        click.current.click()
    };

    const Signature = (e) => {
        setFilename(e.target.files[0].name)
        let fileToLoad = e.target.files[0];
        let fileReader = new FileReader();
        fileReader.onload = function (fileLoadedEvent) {
            setData({...buydata, "signature" : fileLoadedEvent.target.result}) // <--- data: base64
        }
        fileReader.readAsDataURL(fileToLoad);
    }

    const [filename, setFilename] = useState('')

    const [customerinfo, setCustomerInfo] = useState({})

    const [buydata, setData] = useState({
        "enrollee_id": 1,
        "title": "",
        "firstname": "",
        "lastname": "",
        "business_address": "",
        "registered_address": "",
        "bvn": "",
        "phone1": "",
        "phone2": "",
        "address_of_insured": "",
        "start_date": "",
        "fund_source": "",
        "declined_proposal": "",
        "expected_premium": "",
        // "claim_experience": "",
        "other_insurer": "",
        // "insurance_company": "",
        // "name_of_banker": "",
        "dob": "",
        "nationality": "",
        "address": "",
        "email": "",
        "occupation": "",
        // "employer": "",
        "employer_address": "",
        "customer_id_type": "",
        "customer_id_number": "",
        "id_expiry_date": "",
        "customer_id_issue_date": "",
        "tax_id": "",
        "account_number": "",
        "account_name": "",
        "account_type": "",
        "bank_name": "",
        "bank_branch": "",
        "signature": "",
        "payment_frequency_id": "",
    })

    const kyc = [
        "firstname",
        "email",
        "phone1",
        "address",
        "dob",
        "nationality",
        "occupation",
        "employer",
        "employer_address",
        "customer_id_type",
        "customer_id_number",
        "customer_id_issue_date",
        "id_expiry_date",
        "tax_id",
        "account_name",
        "account_type",
        "account_number",
        "bank_name",
        "bank_branch",
        "signature"
    ]

    let userid = JSON.parse(atob(cookies.get("xhrUserxx")));
    let getinfo = cookies.get("xirhnfo");
    // const [today, setToday] = useState("")
    const hideemail = () => {
        setEmailModal(false);
        navigate("/policy/travel/all");
    };

    const kycVerification = (object, targetObject) => {
        return targetObject.every(key => object[key] !== "");
    }

    const quotedata = kycVerification(buydata, kyc);
    // console.log(result);


    const expectedpremiuim = (value, name) => {
        let v = value;
        let n = name;

        if (value !== undefined) {
            setData({
                ...buydata,
                [n]: v,
            });
        }
    };



    const currency = (value, name, index) => {
        let v = value;
        // let n =name
        // console.log(value);
        if (value !== undefined)
            handleInputChange(index, name, JSON.parse(v) * 100)
        // setData({
        //     ...buydata,
        //     `${n}` JSON.parse(v) * 100,
        // });
    };

    const {chi_resourses, resources} = useUnderwriter()

    const buyverification = Object.entries(buydata).every(([key, value]) => {
        const trimmedValue = typeof value === 'string' ? value.trim() : value;
        if (trimmedValue === "") {
        }
        return trimmedValue !== "";
    });


    console.log(quotedata, buydata)

    const getQUOTE = async () => {
        setPage('completeform')
    };

    const buypolicy = async () => {
        console.log(buydata)
        let result = []
        for (let key in gadgetDetails) {
            if (key.startsWith('gadget_name_')) {
                const index = key.slice('gadget_name_'.length); // Extract the index
                const valueKey = `gadget_value_${index}`; // Form the corresponding value key

                // Check if the value key exists using safer Object.hasOwnProperty method
                if (Object.prototype.hasOwnProperty.call(gadgetDetails, valueKey)) {
                    // Pair the name and value in an object and push it to the result array
                    result.push({
                        gadget_name: gadgetDetails[key],
                        gadget_price: `${gadgetDetails[valueKey]}`
                    });
                }
            }
        }
        let data = {
            "enrollee_id": 1,
            "title": buydata.title,
            "firstname": buydata.firstname,
            "lastname": buydata.lastname,
            "business_address": buydata.business_address,
            "registered_address": buydata.registered_address,
            "bvn": buydata.bvn,
            "phone1": buydata.phone1,
            "phone2": buydata.phone2,
            "address_of_insured": buydata.address_of_insured,
            "start_date": buydata.start_date,
            "fund_source": buydata.fund_source,
            "declined_proposal": buydata.declined_proposal,
            "claim_experience": buydata.claim_experience,
            "other_insurer": buydata.other_insurer,
            "insurance_company": buydata.insurance_company,
            "name_of_banker": buydata.name_of_banker,
            "dob": buydata.dob,
            "expected_premium": buydata.expected_premium,
            "nationality": buydata.nationality,
            "address": buydata.address,
            "email": buydata.email,
            "occupation": buydata.occupation,
            "employer": buydata.employer,
            "employer_address": buydata.employer_address,
            "customer_id_type": buydata.customer_id_type,
            "customer_id_number": buydata.customer_id_number,
            "id_expiry_date": buydata.id_expiry_date,
            "customer_id_issue_date": buydata.customer_id_issue_date,
            "tax_id": buydata.tax_id,
            "account_number": buydata.account_number,
            "account_name": buydata.account_name,
            "account_type": buydata.account_type,
            "bank_name": buydata.bank_name,
            "bank_branch": buydata.bank_branch,
            "signature": buydata.signature,
            "payment_frequency_id": buydata.payment_frequency_id,
            "gadget": result
        }
        console.log(result);
        setData({...buydata, gadget: result})
        await buyGadget(data, setEmailModal)
        // setEmailModal(true)
    }


    const [itemOffset, setItemOffset] = useState(0);
    let itemsPerPage = 20;
    const endOffset = itemOffset + itemsPerPage;
    const pageCount = Math.ceil(data.length / itemsPerPage);

    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % data.length;

        setItemOffset(newOffset);
    };
    useEffect(() => {
        if (getinfo !== undefined) {
            setPage("startform");
        }
        const formattedDate = () => {
            const today = new Date();
            const year = today.getFullYear();
            const month = String(today.getMonth() + 1).padStart(2, '0'); // Month is zero-indexed
            const day = String(today.getDate()).padStart(2, '0');
            // console.log(`${year}-${month}-${day}`);
            // setToday(`${year}-${month}-${day}`)
            return `${year}-${month}-${day}`;
        }
        customer();
        formattedDate();
    }, [getinfo, customer,]);
    return (
        <div>
            <div className="navigate-back">
                <Link onClick={() => navigate(-1)}>
                    <Arrow/>
                </Link>
            </div>
            <div className="vehicle-policy-container">
                <h4 className="dashboard-title">Buy Gadget Policy</h4>
                <div className="claims-table-container buy-vehicle-box">
                    <form className="health-form buy-form" autoComplete="off">
                        <div className={`${getinfo !== undefined ? "d-none" : page !== "" ? "d-none" : ""}`}>
                            <div className="lists-cust">
                                <Table className="">
                                    <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Name</th>
                                        <th>Email</th>
                                        <th colSpan={2}>Address</th>
                                        <th>Phone Number</th>
                                        <th></th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {data.slice(itemOffset, endOffset).map((data, index) => {
                                        return (
                                            <tr key={index}>
                                                <td>{itemOffset + 1 + index++}</td>
                                                <td>{data.lastname + " " + data.firstname}</td>
                                                <td>{data.email}</td>
                                                <td colSpan={2}>{data.address}</td>
                                                <td>{data.phone}</td>
                                                <td>
                                                    <Dropdown>
                                                        <Dropdown.Toggle
                                                            variant=""
                                                            id="dropdown-basic"
                                                            className="buy-policy buy-policy-but"
                                                            onMouseOver={() => {
                                                                setCustomerInfo({
                                                                    ...customerinfo,
                                                                    firstname: data.firstname,
                                                                    lastname: data.lastname,
                                                                    email: data.email,
                                                                    phone: data.phone,
                                                                    enrollee_id: data.id,
                                                                    address: data.address,
                                                                });
                                                                setData({
                                                                    ...buydata,
                                                                    enrollee_id: data.id,
                                                                    user_id: userid.userid,
                                                                });
                                                                setData({
                                                                    ...buydata,
                                                                    enrollee_id: data.id,
                                                                    user_id: userid.userid,
                                                                });
                                                            }}
                                                            onClick={() => {
                                                                setPage("startform");
                                                                chi_resourses()
                                                            }}
                                                        >
                                                            Buy
                                                        </Dropdown.Toggle>
                                                    </Dropdown>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                    </tbody>
                                </Table>
                                <ReactPaginate
                                    breakLabel="..."
                                    previousLabel={<Previcon/>}
                                    nextLabel={<Nexticon/>}
                                    pageCount={pageCount}
                                    onPageChange={handlePageClick}
                                    containerClassName={"paginatecontainer"}
                                    previousLinkClassName={"prevbtn"}
                                    pageClassName={"pageli"}
                                    nextLinkClassName={"nextbtn"}
                                    disabledClassName={"paginationdisabled"}
                                    activeClassName={"paginationactive"}
                                    breakClassName={"breakli"}
                                    renderOnZeroPageCount={null}
                                    pageRangeDisplayed={3}
                                    marginPagesDisplayed={2}
                                />
                            </div>
                        </div>
                        <div className={`${page === "startform" ? "px-4 py-4" : "d-none"}`}>
                            <div className="px-4">
                                <h6 className="fw-bold">
                                    Personal Information
                                </h6>
                                <hr/>
                                <p className="mini-header" >
                                    Individual/Personal Details
                                </p>
                            </div>
                            <div className="input-group py-3 underwriter-group">
                                <div>
                                    <label className="fw-bold">NAME (FIRSTNAME, SURNAME)</label>
                                    <input type="text" name="firstname" onChange={(e) => {
                                        setData({...buydata, [e.target.name]: e.target.value})
                                    }}/>
                                </div>
                                <div>
                                    <label className="fw-bold">E-MAIL ADDRESS</label>
                                    <input type="email" name="email" onChange={(e) => {
                                        setData({...buydata, [e.target.name]: e.target.value})
                                    }}/>
                                </div>
                                <div>
                                    <label className="fw-bold">TEL</label>
                                    <input type="tel" name="phone1" onChange={(e) => {
                                        setData({...buydata, [e.target.name]: e.target.value})
                                    }}/>
                                </div>
                            </div>
                            <div className="input-group py-3 underwriter-group">
                                <div>
                                    <label className="fw-bold">ADDRESS</label>
                                    <input type="text" name="address" onChange={(e) => {
                                        setData({...buydata, [e.target.name]: e.target.value})
                                    }}/>
                                </div>
                                <div>
                                    <label className="fw-bold">DATE OF BIRTH</label>
                                    <input type="date" name="dob" onChange={(e) => {
                                        setData({...buydata, [e.target.name]: e.target.value})
                                    }}/>
                                </div>
                                <div>
                                    <label className="fw-bold">NATIONALITY</label>
                                    <input type="text" name="nationality" onChange={(e) => {
                                        setData({...buydata, [e.target.name]: e.target.value})
                                    }}/>
                                </div>
                            </div>
                            <div className="input-group py-3 underwriter-group">
                                <div>
                                    <label className="fw-bold">OCCUPATION</label>
                                    <input type="text" name="occupation" onChange={(e) => {
                                        setData({...buydata, [e.target.name]: e.target.value})
                                    }}/>
                                </div>
                                <div>
                                    <label className="fw-bold">EMPLOYER</label>
                                    <input type="text" name="employer" onChange={(e) => {
                                        setData({...buydata, [e.target.name]: e.target.value})
                                    }}/>
                                </div>
                                <div>
                                    <label className="fw-bold">EMPLOYER ADDRESS</label>
                                    <input type="text" name="employer_address" onChange={(e) => {
                                        setData({...buydata, [e.target.name]: e.target.value})
                                    }}/>
                                </div>
                            </div>
                            <div className="px-4">
                                <hr/>
                                <p className="mini-header" >
                                    Identification Details
                                </p>
                            </div>
                            <div className="input-group py-3 underwriter-group">
                                <div>
                                    <label className="fw-bold">ID TYPE</label>
                                    <select name="customer_id_type" value={buydata.customer_id_type} onChange={(e) => {
                                        setData({...buydata, [e.target.name]: e.target.value})
                                    }} required>
                                        <option value="" disabled>Select ID</option>
                                        <option value="passport">Passport</option>
                                        <option value="National Id">National Id</option>
                                        <option value="Driver lisense">Driver lisense</option>
                                    </select>
                                </div>
                                <div>
                                    <label className="fw-bold">ID NUMBER</label>
                                    <input type="text" name="customer_id_number" onChange={(e) => {
                                        setData({...buydata, [e.target.name]: e.target.value})
                                    }}/>
                                </div>
                                <div>
                                    <label className="fw-bold">ID ISSUE DATE</label>
                                    <input type="date" name="customer_id_issue_date" onChange={(e) => {
                                        setData({...buydata, [e.target.name]: e.target.value})
                                    }}/>
                                </div>
                            </div>
                            <div className="input-group py-3">
                                <div>
                                    <label className="fw-bold">ID EXPIRY DATE</label>
                                    <input type="date" name="id_expiry_date" onChange={(e) => {
                                        setData({...buydata, [e.target.name]: e.target.value})
                                    }}/>
                                </div>
                                <div>
                                    <label className="fw-bold">TAX IDENTIFICATION NUMBER</label>
                                    <input type="text" name="tax_id" onChange={(e) => {
                                        setData({...buydata, [e.target.name]: e.target.value})
                                    }}/>
                                </div>
                            </div>
                            <div className="px-4">
                                <hr/>
                                <p className="mini-header" >
                                    Account Details
                                </p>
                            </div>
                            <div className="input-group py-3 underwriter-group">
                                <div>
                                    <label className="fw-bold">A/C NAME</label>
                                    <input type="text" name="account_name" onChange={(e) => {
                                        setData({...buydata, [e.target.name]: e.target.value})
                                    }}/>
                                </div>
                                <div>
                                    <label className="fw-bold">A/C TYPE</label>
                                    <input type="text" name="account_type" onChange={(e) => {
                                        setData({...buydata, [e.target.name]: e.target.value})
                                    }}/>
                                </div>
                                <div>
                                    <label className="fw-bold">A/C NO</label>
                                    <input type="number" name="account_number" onChange={(e) => {
                                        setData({...buydata, [e.target.name]: e.target.value})
                                    }}/>
                                </div>
                            </div>
                            <div className="input-group py-3 underwriter-group">
                                <div>
                                    <label className="fw-bold">Bank Name</label>
                                    <input type="text" name="bank_name" onChange={(e) => {
                                        setData({...buydata, [e.target.name]: e.target.value})
                                    }}/>
                                </div>
                                <div>
                                    <label className="fw-bold">Branch</label>
                                    <input type="text" name="bank_branch" onChange={(e) => {
                                        setData({...buydata, [e.target.name]: e.target.value})
                                    }}/>
                                </div>
                                <div>
                                    <label className="fw-bold">Payment frequency</label>
                                    <select name="payment_frequency_id" value={buydata.payment_frequency_id}
                                            onChange={(e) => {
                                                setData({...buydata, [e.target.name]: JSON.parse(e.target.value)})
                                            }}>
                                        <option value="" disabled>Select Payment frequency</option>
                                        {
                                            resources.payment_frequency.map((frequency, index) => {
                                                return (
                                                    <option value={frequency.id} key={index}>{frequency.name}</option>
                                                )
                                            })
                                        }
                                    </select>
                                </div>
                            </div>
                            <div className="px-4">
                                <hr/>
                                <p className="mini-header" >
                                    Signature
                                </p>
                            </div>
                            <div>

                                <input type='file' ref={click} onChange={Signature} hidden/>
                                <div className='text-center'>
                                    <Upload onClick={file} style={{ width: 75, height: 75 }}  />
                                    <p className="fw-bold">Upload Signature <span className='text-primary' onClick={file} >Here</span></p>
                                    <p>{filename}</p>
                                    <p className="fw-bold"><span className="text-danger text-md">Note*:</span> All signature should be on a plain white paper</p>
                                    {/*<a href = {Example} download = "file">Download Sample of Excel File</a>*/}
                                </div>
                            </div>
                            <div>
                                {/*<p className="text-danger"><em>{err}</em></p>*/}
                            </div>

                        </div>
                        <div className={`${page === "completeform" ? "" : "d-none"}`}>
                            <div className="px-4">
                                <h6 className="fw-bold">
                                    Personal Informations
                                </h6>
                                <hr/>
                                <p className="mini-header" >
                                    Gadget Details
                                </p>
                            </div>
                            <div className="input-group py-3 underwriter-group">
                                <div>
                                    <label className="fw-bold">Title</label>
                                    <input type="text" name="title" onChange={(e) => {
                                        setData({...buydata, [e.target.name]: e.target.value})
                                    }}/>
                                </div>
                                <div>
                                    <label className="fw-bold">Full Name</label>
                                    <input type="text" name="firstname" onChange={(e) => {
                                        setData({...buydata, [e.target.name]: e.target.value})
                                    }}/>
                                </div>
                                <div>
                                    <label className="fw-bold">
                                        Surname</label>
                                    <input type="text" name="lastname" onChange={(e) => {
                                        setData({...buydata, [e.target.name]: e.target.value})
                                    }}/>
                                </div>
                            </div>
                            <div className="input-group py-3 underwriter-group">
                                <div>
                                    <label className="fw-bold">Email</label>
                                    <input type="email" name="email" onChange={(e) => {
                                        setData({...buydata, [e.target.name]: e.target.value})
                                    }}/>
                                </div>
                                <div>
                                    <label className="fw-bold">TEL</label>
                                    <input type="tel" name="phone2" onChange={(e) => {
                                        setData({...buydata, [e.target.name]: e.target.value})
                                    }}/>
                                </div>
                                <div>
                                    <label className="fw-bold">BVN</label>
                                    <input type="number" name="bvn" onChange={(e) => {
                                        setData({...buydata, [e.target.name]: e.target.value})
                                    }}/>
                                </div>
                            </div>
                            <div className="input-group py-3 underwriter-group">
                                <div>
                                    <label className="fw-bold">Occupation</label>
                                    <input type="text" name="occupation" onChange={(e) => {
                                        setData({...buydata, [e.target.name]: e.target.value})
                                    }}/>
                                </div>
                                <div>
                                    <label className="fw-bold">Business Address</label>
                                    <input type="text" name="business_address" onChange={(e) => {
                                        setData({...buydata, [e.target.name]: e.target.value})
                                    }}/>
                                </div>
                                <div>
                                    <label className="fw-bold">Registered Address</label>
                                    <input type="text" name="registered_address" onChange={(e) => {
                                        setData({...buydata, [e.target.name]: e.target.value})
                                    }}/>
                                </div>
                            </div>
                            <div className="input-group py-3 underwriter-group">
                                <div>
                                    <label className="">Address of gadget to be insured (if different from
                                        business/registered address, above)</label>
                                    <input type="text" name="address_of_insured" onChange={(e) => {
                                        setData({...buydata, [e.target.name]: e.target.value})
                                    }}/>
                                </div>
                                <div>
                                    <label className="fw-bold">Select a Date and Time the insurance required
                                        from</label>
                                    <input name="start_date" type="datetime-local" onChange={(e) => {
                                        setData({...buydata, [e.target.name]: e.target.value})
                                    }}/>
                                </div>
                                <div>
                                    <label className="fw-bold">Kindly state the source of fund for this
                                        transaction</label>
                                    <input type="text" name="fund_source" onChange={(e) => {
                                        setData({...buydata, [e.target.name]: e.target.value})
                                    }}/>
                                </div>
                            </div>
                            <div className="input-group py-3 ">
                                <div>
                                    <label className="fw-bold">How much is the expected premium from this
                                        transaction</label>
                                    <CurrencyInput
                                        id="input-example"
                                        name="expected_premium"
                                        placeholder="₦1,234,5678"
                                        // defaultValue={0}
                                        decimalsLimit={2}
                                        prefix={"₦"}
                                        onValueChange={(value) => expectedpremiuim(value, 'expected_premium')}
                                    />
                                </div>
                            </div>
                            <div className="px-4">
                                <hr/>
                                <p className="mini-header">
                                    Identification Details
                                </p>
                            </div>
                            <div className="input-group py-3 ">
                                <div>
                                    <label className="fw-bold">Has any company declined your proposal</label>
                                    <select name="declined_proposal"  value={buydata.declined_proposal} onChange={(e) => {
                                        setData({...buydata, [e.target.name]: e.target.value})
                                    }}>
                                        <option value="" disabled>Select</option>
                                        <option value="Yes">Yes</option>
                                        <option value="No">No</option>
                                    </select>
                                </div>
                                <div>
                                    <label className="fw-bold">Do you have any other insurance on the
                                        abovementioned</label>
                                    <select name="other_insurer" value={buydata.other_insurer} onChange={(e) => {
                                        setData({...buydata, [e.target.name]: e.target.value})
                                    }}>
                                        <option value="" disabled>Select</option>
                                        <option value="Yes">Yes</option>
                                        <option value="No">No</option>
                                    </select>
                                </div>
                            </div>
                            <div className="input-group py-3 underwriter-group">
                                <div className={`${buydata.declined_proposal !== "Yes" ? "d-none" : ""}`}>
                                    <label className="fw-bold">What is your claim experience</label>
                                    <input type="text" name="claim_experience" onChange={(e) => {
                                        setData({...buydata, [e.target.name]: e.target.value})
                                    }}/>
                                </div>
                                <div className={`${buydata.other_insurer !== "Yes" ? "d-none" : ""}`}>
                                    <label className="fw-bold">If “YES”, please state with what company</label>
                                    <input type="text" name="insurance_company" onChange={(e) => {
                                        setData({...buydata, [e.target.name]: e.target.value})
                                    }}/>
                                </div>
                                <div className={`${buydata.other_insurer !== "Yes" ? "d-none" : ""}`}>
                                    <label className="fw-bold">Name of banker</label>
                                    <input type="text" name="name_of_banker" onChange={(e) => {
                                        setData({...buydata, [e.target.name]: e.target.value})
                                    }}/>
                                </div>
                            </div>
                            <div className="px-4">
                                <hr/>
                                <div className="d-flex justify-content-between">
                                    <p className="mini-header" >
                                        Gadgets Details
                                    </p>
                                    <button type="button"
                                            className="bg-light bg-white border border-success px-5 py-2 rounded text-success"
                                            onClick={() => setGadgetList(gadgetList + 1)}>Add
                                    </button>
                                </div>
                            </div>
                            {
                                [...Array(gadgetList)].map((_, index) => (
                                    <div className="input-group py-3 ">
                                        <div>
                                            <label className="fw-bold">Gadget {index + 1}</label>
                                            <input type="text" name="gadget_name"
                                                   value={gadgetDetails[`gadget_name_${index}`] || ''}
                                                   onChange={(e) => handleInputChange(index, 'gadget_name', e.target.value)}
                                            />
                                        </div>
                                        <div>
                                            <label className="fw-bold">Value {index + 1}</label>
                                            <CurrencyInput
                                                id="input-example"
                                                name="input-name"
                                                placeholder="₦1,234,5678"
                                                // defaultValue={0}
                                                decimalsLimit={2}
                                                prefix={"₦"}
                                                onValueChange={(value) => currency(value, 'gadget_value', index)}
                                            />
                                        </div>
                                    </div>
                                ))
                            }
                            <div>
                                {/*<p className="text-danger"><em>{err}</em></p>*/}
                            </div>

                        </div>
                        <div className="page-control">
                            {page === "completeform" ? (
                                <span onClick={() => setPage("startform")}>Back</span>
                            ) : page === "startform" ? (
                                <span onClick={() => setPage("")}>Back</span>
                            ) : (
                                <div></div>
                            )}
                            {page === "completeform" ? (
                                <div className={`next-box ${!buyverification ? "opacity-50 " : ""}`}>
                                    <div onClick={!buyverification ? null : buypolicy}>Buy Policy</div>
                                </div>
                            ) : page === "startform" ? (
                                <div className={`next-box ${!quotedata ? "opacity-50 " : ""}`}>
                                    <div onClick={!quotedata ? null : getQUOTE}>Next</div>
                                </div>
                            ) : (
                                <div className={`next-box `}></div>
                            )}
                        </div>
                    </form>
                    {/*<PreviewQuote show={modal} data={quote} onHide={() => setModal(false)} cont={() => {*/}
                    {/*    setModal(false);*/}
                    {/*    setPage("completeform"); setData({...buydata, quote_id: quote.travel_quote_id})*/}
                    {/*}} />*/}
                    <Emailsent show={emailmodal} onHide={hideemail}/>
                </div>
                {isLoading ? <Loader/> : ""}
            </div>
        </div>
    );
}

export default Buygadget;
