import React, {useCallback, useState} from "react";
import Modal from "react-bootstrap/Modal";
import InputField from "../Form/SearchInput";
import {CloseCircle} from "iconsax-react";
import {useCustomer} from "../../Hooks/customer";
import {Cookies} from "react-cookie";
import {useNavigate} from "react-router-dom";
function SearchCustomer(props) {
    const {searchCustomer, data, isLoading, error} = useCustomer()
    let cookies = new Cookies()
    let navigate = useNavigate()
    const [updated, setUpdated] =  useState(false)
    const search = useCallback(async (search) => {
        try {
            await searchCustomer(search);
            console.log(data);
        } catch (err) {
            console.error(err);
        }
    }, [searchCustomer, data]);

    const updateCustomer = (options) => {
        console.log(options)
        setUpdated(true)
        const maxAge = 1 * 1 * 60 * 60
        let info = btoa(JSON.stringify(options))
        cookies.set('xirhnfo', info, { path: '/', maxAge: maxAge, sameSite: 'lax', secure: true })
    }
    return (
        <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Body>
                <div className='d-flex justify-content-between align-items-center'>
                    <h3 className='text-md fs'>Search Customer</h3>
                    <CloseCircle size={20} color="#000000" className="text-black " onClick={props.onHide}/>
                </div>
                <div>
                    <label className='py-2'>Enter Customer Name</label>
                    <InputField updateModelValue={search} loading={isLoading} data={data} error={error}
                                updateCustomer={updateCustomer}/>
                </div>
                <div className={`customer-button text-end ${updated ? '' : "non-active" }`}>
                    <button onClick={() => updated ? navigate('buy') : ''}>Next</button>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default SearchCustomer;