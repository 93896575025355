import React from 'react';
import Modal from 'react-bootstrap/Modal';
import '../../Stylesheets/cancel.css';

function Emailsent(props) {

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Body>
                <div className='cancelbody'>
                    <p className='emailsent'>A link has been sent to the email registered for payment</p>
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default Emailsent