import React from 'react'
import '../../Stylesheets/ButtonLoader.css'

function Loader() {
    return (
        <div className="lds-ring">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    )
}

export default Loader