import React from 'react'
import Modal from 'react-bootstrap/Modal';

function metaData(props) {
    console.log(props.data.current)

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Body>
                <div className="quotaion-title">
                    <h6>MetaData</h6>
                    <p>
                        Travel Cover
                    </p>
                </div>
                <div className="input-group underwriter-group">
                    <div>
                        <label>First Name</label>
                        <p>{props.data.current.policy?.metadata?.firstname} </p>
                    </div>
                    <div>
                        <label>Last Name</label>
                        <p>{props.data.current.policy?.metadata?.lastname}</p>
                    </div>
                    <div>
                        <label>Email</label>
                        <p>{props.data.current.policy?.metadata?.email}</p>
                    </div>
                    {/*<div>*/}
                    {/*    <label>Gender</label>*/}
                    {/*    <p>{props.data.current.policy?.metadata?.gender === 1 ? 'Male' : 'Female'}</p>*/}
                    {/*</div>*/}
                </div>
                <div className="input-group underwriter-group">
                    <div>
                        <label>Phone</label>
                        <p>{props.data.current.policy?.metadata?.phone}</p>
                    </div>
                    <div>
                        <label>Country</label>
                        <p>{props.data.current.policy?.metadata?.Country}</p>
                    </div>
                    <div>
                        <label>Depature Date</label>
                        <p>{props.data.current.policy?.metadata?.StartDate}</p>
                    </div>
                </div>
                <div className="input-group underwriter-group">
                    <div>
                        <label>Arrival Date</label>
                        <p>{props.data.current.policy?.metadata?.EndDate}</p>
                    </div>
                    <div>
                        <label>Occupation</label>
                        <p>{props.data.current.policy?.metadata?.Occupation}</p>
                    </div>
                    <div>
                        <label>Marital Status</label>
                        <p>{props.data.current.policy?.metadata?.MaritalStatusID}</p>
                    </div>
                </div>
                <div className="input-group underwriter-group">
                    <div>
                        <label>Next Of Kin Name </label>
                        <p>{props.data.current.policy?.metadata?.NextOfKinName}</p>
                    </div>
                    <div>
                        <label>Next Of Kin Phone</label>
                        <p>{props.data.current.policy?.metadata?.NextOfKinPhone}</p>
                    </div>
                    <div>
                        <label>Next Of Kin Relationship</label>
                        <p>{props.data.current.policy?.metadata?.NextOfKinRelationship}</p>
                    </div>
                </div>
                <div className="input-group underwriter-group">
                    <div>
                        <label>Pre Existing Medical Condition </label>
                        <p>{props.data.current.policy?.metadata?.PreExistingMedicalCondition}</p>
                    </div>
                    <div>
                        <label></label>
                        <input hidden />
                    </div>
                    <div>
                        <label></label>
                        <input hidden />
                    </div>
                </div>

                <div className="input-group underwriter-group">
                    <div className='total-premium'>
                        <label>Amount</label>
                        <p>{props.data.current.policy?.premium}</p>
                    </div>
                    <div>
                        <label></label>
                        <input hidden />
                    </div>
                    <div>
                        <label></label>
                        <input hidden />
                    </div>
                </div>
                {/*<div className={`next-box `}>*/}
                {/*    <div >Continue</div>*/}
                {/*</div>*/}
            </Modal.Body>
        </Modal>
    )
}

export default metaData