import React from "react";
import { ReactComponent as Mobilemenu } from "../../Assets/icons/mobilemenu.svg";
import "../../Stylesheets/navbar.css";
import { useLocation } from "react-router-dom";
import { Cookies } from "react-cookie";

function Navbar(props) {
  const location = useLocation();
  let pagename = location.pathname;
  pagename = pagename.slice(1);
  const cookies = new Cookies();
    let data = JSON.parse(atob(cookies.get('xhrUserxx')))
  let navbar;
  switch (pagename) {
    case "dashboard":
      navbar = "Dashboard";
      break;
    case "customer":
      navbar = "Customer";
      break;
    case "claims":
      navbar = "Claim";
      break;
    case "policy":
      navbar = "Policy";
      break;
    case "policy/vehicle":
      navbar = "Vehicle Policy";
      break;
    case "policy/health":
      navbar = "Health Policy";
      break;
    case "policy/vehicle-claim":
      navbar = "Vehicle Claim";
      break;
    case "policy/buy-vehicle-policy":
      navbar = "Buy Vehicle Policy";
      break;
    case "policy/buy-health-policy":
      navbar = "Buy Health Policy";
      break;
    case "wallet":
      navbar = "Wallet";
      break;
    case "profile":
      navbar = "Profile";
      break;
    default:
      break;
  }
  return (
    <div className="navbar-container">
      <div className="navbar-title">
        <Mobilemenu onClick={() => props.setMobileShow(true)} />
        <h4>{navbar}</h4>
      </div>
      <div className="shortname m-0">{data.lastname[0] + '' + data.firstname[0]}</div>
    </div>
  );
}

export default Navbar;
