import React, { useEffect, useRef, useState } from "react";
import { ReactComponent as Arrow } from "../Assets/icons/back-arrow.svg";
import { Link, useNavigate } from "react-router-dom";
import { useUnderwriter } from "../Hooks/underwriter";
import { usePolicy } from "../Hooks/policy";
import { ReactComponent as Uploadicon } from "../Assets/Images/uploadicon.svg";
import Table from "react-bootstrap/Table";
import Loader from "../Components/Loader/Loader";
import UpdateHealth from "../Components/Health/Updatehealth";
import Modal from "react-bootstrap/Modal";
import { API } from '../Helpers/action';
import Emailsent from "../Components/modal/Email-modal";
// import { usePaystackPayment } from "react-paystack";


// export function Paystack({config, check}) {
//     const { verifyhmopolicy, } = usePolicy();
//     const initializePayment = usePaystackPayment(config);
//     const navigate = useNavigate();

//     const onSuccess = useCallback(
//         () => {
//             let data = {
//                 reference: config.reference
//             }
//             verifyhmopolicy(data)
//             navigate("/policy/health/all");
//             // console.log(reference);
//         }, [config.reference, navigate, verifyhmopolicy]
//     )

//     const onClose = () => {
//     }

//     useEffect(() => {
//         if (check) {
//           initializePayment(onSuccess, onClose);
//         }
//       }, [check, config.reference, initializePayment, onSuccess]);


// } 

export function Payment(props) {
    const [data, setData] = useState({
    });

    console.log(data);

    const adduser = async (e) => {
        e.preventDefault();
        let payload = {
            "bulk_reference": props.reference,
            "email": data.email,
            "phone": data.phone
        }
        props.onHide()
        props.buyPolicy(payload)
        
    };

    const ValidatePhone = (e) => {
        const inputValue = e.target.value;
        // Define a regular expression to match a valid phone number format
        const phoneRegex = /^[0-9]{0,11}$/; // Assuming a 10-digit phone number
    
        if (phoneRegex.test(inputValue)) {
          // If the input matches the regex, update the state
          setData({ ...data, [e.target.name]: inputValue })
        } 
      
    };

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Body>
                <form className="form-tab">
                    <div>
                        <h5>
                            Send Payment Link
                        </h5>
                        <p><span className="text-danger">*</span>Note: details provided here is where the payment link will be sent too</p>
                    </div>
                    <div className="input-group">
                        <div>
                            <label>Email</label>
                            <input
                                type="email"
                                placeholder="email@email.com"
                                name="email"
                                onChange={(e) =>
                                    setData({ ...data, [e.target.name]: e.target.value })
                                }
                            required/>
                        </div>
                        <div>
                            <label>Phone Number</label>
                            <input
                                type="tel"
                                placeholder="08012345678"
                                name="phone"
                                onChange={ValidatePhone}
                            required/>
                        </div>
                    </div>

                    <div>
                        {/* <p>{error}</p> */}
                    </div>
                    <div className="customer-button">
                        <button onClick={adduser}>Send Payment Link</button>
                    </div>
                </form>
            </Modal.Body>
            {/* {isLoading ? <Loader /> : ""} */}
        </Modal>
    );
}

function Buyhealth() {
    const navigate = useNavigate();
    const [emailmodal, setEmailModal] = useState(false);
    const [paymentmodal, setPaymentModal] =  useState(false)
    

    const click = useRef("");
    const [data, setData] = useState([])
    const [showupdate, setShowUpdate] = useState(false)
    const [reference, setRefrence] = useState('')
    const [page, setPage] = useState(0);
    const { isLoading,  create_hmo_policy, getListOfBulkHmo, healthbulk, error } = usePolicy();
    const {
        healthplan,
        hmo_plan,
        hmo_states,
        state,
    } = useUnderwriter();
    const [customerinfo, setCustomerInfo] = useState({});

    const [healthdata, setHealthData] = useState({
    });

    const [filename, setFilename] = useState("");

    const create_policy = async () => {
        let formData = new FormData();
        formData.append('excel', healthdata.excel);
        formData.append('underwriter_id', 1);
        formData.append('plan_id', healthdata.plan_id);
        formData.append('payment_frequency', healthdata.payment_frequency);
        let result = await create_hmo_policy(formData)
        console.log(result);
        setRefrence(result.reference)
        let list = await getListOfBulkHmo(result.reference)
        setData(list)
        setPage(1)
    }

    const hideemail = () => {
        setEmailModal(false);
        navigate("/policy/health/all");
    };

    const updatelist = async () => {
        let list = await getListOfBulkHmo(reference)
        setData(list)
    }

    

    

    const targetObject = data.every(item =>  item.status === 'Completed');  
    console.log(targetObject);
    const plan = (e) => {
        let dataset = e.target.options[e.target.selectedIndex].dataset;
        setHealthData({
            ...healthdata,
            [e.target.name]: e.target.value, PlanId: dataset.id
        });
    };

    console.log(healthdata);

    const file = () => {
        click.current.click();
    };

    // const check = useRef(false)
    async function buypolicy(data) {
        
        try {
            await healthbulk(data);
            setEmailModal(true)
        } catch (error) {
            // Handle any errors here
            console.log(error);
        }
    }



    useEffect(() => {
        hmo_plan();
        hmo_states();
    }, [hmo_states, hmo_plan]);
    return (
        <div>
            <div className="navigate-back">
                <Link onClick={() => navigate(-1)}>
                    <Arrow />
                </Link>
            </div>
            <div className="vehicle-policy-container">
                <h4 className="dashboard-title">Buy Coporate Health Policy</h4>
                <div className="claims-table-container buy-vehicle-box">
                    <form className="health-form buy-form" autoComplete="off">
                        <div className={`${page === 0 ? "" : "d-none"}`}>
                            <div className="input-group">
                                <div>
                                    <label>Available Plans </label>
                                    <select name="plan_id" onChange={plan}>
                                        <option defaultValue="" disabled selected>
                                            Select Provider
                                        </option>
                                        {healthplan.map((data, index) => {
                                            return (
                                                <option
                                                    key={index}
                                                    value={data.ID}
                                                    data-id={data.PlanId}
                                                >
                                                    {data.Name} {data.MonthlyCost}/{data.AnnualCost}
                                                </option>
                                            );
                                        })}
                                    </select>
                                </div>
                                <div>
                                    <label>Payment frequency</label>
                                    <select
                                        name="payment_frequency"
                                        onChange={(e) =>
                                            setHealthData({
                                                ...healthdata,
                                                [e.target.name]: JSON.parse(e.target.value),
                                            })
                                        }
                                    >
                                        <option>Select Payment frequency</option>
                                        <option value="1">1 Month</option>
                                        <option value="2">2 Month</option>
                                        <option value="3">3 Month</option>
                                        <option value="4">4 Month</option>
                                        <option value="5">5 Month</option>
                                        <option value="6">6 Month</option>
                                        <option value="7">7 Month</option>
                                        <option value="8">8 Month</option>
                                        <option value="9">9 Month</option>
                                        <option value="10">10 Month</option>
                                        <option value="11">11 Month</option>
                                        <option value="12">12 Month</option>
                                    </select>
                                </div>
                            </div>
                            <hr />
                            <div className="input-group">
                                <div className="text-center">
                                    <label>Upload File</label>
                                    <input
                                        type="file"
                                        name="excel"
                                        ref={click}
                                        onChange={(e) => { setHealthData({ ...healthdata, [e.target.name]: e.target.files[0] }); setFilename(e.target.files[0].name) }}
                                        hidden
                                    />
                                    <Uploadicon onClick={file} />
                                    <p className="fw-semibold">
                                        Upload file <span className="text-primary cursor-pointer" style={{ cursor: 'pointer' }} onClick={file}>Here</span>
                                    </p>
                                    <p><span className="text-danger">*</span>Note: Please upload an excel sheet using this format <a href={`${API}/health/policy/template/individual`} download='batch_template_format'>HERE</a></p>
                                    <p>{filename}</p>
                                </div>
                            </div>
                            <div>
                                <p>
                                    {error}
                                </p>
                            </div>
                            <div className="page-control justify-content-end">
                                <div className={`next-box `} onClick={create_policy}>
                                    <div >Next</div>
                                </div>
                            </div>
                        </div>
                        <div className={`${page === 1 ? '' : 'd-none'}`}>
                            <div className="lists-cust">
                                <Table className="">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>First Name</th>
                                            <th>Last Name</th>
                                            <th>Email</th>
                                            <th >Preferred Hospital</th>
                                            <th>Passport</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data.map((data, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>{1 + index++}</td>
                                                    <td>{data.firstname}</td>
                                                    <td>{data.othername}</td>
                                                    <td>{data.email}</td>
                                                    <td className={` ${data.status === 'Incomplete' ? 'text-danger' : 'text-success'}`}>{data.status}</td>
                                                    <td className={` ${data.status === 'Incomplete' ? 'text-danger' : 'text-success'}`}>{data.status === 'Incomplete' ? data.status : 'Added'}</td>
                                                    <td>
                                                        <button type="button" className="submit-button border-0" onClick={() => { setCustomerInfo(data); setShowUpdate(true); setFilename("") }}>
                                                            Update
                                                        </button>
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </Table>

                            </div>
                        </div>
                        <UpdateHealth show={showupdate} customerinfo={customerinfo} healthData={healthdata} update={updatelist} state={state} onHide={() => setShowUpdate(false)} />
                        <div className="page-control justify-content-end">
                        {/* {check.current ? <Paystack config={config} check={check} /> : <div></div>} */}
                            <div className={`next-box ${page === 0 ? 'd-none' : targetObject ? '' : 'opacity-25'} `} onClick={() =>  !targetObject ? null :  setPaymentModal(true)}>
                                <div >Buy</div>
                            </div>
                        </div>
                    </form>
                    <Emailsent show={emailmodal} onHide={hideemail} />
                    <Payment show={paymentmodal} buyPolicy={buypolicy} reference={reference} onHide={() => setPaymentModal(false)} />
                </div>
                {isLoading ? <Loader /> : ""}
            </div>
        </div>
    );
}

export default Buyhealth;
