import React from 'react'
import Modal from 'react-bootstrap/Modal';

function PreviewQuote(props) {
    // console.log(props.quote);
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Body>
                <div className="quotaion-title">
                    <h6>Preview</h6>
                    <p>
                        Vehicle Quote
                    </p>
                </div>
                <div className="input-group underwriter-group">
                    <div>
                        <label>Vehicle Make</label>
                        <p>{props.quote.vehicle_make} </p>
                    </div>
                    <div>
                        <label>Vehicle Model</label>
                        <p>{props.quote.vehicle_model}</p>
                    </div>
                    <div>
                        <label>Vehicle Type</label>
                        <p>{props.quote.vehicle_class}</p>
                    </div>
                </div>
                <div className="input-group underwriter-group">
                    <div>
                        <label>Flood Damage</label>
                        <p>{props.quote.flood_cover}</p>
                    </div>
                    <div>
                        <label></label>
                        <input hidden />
                    </div>
                    <div>
                        <label></label>
                        <input hidden />
                    </div>
                </div>
                <div className="input-group">
                    <div className="total-premium">
                        <label>Total Premium</label>
                        <p>{props.quote.financed_premium === '' ? props.quote.price : props.quote.financed_premium}</p>
                    </div>
                    <div>
                        <label></label>
                        <input hidden />
                    </div>
                    <div>
                        <label></label>
                        <input hidden />
                    </div>
                    <div>
                        <label></label>
                        <input hidden />
                    </div>
                </div>
                <div className="flood-section px-0">
                    <div className="input-group">
                        <div className="total-premium">
                            <label>Monthly Payment</label>
                            <p>{props.quote.price}</p>
                        </div>
                        <div>
                            <label></label>
                            <input hidden />
                        </div>
                        <div>
                            <label></label>
                            <input hidden />
                        </div>
                        <div>
                            <label></label>
                            <input hidden />
                        </div>
                    </div>
                </div>
                <div className={`next-box `}>
                    <div onClick={() => { props.onPage(); props.onHide() }} >Continue</div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default PreviewQuote