import { useCallback, useState } from 'react'
import axios from 'axios'
import { Cookies } from 'react-cookie';
import { API } from '../Helpers/action';

export const useProfile = () => {
    const [error, setError] = useState(null)
    const [isLoading, setIsLoading] = useState(null)
    const [data, setData] = useState([])

    const profile = useCallback(
        async () => {
            setIsLoading(true)
            setError(null)

            const cookies = new Cookies();
            let token =cookies.get('xhrToken')
    
            await axios.get(`${API}/broker/profile`,
            {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            } ).then((response) => {
                // console.log(response);
                setData(response.data.data)
                    setIsLoading(false)
                
            }).catch((err) => {
                setIsLoading(false)
                setError(err.response.data.message)
                // console.log(err.response.data.message)
            })
        }, []
    )

    return { profile, data, isLoading, error}
}
