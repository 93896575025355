import React from 'react';

const Toast = ({ title, message, type }) => {
    let bgColorClass = '';
    switch (type) {
        case 'success':
            bgColorClass = 'bg-success';
            break;
        case 'error':
            bgColorClass = 'bg-danger';
            break;
        case 'pending':
            bgColorClass = 'bg-warning';
            break;
        default:
            bgColorClass = 'bg-secondary';
            break;
    }

    return (
        <div className={`position-fixed top-0 end-0 m-4 p-4 text-white rounded ${bgColorClass}`}>
            <div className="font-bold">{title}</div>
            <div>{message}</div>
        </div>
    );
};

export default Toast;
