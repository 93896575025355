import React, {useEffect, useRef, useState} from 'react'
import { ReactComponent as Arrow } from "../../Assets/icons/back-arrow.svg";
import { Link, useNavigate } from "react-router-dom";
import Table from "react-bootstrap/Table";
// import { usePolicy } from '../../Hooks/policy';
import ReactPaginate from 'react-paginate';
import { ReactComponent as Nexticon } from "../../Assets/icons/nextarrw.svg";
import { ReactComponent as Previcon } from "../../Assets/icons/prevarrw.svg";
import Loader from '../../Components/Loader/Loader';
import { Cookies } from 'react-cookie';
import Dropdown from "react-bootstrap/Dropdown";
import Metadata from "./metadata";
import {useGadget} from "../../Hooks/gadget";


function Gadgetpolicy() {
    const navigate = useNavigate()
    const { getAllPolicies, data, isLoading, policy } = useGadget()
    const [filtereddata, setFiltereddata] = useState([]);
    const [itemOffset, setItemOffset] = useState(0);
    const [showmetadata, setShowMetaData] = useState(false);
    const cookies = new Cookies()
    let itemsPerPage = 7
    const endOffset = itemOffset + itemsPerPage;
    const pageCount = Math.ceil(policy.length / itemsPerPage);
    const pageCount1 = Math.ceil(filtereddata.length / itemsPerPage);
    let getinfo = cookies.get('xirhnfo')


    // function downloadImage(url, name){
    //     fetch(url)
    //         .then(resp => resp.blob())
    //         .then(blob => {
    //             const url = window.URL.createObjectURL(blob);
    //             const a = document.createElement('a');
    //             a.style.display = 'none';
    //             a.href = url;
    //             // the filename you want
    //             a.download = name;
    //             document.body.appendChild(a);
    //             a.click();
    //             window.URL.revokeObjectURL(url);
    //         })
    //         .catch(() => alert('An error sorry'));
    // }

    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % data.length;
        setItemOffset(newOffset);
    };

    const handlePageClick1 = (event) => {
        const newOffset = (event.selected * itemsPerPage) % filtereddata.length;
        setItemOffset(newOffset);
    };
    const metaDetails = useRef({})

    const searchcustomer = (e) => {
        let searchword = e.target.value
        const newfilter = policy.filter((value) => {
            return value.policy.enrollee.toLowerCase().includes(searchword.toLowerCase());
        });
        setFiltereddata(newfilter);
    }

    const allpolicy = policy.slice(itemOffset, endOffset).map((data, index) => {
        return (
            <tr key={index}>
                <td>{itemOffset + 1 + index++}</td>
                <td>{data.enrollee.name}</td>
                {/*<td>{data.plan}</td>*/}
                <td>{data.total_amount}</td>
                <td className={`${data.status === 'Success' ? 'text-success' : data.status === 'Active' ? 'text-success' : data.status === "Completed" ? "text-warning" :  'text-danger'}`}>{data.status}</td>
                <td>{data.start}</td>
                <td>{data.end}</td>
                {/*<td>{data.policy_number}</td>*/}
                <td>
                    <Dropdown>
                    <Dropdown.Toggle
                        variant=""
                        id="dropdown-basic"
                        className="buy-policy"
                    >
                        Action
                    </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item
                                as='div'
                                className="healthdropdown"
                                onClick={() => {metaDetails.current = data; setShowMetaData(true)}}
                            >
                                View MetaData
                            </Dropdown.Item>
                        </Dropdown.Menu>
                </Dropdown>
                </td>
                {/*<td >{data.status === 'Pending' ? <span className='hmo_resend' onClick={() => Health_Resend_Email(data.user_hmo_id, setEmailModal)}>Resend Payment Link</span> : <span className={`${data.id_card === '' ? 'non-activelink' : 'idactivelink'}`} onClick={() => data.id_card === '' ? '' : downloadImage(data.id_card, 'Idcard.pdf')} >View ID</span>}</td>*/}

            </tr>
        );
    })

    const filteredpolicy = filtereddata.slice(itemOffset, endOffset).map((data, index) => {
        return (
            <tr key={index}>
                <td>{itemOffset + 1 + index++}</td>
                <td>{data.enrollee.name}</td>
                {/*<td>{data.plan}</td>*/}
                <td>{data.total_amount}</td>
                <td className={`${data.status === 'Success' ? 'text-success' : data.status === 'Active' ? 'text-success' : data.status === "Completed" ? "text-warning" :  'text-danger'}`}>{data.status}</td>
                <td>{data.start}</td>
                <td>{data.end}</td>
                {/*<td >{data.status === 'Pending' ? <span className='hmo_resend' onClick={() => Health_Resend_Email(data.user_hmo_id, setEmailModal)}>Resend Payment Link</span> : <span className={`${data.id_card === '' ? 'non-activelink' : 'idactivelink'}`} onClick={() => data.id_card === '' ? '' : downloadImage(data.id_card, 'Idcard.pdf')} >View ID</span>}</td>*/}

            </tr>
        );
    })
    // 539546A5
    useEffect(() => {
        // let getinfo = cookies.get('xirhnfo')
        // if (getinfo !== undefined) {
        //     let info = JSON.parse(atob(getinfo))
        //     // console.log(info);
        //     individual_healthpolicy(info.enrollee_id)
        // } else {
        //     healthpolicy()
        // }
        getAllPolicies()
    }, [ getinfo, getAllPolicies])
    return (
        <div>
            <div className="navigate-back">
                <Link onClick={() => navigate(-1)}>
                    <Arrow />
                </Link>
            </div>
            <div className="vehicle-policy-container">
                <h4 className="dashboard-title">Gadget Policy</h4>
                <div>
                    <div className="policy-search">
                        <input type="text" placeholder='Search By Name' onChange={searchcustomer} />
                        <div>Filter</div>
                    </div>
                    <div className="policy-tab-container">
                        <Table className="">
                            <thead>
                            <tr>
                                <th>#</th>
                                <th>Customer</th>
                                {/*<th>Plan</th>*/}
                                <th>Amount</th>
                                <th>Status</th>
                                <th>Start Date</th>
                                {/*<th>Policy Number</th>*/}
                                <th>Due Date</th>
                                {/* <th>Frequency</th> */}
                                <th>Action</th>

                            </tr>
                            </thead>
                            <tbody>
                            {
                                filtereddata.length !== 0 ? filteredpolicy : allpolicy
                            }
                            </tbody>
                        </Table>
                        {
                            filtereddata.length !== 0 ?
                                <ReactPaginate
                                    breakLabel="..."
                                    previousLabel={<Previcon />}
                                    nextLabel={<Nexticon />}
                                    pageCount={pageCount1}
                                    onPageChange={handlePageClick1}
                                    containerClassName={"paginatecontainer"}
                                    previousLinkClassName={"prevbtn"}
                                    pageClassName={"pageli"}
                                    nextLinkClassName={"nextbtn"}
                                    disabledClassName={"paginationdisabled"}
                                    activeClassName={"paginationactive"}
                                    breakClassName={"breakli"}
                                    renderOnZeroPageCount={null}
                                    pageRangeDisplayed={3}
                                    marginPagesDisplayed={2}
                                /> :
                                <ReactPaginate
                                    breakLabel="..."
                                    previousLabel={<Previcon />}
                                    nextLabel={<Nexticon />}
                                    pageCount={pageCount}
                                    onPageChange={handlePageClick}
                                    containerClassName={"paginatecontainer"}
                                    previousLinkClassName={"prevbtn"}
                                    pageClassName={"pageli"}
                                    nextLinkClassName={"nextbtn"}
                                    disabledClassName={"paginationdisabled"}
                                    activeClassName={"paginationactive"}
                                    breakClassName={"breakli"}
                                    renderOnZeroPageCount={null}
                                    pageRangeDisplayed={3}
                                    marginPagesDisplayed={2}
                                />
                        }
                    </div>
                    <Metadata show={showmetadata} data={metaDetails}  onHide={() => setShowMetaData(false)}/>
                </div>
                {isLoading ? <Loader /> : ''}
            </div>
        </div>
    )
}

export default Gadgetpolicy