import React, { useEffect, useState } from 'react'
import '../Stylesheets/wallet.css'
import Table from "react-bootstrap/Table";
import { useBank } from '../Hooks/bank';

function Wallet() {
    const [tab, setTab] = useState("withdraw");
    const { bank, getaccount, addaccount, data, getacc } = useBank()
    const [details, setDetails] = useState({
        "account_number": "",
        "bank_id": ''
    })
    const walletdata = []

    const addbank = () => {
        if(details.account_number !== '' || details.bank_id !== ''){
            addaccount(details)
        }
    }

    useEffect(() => {
        bank()
        getaccount()
    }, [bank, getaccount])
  return (
    <div className='wallet-container'>
        <h4 className="dashboard-title">Wallet</h4>
        <div>
            <div className='wallet-display-grid'>
                <div className='wallet-card'>
                    <h4>
                        TOTAL COMMISSION
                    </h4>
                    <p>
                        ₦0.00
                    </p>
                </div>
                <div className='wallet-card'>
                    <h4>
                        TOTAL AMOUNT WITHDRAWN
                    </h4>
                    <p>
                        ₦0.00
                    </p>
                </div>
            </div>
            <div className='wallet-tab-container'>
                <div className='wallet-tab-title'> 
                    <div className={`${tab === "withdraw" ? "activetab" : ""}`}
            onClick={() => setTab("withdraw")}>
                        Withdraw
                    </div>
                    <div className={`${tab === "add" ? "activetab" : ""}`}
            onClick={() => setTab("add")}>
                        Add Bank Account
                    </div>
                    <div className={`${tab === "fund" ? "activetab" : ""}`}
            onClick={() => setTab("commission")}>
                        Commission History
                    </div>
                </div>
                <div className='wallet-tab-body'>
                    <div className={` ${tab === "withdraw" ? "" : "d-none"}`}>
                        <form className='wallet-form'>
                            <div className='wallet-input'>
                                <label>
                                    Enter amount to withdraw
                                </label>
                                <input type='text' placeholder='Enter amount'/>
                            </div>
                            <div className='wallet-input'>
                                <label>
                                    Select Account
                                </label>
                                <select>
                                    <option>Select</option>
                                    {
                                        getacc.map((data, index) => {
                                            return (
                                                <option key={index} value={data.id}>{data.account_number} - {data.bank.name}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                            <div className='wallet-button'>
                                <button>
                                    Withdraw
                                </button>
                            </div>
                        </form>
                    </div>
                    <div className={` ${tab === "add" ? "" : "d-none"}`}>
                    <form className='wallet-form'>
                            <div className='wallet-input'>
                                <label>
                                    Select Bank
                                </label>
                                <select name='bank_id' onChange={(e) => setDetails({...details, [e.target.name]: JSON.parse(e.target.value)})}>
                                    <option>Select</option>
                                    {
                                        data.map((data, index) => {
                                            return (
                                                <option key={index} value={data.id}>{data.name}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                            <div className='wallet-input'>
                                <label>
                                    Account Number
                                </label>
                                <input type='text' placeholder='Enter amount' name='account_number' onChange={(e) => setDetails({...details, [e.target.name]: e.target.value})}/>
                            </div>
                            <div className='wallet-input'>
                                <label>
                                    Account Name
                                </label>
                                <input type='text' placeholder='Enter amount'/>
                            </div>
                            <div className={`wallet-button wallet-add-button ${details.account_number === '' || details.bank_id === '' ? 'non-active' : ''}`}>
                                <button  onClick={addbank}>
                                    Add Bank Account
                                </button>
                            </div>
                        </form>
                    </div>
                    <div className={` ${tab === "commission" ? "" : "d-none"}`}>
                    <Table >
                            <thead className='transaction-table-title'>
                                <tr>
                                    <th>#</th>
                                    <th>Operation</th>
                                    <th>Amount</th>
                                    <th>Date</th>
                                </tr>
                            </thead>
                            {
                                walletdata.length === 0 ?
                                    <tbody>
                                        <tr >
                                            <td colSpan={4} className='no-data-table' >
                                                No data available in table
                                            </td>
                                        </tr>
                                    </tbody> :
                                    <tbody>
                                        {walletdata.map((data, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>{data.id}</td>
                                                    <td>{data.name}</td>
                                                    <td>{data.date}</td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                            }
                        </Table>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Wallet