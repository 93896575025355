import React from 'react'
import Modal from 'react-bootstrap/Modal';
import { usePaystackPayment } from 'react-paystack';
import { usePolicy } from '../../Hooks/policy';

function PreviewBuyHealth(props) {
    const initializePayment = usePaystackPayment(props.config);
    const {verifyhealthpolicy} = usePolicy()

    const onSuccess = (reference) => {
        verifyhealthpolicy(props.config.reference)
      };
    
      // you can call this function anything
      const onClose = () => {
        // implementation for  whatever you want to do when the Paystack dialog closed.
        // console.log('closed')
      }
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Body>
                <div className="quotaion-title">
                    <h6>Preview</h6>
                    <p>
                        Health Cover
                    </p>
                </div>
                <div className="input-group underwriter-group">
                    <div>
                        <label>First Name</label>
                        <p>{props.healthdata.firstname} </p>
                    </div>
                    <div>
                        <label>Last Name</label>
                        <p>{props.healthdata.surname}</p>
                    </div>
                    <div>
                        <label>Gender</label>
                        <p>{props.healthdata.gender === 1 ? 'Male' : 'Female'}</p>
                    </div>
                </div>
                <div className="input-group underwriter-group">
                    <div>
                        <label>State of residence</label>
                        <p>{props.customer.state}</p>
                    </div>
                    <div>
                        <label>Preffered Hospital</label>
                        <p>{props.customer.preferredhospital}</p>
                    </div>
                    <div>
                        <label></label>
                        <input hidden />
                    </div>
                </div>
                <div className="input-group underwriter-group">
                    <div className='total-premium'>
                        <label>Amount</label>
                        <p>₦{props.config.amount / 100}</p>
                    </div>
                    <div>
                        <label></label>
                        <input hidden />
                    </div>
                    <div>
                        <label></label>
                        <input hidden />
                    </div>
                </div>
                <div className={`next-box `}>
                    <div onClick={() => {props.onHide(); initializePayment(onSuccess, onClose)}}>Continue</div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default PreviewBuyHealth