import React, { useEffect, useState } from 'react'
import '../Stylesheets/claims.css'
import Table from 'react-bootstrap/Table';
import { useClaims } from '../Hooks/claims';
import ReactPaginate from 'react-paginate';
import { ReactComponent as Nexticon } from "../Assets/icons/nextarrw.svg";
import { ReactComponent as Previcon } from "../Assets/icons/prevarrw.svg";
import Loader from '../Components/Loader/Loader';

function Claims() {
    const { getclaim, data, isLoading } = useClaims()
    const [itemOffset, setItemOffset] = useState(0);
    let itemsPerPage = 7
    const endOffset = itemOffset + itemsPerPage;
    const pageCount = Math.ceil(data.length / itemsPerPage);

    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % data.length;

        setItemOffset(newOffset);
    };

    useEffect(() => {
        getclaim()
    }, [getclaim])
    return (
        <div className='claims-containers'>
            <h4 className="dashboard-title">Claims</h4>
            <div className='claims-table-container'>
                <Table>
                    <thead>
                        <tr>
                            <th>S/N</th>
                            <th>Customer</th>
                            <th>Email</th>
                            <th>Policy Number</th>
                            <th>Policy Type</th>
                            <th>Start Date</th>
                            <th>Underwriter</th>
                            <th>Status</th>
                        </tr>
                    </thead>
                    {
                        data.length === 0 ? <tbody>
                            <tr >
                                <td colSpan={8} className='no-data-table' >
                                    No data available in table
                                </td>
                            </tr>
                        </tbody> : <tbody className='table-content'>
                            {
                                data.slice(itemOffset, endOffset).map((data, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>{itemOffset + 1 + index++}</td>
                                            <td>{data.user.lastname + " " + data.user.firstname}</td>
                                            <td>{data.user.email}</td>
                                            <td>{data.metadata.policy_number}</td>
                                            <td>{data.policy_type}</td>
                                            <td>{data.timestamp}</td>
                                            <td>{data.underwriter.name}</td>
                                            <td className={`${data.status === 'Pending' ? 'text-warning' : data.status === 'Accept' ? 'text-success' : data.status === 'Settled' ? 'text-success' : 'text-danger'}`}>{data.status}</td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    }

                </Table>

                <ReactPaginate
                    breakLabel="..."
                    previousLabel={<Previcon />}
                    nextLabel={<Nexticon />}
                    pageCount={pageCount}
                    onPageChange={handlePageClick}
                    containerClassName={"paginatecontainer"}
                    previousLinkClassName={"prevbtn"}
                    pageClassName={"pageli"}
                    nextLinkClassName={"nextbtn"}
                    disabledClassName={"paginationdisabled"}
                    activeClassName={"paginationactive"}
                    breakClassName={"breakli"}
                    renderOnZeroPageCount={null}
                    pageRangeDisplayed={3}
                    marginPagesDisplayed={2}
                />
                {isLoading ? <Loader /> : ''}
            </div>
        </div>
    )
}

export default Claims