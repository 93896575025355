import React from 'react'
import Modal from 'react-bootstrap/Modal';

function metaData(props) {
    console.log(props.data.current)

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Body>
                <div className="quotaion-title">
                    <h6>MetaData</h6>
                    <p>
                        Gadget Cover
                    </p>
                </div>
                <div className="d-flex justify-content-around py-4">
                    <div>
                        <label className="fw-semibold" >First Name</label>
                        <p className="fw-semibold" >{props.data.current?.metadata?.Firstname} </p>
                    </div>
                    <div>
                        <label className="fw-semibold" >Last Name</label>
                        <p className="fw-semibold" >{props.data.current?.metadata?.Lastname}</p>
                    </div>
                    <div>
                        <label className="fw-semibold" >Email</label>
                        <p className="fw-semibold" >{props.data.current?.metadata?.Email}</p>
                    </div>
                    <div>
                        <label className="fw-semibold" >Occupation</label>
                        <p className="fw-semibold" >{props.data.current?.metadata?.Occupation}</p>
                    </div>
                </div>
                <div className="d-flex justify-content-around py-4">
                    <div>
                        <label className="fw-semibold" >Phone</label>
                        <p className="fw-semibold" >{props.data.current?.metadata?.Phone1}</p>
                    </div>
                    <div>
                        <label className="fw-semibold" >Nationality</label>
                        <p className="fw-semibold" >{props.data.current?.metadata?.Nationality}</p>
                    </div>
                    <div>
                        <label className="fw-semibold" >Address</label>
                        <p className="fw-semibold" >{props.data.current?.metadata?.Address}</p>
                    </div>
                    <div>
                        <label className="fw-semibold" >Registered Address</label>
                        <p className="fw-semibold" >{props.data.current?.metadata?.RegisteredAddress}</p>
                    </div>
                </div>
                <div className="px-4">
                    <hr/>
                    <div className="">
                        <p className="mini-header" >
                            Gadgets Details
                        </p>
                        {props.data.current?.metadata?.Gadgets.map((gadget, index) => {
                            return (
                                <div key={index} className="input-group">
                                    <div className=''>
                                        <label className="fw-semibold" >Name</label>
                                        <p className="fw-semibold" >{gadget?.name}</p>
                                    </div>
                                    <div>
                                        <label className="fw-semibold" >Amount</label>
                                        <p className="fw-semibold" >{gadget?.amount}</p>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>

                <div className="px-4">
                    <div className='total-premium'>
                        <label className="fw-semibold" >Amount</label>
                        <p className="fw-semibold" >{props.data.current?.premium}</p>
                    </div>
                    <div>
                        <label className="fw-semibold" ></label>
                        <input hidden />
                    </div>
                    <div>
                        <label className="fw-semibold" ></label>
                        <input hidden />
                    </div>
                </div>
                {/*<div className={`next-box `}>*/}
                {/*    <div >Continue</div>*/}
                {/*</div>*/}
            </Modal.Body>
        </Modal>
    )
}

export default metaData