import React, { useRef, useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import { useCustomer } from '../../Hooks/customer';
import Loader from '../Loader/Loader';
import { ReactComponent as Upload } from "../../Assets/icons/upload.svg";
import Example from "../../Assets/example.xlsx";

function Addbulkcustomer(props) {
    const { addbulkcustomer, isLoading, error } = useCustomer()
    const click = useRef()
    const [data, setData] = useState({
        excel: null
    })
    const [filename, setFilename] = useState('')
    // console.log(data.excel);

    const adduser = async(e) => {
        e.preventDefault()
        await addbulkcustomer(data, props.onHide)
    }

    const file = () => {
        click.current.click()
      };

  return (
    <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Body>
            <form className='form-tab'>
                        <div>
                            <label>
                                Upload excel file
                            </label>
                            <input type='file' ref={click} onChange={(e) => { setData({...data, excel: e.target.files[0]}); setFilename(e.target.files[0].name)}} hidden/>
                            <div className='text-center'>
                                <Upload onClick={file} />
                                <p>Upload file <span className='text-primary' onClick={file} >here</span></p>
                                <p>{filename}</p>
                                <a href = {Example} download = "file">Download Sample of Excel File</a>
                            </div>
                        </div>
                        <div>
                            <p>{error}</p>
                        </div>
                        <div className='customer-button'>
                            <button onClick={adduser}>Add customer</button>
                        </div>
                    </form>
            </Modal.Body>
            {isLoading ? <Loader /> : '' }
        </Modal>
  )
}

export default Addbulkcustomer