import React from 'react';
import Modal from 'react-bootstrap/Modal';
import '../../Stylesheets/deleteconfirmation.css'

function Delete(props) {
  return (
    <Modal
      {...props}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
        <Modal.Header className='delete-header'>
          <Modal.Title >Delete</Modal.Title>
        </Modal.Header>
      <Modal.Body>
        <div className='delete-body'>
            <p>
                Are you sure you want to delete 
            </p>
            <div className='delete-button-container'>
                <button>
                    Yes
                </button>
                <button onClick={() => props.setModalShow(false)}>
                    No
                </button>
            </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default Delete