import React, { useEffect, useState } from 'react'
import '../Stylesheets/profile.css'
import { ReactComponent as Eye } from '../Assets/icons/eye.svg'
import { ReactComponent as Eyeslash } from '../Assets/icons/eye-slash.svg'
import { useProfile } from '../Hooks/profile'

function Profile() {
    const [reset, setReset] = useState(false)
    const [view, setView] = useState(false)
    const { profile, data } = useProfile()
    const passwordview = () => {
        const element = document.querySelector('#password')
        element.setAttribute("type", "text");
        setView(true)
    }

    const passwordhide = () => {
        const element = document.querySelector('#password')
        element.setAttribute("type", "password");
        setView(false)
    }

    useEffect(() => {
        profile()
    }, [profile])
    return (
        <div className='profile-container'>
            <h4 className="dashboard-title">Profile</h4>
            <div className='profile-info-container'>
                <div className='profile-welcome'>
                    <h4>Welcome <span>Habeeb</span></h4>
                </div>
                <h4>Bio</h4>
                <div className='profile-display-container'>
                    <div className='profile-display-group'>
                        <div>
                            <label>
                                Name
                            </label>
                            <input value={data.lastname + ' ' + data.firstname} readOnly />
                        </div>
                        <div>
                            <label>
                                Email
                            </label>
                            <input value={data.email} readOnly />
                        </div>
                    </div>
                    <div className='profile-display-group'>
                        <div>
                            <label>
                                Phone Number
                            </label>
                            <input value={data.phone} readOnly />
                        </div>
                    </div>
                </div>
                <div className='reset-password-container'>
                    <h4>Reset Password</h4>
                    <div className={`${reset ? '' : 'd-none'}`}>
                        <form className='reset-password-form'>
                            <div>
                                <label>
                                    Current Password
                                </label>
                                <input type='password' />
                            </div>
                            <div>
                                <label>
                                    New Password
                                </label>
                                <div className='passwordgroup'>
                                    <input type='password' name="password" id='password' />
                                    <span onClick={passwordview} className={`${view ? 'd-none' : ''}`}><Eyeslash /></span>
                                    <span onClick={passwordhide} className={`${view ? '' : 'd-none'}`}><Eye /></span>
                                </div>
                            </div>
                            <button className='reset-password-button'>Reset Password</button>
                        </form>
                    </div>
                    <button className={`reset-password-button ${reset ? 'd-none' : ''}`} onClick={() => setReset(true)}>Change Password</button>
                </div>
            </div>
        </div>
    )
}

export default Profile