import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import '../../Stylesheets/cancel.css'
import { usePolicy } from '../../Hooks/policy';
import Loader from '../Loader/Loader';

function Cancel(props) {
    const [data, setData] = useState({
        "user_vehicle_id": props.vehicle_id,
        "reason": ''
    })
    const { cancelpolicy, isLoading } = usePolicy()

    const cancel = async() => {
       await cancelpolicy(data, props.onHide)
    }

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Body>
                <div className='cancelbody'>
                    <h6>Hi Our Paddy, we will like to know why you are cancelling your policy</h6>
                    <div className="flood-radio cancel-radio">
                        <input
                            type="radio"
                            id="reason"
                            name="reason"
                            checked={data.reason === 'I sold my car' ? true : false}
                            value="I sold my car"
                            onChange={(e) => setData({
                                ...data,
                                reason: e.target.value,
                                user_vehicle_id: props.vehicle_id
                            })}
                        />
                        <label htmlFor="html">I sold my car</label>
                    </div>
                    <div className="flood-radio cancel-radio">
                        <input
                            type="radio"
                            id="reason"
                            name="reason"
                            checked={data.reason === 'I want to change the Underwriter' ? true : false}
                            value="I want to change the Underwriter"
                            onChange={(e) => setData({
                                ...data,
                                reason: e.target.value,
                                user_vehicle_id: props.vehicle_id
                            })}
                        />
                        <label htmlFor="html">I want to change the Underwriter/Insurance provider</label>
                    </div>
                    <div>
                        <label className='typereason'>
                            Others (please state reason)
                        </label>
                        <textarea rows={4} name='reason' onChange={(e) => setData({ ...data, [e.target.name]: e.target.value, user_vehicle_id: props.vehicle_id })} />
                    </div>
                    <div className='initiate-cancel-button' onClick={cancel}>
                        <p>Initiate Cancel</p>
                    </div>
                </div>
                {isLoading ? <Loader /> : '' }
            </Modal.Body>
        </Modal>
    );
}

export default Cancel