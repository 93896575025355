import React, {useState} from "react";
import "../Stylesheets/policy.css";
import { ReactComponent as Vehicleicon } from "../Assets/icons/vehiclepolicy.svg";
import { ReactComponent as Vehicleclaim } from "../Assets/icons/vehicleclaims.svg";
import { ReactComponent as Buypolicy } from "../Assets/icons/buypolicy.svg";
import { Link, useNavigate } from "react-router-dom";
import { ReactComponent as Arrow } from "../Assets/icons/back-arrow.svg";
import SearchCustomer from "../Components/Customer/SearchCustomer";

function Vehicle() {
  let navigate = useNavigate()
  const [show, setShow] = useState(false)

  return (
    <div className="policy-container">
      <div className="navigate-back px-0">
        <Link onClick={() => navigate(-1)}>
          <Arrow />
        </Link>
      </div>
      <div className="policies-card-container">
        <h4 className="dashboard-title policy-title">Vehicle Policy</h4>
        <div className="policy-card-container">
          <Link to="all" className="policy-card">
            <div>
              <h4>All Policies</h4>
              <span>View polices</span>
            </div>
            <Vehicleicon />
          </Link>
          <Link to='buy'  className="policy-card">
            <div>
              <h4>Buy Policy</h4>
              <span>Purchase</span>
            </div>
            <Buypolicy />
          </Link>
          <Link to='claim' className="policy-card">
            <div>
              <h4>Claims</h4>
              <span>View claims</span>
            </div>
            <Vehicleclaim />
          </Link>
        </div>
      </div>
      <SearchCustomer show={show} onHide={()=> setShow(false)} />
    </div>
  );
}

export default Vehicle;
