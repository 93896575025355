import React from 'react'
import '../../Stylesheets/sidebar.css'
import { ReactComponent as Logo } from '../../Assets/icons/logo.svg'
import { ReactComponent as Dashboard } from '../../Assets/icons/dashboardicon.svg'
import { ReactComponent as Customers } from '../../Assets/icons/underwritersicon.svg'
import { ReactComponent as Profile } from '../../Assets/icons/profileicon.svg'
import { ReactComponent as Policy } from '../../Assets/icons/policyicon.svg'
import { ReactComponent as Claims } from '../../Assets/icons/claimsicon.svg'
import { ReactComponent as Wallet } from '../../Assets/icons/walleticon.svg'
import { ReactComponent as Logout } from '../../Assets/icons/logouticon.svg'
import { ReactComponent as Arrowright } from '../../Assets/icons/arrow-right.svg'
import { ReactComponent as Arrowleft } from '../../Assets/icons/arrow-left.svg'
import { Link, useLocation } from 'react-router-dom'
import Offcanvas from 'react-bootstrap/Offcanvas';
import { useLogout } from '../../Hooks/logout'
import { Cookies } from 'react-cookie'

function Sidebar(props) {
    const {logout} = useLogout()
    const location = useLocation();
    let pagename = location.pathname;
    pagename = pagename.slice(1);
    const cookies = new Cookies();
    let data = JSON.parse(atob(cookies.get('xhrUserxx')))
    const handleLogout = () => {
        logout()
    }
    
    return (
        <div>
            <div>
                <div className='sidebarcontainer'>
                    <div className='sideicons-container'>
                        <div className='shortname'>
                            {data.lastname[0] + '' + data.firstname[0]}
                        </div>
                        <div className='sideicon-list'>
                            <Link to='/dashboard' className={`sidebaricons ${pagename === 'dashboard' ? 'activesidebar' : ''}`}>
                                <Dashboard />
                                <h4 className={`sideicon-tag ${props.show ? '' : 'd-none'}`}>
                                    Dashboard
                                </h4>
                            </Link>
                            <Link to='/customer' className={`sidebaricons sidebaricons-opt ${pagename === 'customer' ? 'activesidebar-opt' : ''}`}>
                                <Customers />
                                <h4 className={`sideicon-tag ${props.show ? '' : 'd-none'}`}>Customers</h4>
                            </Link>
                            <Link to='/claims' className={`sidebaricons sidebaricons-opt ${pagename === 'claims' ? 'activesidebar-opt' : ''}`}>
                                <Claims />
                                <h4 className={`sideicon-tag ${props.show ? '' : 'd-none'}`}>
                                    Claims
                                </h4>
                            </Link>
                            <Link to='/policy' className={`sidebaricons sidebaricons-opt ${pagename === 'policy' ? 'activesidebar-opt' : ''}`}>
                                <Policy />
                                <h4 className={`sideicon-tag ${props.show ? '' : 'd-none'}`}>
                                    Policy
                                </h4>
                            </Link>
                            <Link to='/account' className={`sidebaricons sidebaricons-opt ${pagename === 'wallet' ? 'activesidebar-opt' : ''}`}>
                                <Wallet />
                                <h4 className={`sideicon-tag ${props.show ? '' : 'd-none'}`}>
                                    Account
                                </h4>
                            </Link>
                            <Link to='/profile' className={`sidebaricons sidebaricons-opt ${pagename === 'profile' ? 'activesidebar-opt' : ''}`}>
                                <Profile />
                                <h4 className={`sideicon-tag ${props.show ? '' : 'd-none'}`}>
                                    Profile
                                </h4>
                            </Link>
                            <Link className={`sidebaricons sidebaricons-opt`} onClick={handleLogout}>
                                <Logout />
                                <h4 className={`sideicon-tag ${props.show ? '' : 'd-none'}`}>
                                    Log out
                                </h4>
                            </Link>
                        </div>
                    </div>
                    <div className='sidebarlist-container'>
                        <div className={`sidebarlist ${props.show ? 'd-none' : ''}`}>
                            <div className={`sidebar-name ${pagename === 'dashboard' ? 'activesidebarname' : ''}`}>
                                <Link to='/dashboard'>
                                    Dashboard
                                </Link>
                            </div>
                            <div className={`sidebar-name ${pagename === 'customer' ? 'activesidebarname' : ''}`}>
                                <Link to='/customer'>
                                    Customers
                                </Link>
                            </div>
                            <div className={`sidebar-name ${pagename === 'claims' ? 'activesidebarname' : ''}`}>
                                <Link to='/claims'>
                                    Claims
                                </Link>
                            </div>
                            <div className={`sidebar-name ${pagename === 'policy' ? 'activesidebarname' : ''}`}>
                                <Link to='/policy'>
                                    Policy
                                </Link>
                            </div>
                            <div className={`sidebar-name ${pagename === 'wallet' ? 'activesidebarname' : ''}`}>
                                <Link to='/account'>
                                    Account
                                </Link>
                            </div>
                            <div className={`sidebar-name ${pagename === 'profile' ? 'activesidebarname' : ''}`}>
                                <Link to='/profile'>
                                    Profile
                                </Link>
                            </div>
                            <div className={`sidebar-name`}>
                                <Link onClick={handleLogout}>
                                    Log out
                                </Link>
                            </div>
                        </div>
                        {
                            props.show ? <div className='arr-right'>
                                <Arrowright onClick={() => props.setShow(!props.show)} />
                            </div> : <div className='arr-right'>
                                <Arrowleft onClick={() => props.setShow(!props.show)} />
                            </div>
                        }


                    </div>
                </div>
            </div>
            <div className=''>
                <Offcanvas className='mobile-sidebar' show={props.mobileshow} onHide={() => props.setMobileShow(false)}>
                    <Offcanvas.Header closeButton>
                        <Offcanvas.Title>
                            <Logo />
                        </Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                    <div className='sideicon-list'>
                            <Link to='/dashboard' className={`sidebaricons ${pagename === 'dashboard' ? 'activesidebar' : ''}`} onClick={() => props.setMobileShow(false)}>
                                <Dashboard />
                                <h4 className=''>
                                    Dashboard
                                </h4>
                            </Link>
                            <Link to='/customer' className={`sidebaricons sidebaricons-opt ${pagename === 'customer' ? 'activesidebar-opt' : ''}`} onClick={() => props.setMobileShow(false)}>
                                <Customers />
                                <h4 className=''>Customers</h4>
                            </Link>
                            <Link to='/claims' className={`sidebaricons sidebaricons-opt ${pagename === 'claims' ? 'activesidebar-opt' : ''}`} onClick={() => props.setMobileShow(false)}>
                                <Claims />
                                <h4 className=''>
                                    Claims
                                </h4>
                            </Link>
                            <Link to='/policy' className={`sidebaricons sidebaricons-opt ${pagename === 'policy' ? 'activesidebar-opt' : ''}`} onClick={() => props.setMobileShow(false)}>
                                <Policy />
                                <h4 className=''>
                                    Policy
                                </h4>
                            </Link>
                            <Link to='/account' className={`sidebaricons sidebaricons-opt ${pagename === 'wallet' ? 'activesidebar-opt' : ''}`} onClick={() => props.setMobileShow(false)}>
                                <Wallet />
                                <h4 className=''>
                                    Account
                                </h4>
                            </Link>
                            <Link to='/profile' className={`sidebaricons sidebaricons-opt ${pagename === 'profile' ? 'activesidebar-opt' : ''}`} onClick={() => props.setMobileShow(false)}>
                                <Profile />
                                <h4 className=''>
                                    Profile
                                </h4>
                            </Link>
                            <Link  className={`sidebaricons sidebaricons-opt `} onClick={handleLogout}>
                                <Logout />
                                <h4 className=''>
                                    Logout
                                </h4>
                            </Link>
                        </div>
                    </Offcanvas.Body>
                </Offcanvas>
            
            </div>
        </div >
    )
}

export default Sidebar