import React, { useEffect, useState } from 'react'
import Dropdown from 'react-bootstrap/Dropdown';
import '../Stylesheets/customer.css'
import Table from "react-bootstrap/Table";
import { ReactComponent as Adduser } from "../Assets/icons/addcustomer.svg";
import { ReactComponent as Multipleuser } from "../Assets/icons/multipleuser.svg";
import Addcustomer from '../Components/Customer/Add-customer';
import { useCustomer } from '../Hooks/customer';
import { ReactComponent as Nexticon } from "../Assets/icons/nextarrw.svg";
import { ReactComponent as Previcon } from "../Assets/icons/prevarrw.svg";
import ReactPaginate from 'react-paginate';
import Loader from '../Components/Loader/Loader';
import { useNavigate } from 'react-router-dom';
import { Cookies } from 'react-cookie';
import Addbulkcustomer from '../Components/Customer/Addbulk-customer';
import Editcustomer from "../Components/Customer/Edit-Customer";
// import { useBuyVehiclePolicy } from '../Hooks/buy-vehiclepolicy';

function Customer() {
    const [modalShow, setModalShow] = useState(false);
    const [addmodal, setAddModal] = useState(false);
    const [editmodal, setEditModal] = useState(false);
    const [stateAvailable, setStateAvailable] = useState('false');
    // const [automodalShow, setAutoModalShow] = useState(false);
    const { customer, data, isLoading, states, gender, liststate, listgenders } = useCustomer();
    const [filtereddata, setFiltereddata] = useState([]);
    const [itemOffset, setItemOffset] = useState(0);
    // const {
    //     paymentmode,
    //   } = useBuyVehiclePolicy();
    let cookies = new Cookies()
    let navigate = useNavigate()
    let itemsPerPage = 4
    const endOffset = itemOffset + itemsPerPage;
    const pageCount = Math.ceil(data.length / itemsPerPage);

    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % data.length;

        setItemOffset(newOffset);
    };
    const [customerinfo, setCustomerinfo] = useState({
        firstname: '',
        lastname: '',
        email: '',
        phone: '',
        agent_user_id: '',
        address: ''
    })
    const [lga, setLga] = useState([])
    const editCustomer = (data) => {
        setStateAvailable('true')
        console.log(data)
        const maxAge = 1 * 1 * 60 * 60
        console.log(liststate)
        const searchObject = liststate.find(
            (state) => state.id === data.state_id
        );
        if (searchObject) {
            setLga(searchObject.lgas)
        }
        let info = btoa(JSON.stringify(data))
        cookies.set('xirhnfo', info, { path: '/', maxAge: maxAge, sameSite: 'lax', secure: true })
        setEditModal(true)
    }

    const manage = (data) => {
        const maxAge = 1 * 1 * 60 * 60
        let info = btoa(JSON.stringify(customerinfo))
        cookies.set('xirhnfo', info, { path: '/', maxAge: maxAge, sameSite: 'lax', secure: true })
        navigate(`/policy/${data}`)
    }

    const searchcustomer = (e) => {
        let searchword = e.target.value
        const newfilter = data.filter((value) => {
            let name = value.lastname + " " + value.firstname
            return name.toLowerCase().includes(searchword.toLowerCase());
            });
            setFiltereddata(newfilter);
    }
    
    const allcustomer = data.slice(itemOffset, endOffset).map((data, index) => {
        return (
            <tr key={index}>
                <td>{itemOffset + 1 + index++}</td>
                <td>{data.lastname + ' ' + data.firstname}</td>
                <td>{data.email}</td>
                <td colSpan={2}>{data.address}</td>
                <td>{data.phone}</td>
                <td>
                    <Dropdown>
                        <Dropdown.Toggle variant="" id="dropdown-basic" className='buy-policy' onMouseOver={() => setCustomerinfo({...customerinfo, firstname: data.firstname, lastname: data.lastname, email: data.email, phone: data.phone, agent_user_id: data.id, address: data.address })}>
                            Manage
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <Dropdown.Item  className='healthdropdown' onClick={() => editCustomer(data)}>Edit Customer</Dropdown.Item>
                            <Dropdown.Item  className='healthdropdown' onClick={() => manage('health')}>Manage Health Policy</Dropdown.Item>
                            <Dropdown.Item  className='healthdropdown' onClick={() => { manage('vehicle')}}>Manage Vehicle Policy</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </td>
            </tr>
        );
    })

    const filteredcustomer = filtereddata.map((data, index) => {
        return (
            <tr key={index}>
                <td>{itemOffset + 1 + index++}</td>
                <td>{data.lastname + ' ' + data.firstname}</td>
                <td>{data.email}</td>
                <td colSpan={2}>{data.address}</td>
                <td>{data.phone}</td>
                <td>
                    <Dropdown>
                        <Dropdown.Toggle variant="" id="dropdown-basic" className='buy-policy' onMouseOver={() => setCustomerinfo({...customerinfo, firstname: data.firstname, lastname: data.lastname, email: data.email, phone: data.phone, agent_user_id: data.id, address: data.address })}>
                            Manage
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <Dropdown.Item  className='healthdropdown' onClick={() => editCustomer(data)}>Edit Customer</Dropdown.Item>
                            <Dropdown.Item  className='healthdropdown' onClick={() => manage('health')}>Manage Health Policy</Dropdown.Item>
                            <Dropdown.Item  className='healthdropdown' onClick={() => manage('vehicle')}>Manage Vehicle Policy</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </td>
            </tr>
        );
    })

    useEffect(() => {
        customer();
        states();
        gender();
    }, [customer, states, gender])
    return (
        <div className='customer-container'>
            <h4 className="dashboard-title">Customers</h4>
            <div className='policy-card-container'>
                <div className="policy-card add-customer-card" onClick={() => setAddModal(true)}>
                    <div>
                        <h4>Add Customer</h4>
                        <span>add customer</span>
                    </div>
                    <Adduser  />
                </div>
                <div className="policy-card add-customer-card" onClick={() => setModalShow(true)}>
                    <div>
                        <h4>Add Bulk Customer</h4>
                        <span>add customer</span>
                    </div>
                    <Multipleuser />
                </div>
            </div>
            <div className="policy-search customer-search">
            <input type="text" onChange={searchcustomer} />
            <div>Filter</div>
          </div>
            <div className='customer-modal'>
                <div className='customer-modal-body'>
                    <div className='lists-cust'>
                        <Table className='' >
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Name</th>
                                    <th>Email</th>
                                    <th colSpan={2}>Address</th>
                                    <th>Phone Number</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    filtereddata.length !== 0 ? filteredcustomer : allcustomer
                                }
                            </tbody>
                        </Table>
                        {
                            filtereddata.length !==0 ? '' : 
                            <ReactPaginate
                            breakLabel="..."
                            previousLabel={<Previcon />}
                            nextLabel={<Nexticon />}
                            pageCount={pageCount}
                            onPageChange={handlePageClick}
                            containerClassName={"paginatecontainer"}
                            previousLinkClassName={"prevbtn"}
                            pageClassName={"pageli"}
                            nextLinkClassName={"nextbtn"}
                            disabledClassName={"paginationdisabled"}
                            activeClassName={"paginationactive"}
                            breakClassName={"breakli"}
                            renderOnZeroPageCount={null}
                            pageRangeDisplayed={3}
                            marginPagesDisplayed={2}
                        />
                        }
                        {/* <Buyhealth show={modalShow} onHide={() => setModalShow(false)} />
                        <Buyauto show={automodalShow} onHide={() => setAutoModalShow(false)} /> */}
                    </div>
                    <Addcustomer show={addmodal}  states={liststate} genders={listgenders}  onHide={() => setAddModal(false)} />
                    <Editcustomer show={editmodal}  states={liststate} genders={listgenders}  onHide={() => setEditModal(false)} stateavailable={stateAvailable} lga={lga}/>
                    <Addbulkcustomer show={modalShow}  onHide={() => setModalShow(false)} />
                </div>
                {isLoading ? <Loader /> : '' }
            </div>
        </div>
    )
}

export default Customer