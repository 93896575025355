import { useCallback, useState } from 'react'
import axios from 'axios'
import { Cookies } from 'react-cookie';
import { API } from '../Helpers/action';

export const useTransaction = () => {
    const [error, setError] = useState(null)
    const [isLoading, setIsLoading] = useState(null)
    const [data, setData] = useState([])

    const transaction = useCallback(
        async () => {
            setIsLoading(true)
            setError(null)

            const cookies = new Cookies();
            let token =cookies.get('xhrToken')
    
            await axios.get(`${API}/broker/transactions`,
            {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            } ).then((response) => {
                setData(response.data.data.transactions === null ? [] : response.data.data.transactions);
                    setIsLoading(false)
                
            }).catch((err) => {
                setIsLoading(false)
                setError(err.response.data.message)
                // console.log(err.response.data.message)
            })
        }, []
    )

    const filterTransaction = useCallback(
        async (type) => {
            setIsLoading(true)
            setError(null)

            const cookies = new Cookies();
            let token =cookies.get('xhrToken')

            await axios.get(`${API}/broker/transactions`,
                {
                    params: {
                        filter: type
                    },
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                } ).then((response) => {
                setData(response.data.data.transactions === null ? [] : response.data.data.transactions);
                setIsLoading(false)

            }).catch((err) => {
                setIsLoading(false)
                setError(err.response.data.message)
                // console.log(err.response.data.message)
            })
        }, []
    )

    return { transaction, filterTransaction, data, isLoading, error}
}
