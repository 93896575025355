import React, { useEffect } from "react";
import "../Stylesheets/dashboard.css";
import Table from "react-bootstrap/Table";
import { ReactComponent as Customer } from "../Assets/icons/customer.svg";
import { ReactComponent as Vehicle } from "../Assets/icons/vehiclepolicy.svg";
import { ReactComponent as Health } from "../Assets/icons/healthpolicy.svg";
import { ReactComponent as Commision } from "../Assets/icons/commisionicon.svg";
import { useTransaction } from "../Hooks/transaction";
import { useDashboard } from "../Hooks/dashboard";
import { Link } from "react-router-dom";
import Loader from "../Components/Loader/Loader";
import { Cookies } from "react-cookie";

function Dashboard() {
  const { transaction, data, isLoading, filterTransaction } = useTransaction()
  const { dashboard, dash } = useDashboard()
  const cookies = new Cookies()
  const removecookies = () => {
    cookies.remove('xirhnfo')
}

const getFilteredTransaction = async (type) => {
   await filterTransaction(type)
}

  useEffect(() => {
    transaction()
    dashboard()
  }, [transaction, dashboard])
  return (
    <div className="dashboard-container">
      <h4 className="dashboard-title">Dashboard</h4>
      <div className="dashboard-grid">
        <Link to='/customer' className="policy-card dashboard-card">
          <div>
            <h4>Customers</h4>
            <p>{dash.customerCount}</p>
          </div>
          <Customer />
        </Link>
        <Link to='/policy/vehicle' className="policy-card dashboard-card" onMouseOver={removecookies}>
          <div>
            <h4>Vehicle Policy</h4>
            <p>{dash.vehiclePolicyCount}</p>
          </div>
          <Vehicle />
        </Link>
        <Link to='/policy/health' className="policy-card dashboard-card" onMouseOver={removecookies}>
          <div>
            <h4>Health Policy</h4>
            <p>{dash.healthPolicyCount}</p>
          </div>
          <Health />
        </Link>
        <Link to='/wallet' className="policy-card dashboard-card">
          <div>
            <h4>Commissions</h4>
            <p>0</p>
          </div>
          <Commision />
        </Link>
      </div>
      <div className="dashboard-table">
        <div className={`d-flex justify-content-between`}>
          <h4>Brokers' Recent Transactions</h4>
          <div className={`d-flex gap-2 align-items-center w-25`}>
            <label className={`w-fit`}>Filter By:</label>
            <select className={`filteredTransactionSelect w-75`} onChange={(e) => getFilteredTransaction(e.target.value)}>
              <option defaultValue='' disabled>
                Select Product
              </option>
              <option value='vehicle'>Vehicle Policy</option>
              <option value='health'>Health Policy</option>
              <option value='travel'>Travel Policy</option>
              <option value='gadget'>Gadget Policy</option>
            </select>
          </div>
        </div>
        <div className="dashboard-list-container">
          <Table>
            <thead>
            <tr>
              <th>#</th>
              <th>Customer</th>
              <th>Amount</th>
              <th>Policy Type</th>
              <th colSpan={2}>Description</th>
              <th>Status</th>
              <th>TrxDate</th>
            </tr>
            </thead>
            { data.length === 0 ?
              <tbody>
                <tr>
                  <td colSpan={8} className='no-data-table'>
                    No transaction available for this policy
                  </td>
                </tr>
              </tbody> :
              <tbody>
            {data.slice(0, 4).map((data, index) => {
              return (
              <tr key={index}>
            <td>{index + 1}</td>
            <td>{data.enrollee.lastname + ' ' + data.enrollee.firstname}</td>
            <td>{data.amount}</td>
            <td>{data.policy}</td>
            <td colSpan={2}>{data.description}</td>
            <td>{data.status}</td>
            <td>{data.date_time}</td>
          </tr>
          );
          })}
        </tbody>
        }
          </Table>
        </div>
        {isLoading ? <Loader/> : ''}
      </div>
    </div>
  );
}

export default Dashboard;
