import './App.css';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom'
// import Forgotpassword from './Pages/Forgotpassword';
import Layout from './Pages/Layout';
import Home from './Pages/Home';
import Forgotpassword from './Pages/Forgotpassword';
import { PrivateRoutes } from './Hooks/protectedroutes';
import {ToastProvider} from "./Store/NotificationContext";

function App() {
  return (
    <div className="App">
        <Router>
          <ToastProvider>
            <Routes>
              <Route index exact element={<Home/>} />
              <Route path='/forgotpassword' exact element={<Forgotpassword />} />
              <Route element={<PrivateRoutes />}>
                <Route path='/*' exact element={<Layout/>} />
              </Route>
            </Routes>
          </ToastProvider>
        </Router>
    </div>
  );
}

export default App;
