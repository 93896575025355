import React, { useEffect, useState } from "react";
import { ReactComponent as Arrow } from "../Assets/icons/back-arrow.svg";
import { Link, useNavigate } from "react-router-dom";
import Table from "react-bootstrap/Table";
import Delete from "../Components/modal/Deleteconfirmation";
import Dropdown from "react-bootstrap/Dropdown";
import { usePolicy } from "../Hooks/policy";
import ReactPaginate from 'react-paginate';
import { ReactComponent as Nexticon } from "../Assets/icons/nextarrw.svg";
import { ReactComponent as Previcon } from "../Assets/icons/prevarrw.svg";
import PreviewBuyvehicle from "../Components/Vehicle/Preview-buy-vehicle";
import Repaymentlogs from "../Components/Vehicle/Repayment-log";
import Loader from "../Components/Loader/Loader";
import Cancel from '../Components/Vehicle/Cancel_policy'
import { Cookies } from "react-cookie";
import Emailsent from "../Components/modal/Email-modal";
import TransferPayment from "../Components/modal/Transfermodal";

function Vechiclepolicy() {
  const [modalShow, setModalShow] = useState(false);
  const [transfermodal, setTransferModal] = useState(false);
  const [cancelmodal, setCancelModal] = useState(false);
  const [show, setShow] = useState(false);
  const cookies = new Cookies()
  const [showlog, setShowlog] = useState(false);
  const [emailmodal, setEmailModal] = useState(false);
  const [repayment, setRepayment] = useState([]);
  const [filtereddata, setFiltereddata] = useState([]);
  const [id, setId] = useState('')
  const [quote, setQuote] = useState({
    price: ''
  })
  const [userid, setUserId] = useState(0)
  const navigate = useNavigate()
  const {policy, data, config, complete_payment, isLoading, Resend_Email, individual_vehiclepolicy} = usePolicy()
  const [itemOffset, setItemOffset] = useState(0);
  let itemsPerPage = 7
  const endOffset = itemOffset + itemsPerPage;
  const pageCount = Math.ceil(data.length / itemsPerPage);
  const pageCount1 = Math.ceil(filtereddata.length / itemsPerPage);
  const [info, setInfo] = useState({
    vehicle_make: '',
    vehicle_model: '',
    vehicle_class: '',
    flood_cover: '',
    vehicle_category: ''
  })
  let getinfo = cookies.get('xirhnfo')
  // console.log(quote, userid);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % data.length;
    setItemOffset(newOffset);
  };

  const handlePageClick1 = (event) => {
    const newOffset = (event.selected * itemsPerPage) % filtereddata.length;
    setItemOffset(newOffset);
  };

  const completepayment = async(data) => {
    setShow(true)
    await complete_payment(data)
  }

  const makeclaims = (data) => {
    localStorage.setItem('claimid', data)
    navigate('/make-claims')
  }

  const searchstatus = (e) => {
    let searchword = e.target.value
    const newfilter = data.filter((value) => {
        return value.policy.status.includes(searchword);
      });
      setFiltereddata(newfilter);
}

const searchcustomer = (e) => {
  let searchword = e.target.value
  // console.log(searchword);
  const newfilter = data.filter((value) => {
    let name = value.policy.enrollee.lastname + " " + value.policy.enrollee.firstname
    return name.toLowerCase().includes(searchword.toLowerCase());
    });
    setFiltereddata(newfilter);
}

// console.log(filtereddata);

const allpolicy = data.slice(itemOffset, endOffset).map((data, index) => {
  return (
    <tr key={index}>
      <td>{itemOffset + 1 + index++}</td>
      <td>{data.policy.customer.lastname + ' ' + data.policy.customer.firstname}</td>
      <td className="policy-underwriter">{data.policy.vehicle_category}</td>
      {/* <td>{data.policy_number}</td> */}
      <td className="policy-underwriter">{data.underwriter === null ? 'null' : data.underwriter.name}</td>
      <td>{data.policy.amount}</td>
      <td>{
        data.policy.policy_number === undefined ? 'Nil' : data.policy.policy_number
        }</td>
      <td>{data.policy.start}</td>
      <td>{data.policy.end}</td>
      <td className={`${data.policy.status === 'Pending' ? 'text-warning' : data.policy.status === 'Active' ? 'text-success' : data.policy.status === 'Success' ? 'text-success' : data.policy.status === 'Incomplete' ? 'text-warning' : 'text-danger'}`}>{data.policy.status}</td>
      <td>
        <Dropdown>
          <Dropdown.Toggle
            variant=""
            id="dropdown-basic"
            className="buy-policy"
          >
            Action
          </Dropdown.Toggle>
          {
            data.policy.start === 'nil' && data.policy.status === 'pending' ? 
            <Dropdown.Menu>
            <Dropdown.Item
              as='div'
              className="healthdropdown"
            >
              Contact admin
            </Dropdown.Item>
          </Dropdown.Menu> :
          data.policy.status === 'Incomplete' ? 
          <Dropdown.Menu>
            <Dropdown.Item
              as='div'
              className="healthdropdown"
              onMouseOver={() => setInfo({
                ...info,
                vehicle_make: data.policy.vehicle_make,
                vehicle_model: data.policy.vehicle_model,
                vehicle_class: data.policy.vehicle_class ,
                flood_cover: data.policy.flood_cover  
              }) }
              onClick={() => completepayment(data.user_vehicle_id)}
            >
              Complete Payment
            </Dropdown.Item>
            <Dropdown.Item
              as='div'
              className="healthdropdown"
              onClick={() => Resend_Email(data.user_vehicle_id, setEmailModal)}
            >
              Resend Payment Link
            </Dropdown.Item>
            <Dropdown.Item
              as='div'
              className="healthdropdown"
              onMouseOver={() => { setQuote({...quote, price: data.policy.amount}, setUserId(data.user_vehicle_id))}}
              onClick={() => setTransferModal(true)}
            >
              Send Payment to Paddycover
            </Dropdown.Item>
          </Dropdown.Menu> :
          data.policy.status === 'Success' ? 
          <Dropdown.Menu>
            <Dropdown.Item
              as='div'
              className="healthdropdown"
              onMouseOver={() => {setId(data.user_vehicle_id)}}
              onClick={() => {setCancelModal(true); }}
            >
              Cancel Policy
            </Dropdown.Item>
            <Dropdown.Item
              as='div'
              className="healthdropdown"
            >
              Renew Policy
            </Dropdown.Item>
            <Dropdown.Item
              as='div'
              className="healthdropdown"
              onMouseOver={() => {setId(data.user_vehicle_id)}}
              onClick={() => makeclaims(data.user_vehicle_id)}
            >
              <Link to='/make-claims' className="text-dark text-decoration-none">
                Make Claims
              </Link>
            </Dropdown.Item>
            <Dropdown.Item
              as='div'
              className="healthdropdown"
            >
              <a href={data.policy.certificate} target="_blank" rel="noreferrer" className="text-dark text-decoration-none">
                View Certtificate
              </a>
            </Dropdown.Item>
            <Dropdown.Item
              as='div'
              className="healthdropdown"
              onMouseOver={() => {setRepayment(data.repayment); setInfo({...info, vehicle_category: data.policy.vehicle_category })} }
              onClick={() => setShowlog(true)}
            >
              Repayment Logs
            </Dropdown.Item>
          </Dropdown.Menu> :
          data.policy.status === 'Active' ?
          <Dropdown.Menu>
            <Dropdown.Item
              as='div'
              className="healthdropdown"
              onClick={() => {setId(data.user_vehicle_id); setCancelModal(true); }}
            >
              Cancel Policy
            </Dropdown.Item>
            <Dropdown.Item
              as='div'
              className="healthdropdown"
            >
              Renew Policy
            </Dropdown.Item>
            <Dropdown.Item
              as='div'
              className="healthdropdown"
              onMouseOver={() => {setId(data.user_vehicle_id)}}
            >
              <Link to='/make-claims' className="text-dark text-decoration-none">
                Make Claims
              </Link>
            </Dropdown.Item>
            <Dropdown.Item
              as='div'
              className="healthdropdown"
            >
              <a href={data.policy.certificate} target="_blank" rel="noreferrer" className="text-dark text-decoration-none">
                View Certtificate
              </a>
            </Dropdown.Item>
            <Dropdown.Item
              as='div'
              className="healthdropdown"
            >
              Repayment Logs
            </Dropdown.Item>
          </Dropdown.Menu> :
          data.policy.status === 'Pending' ? 
          <Dropdown.Menu>
            <Dropdown.Item
              as='div'
              className="healthdropdown"
            >
              Contact Admin 
            </Dropdown.Item>
          </Dropdown.Menu> :
          data.policy.status === 'Cancel Initiated' ? 
          <Dropdown.Menu>
            <Dropdown.Item
              as='div'
              className="healthdropdown"
            >
              Contact admin
            </Dropdown.Item>
          </Dropdown.Menu> :
          <Dropdown.Menu>
          <Dropdown.Item
            as='div'
            className="healthdropdown"
          >
            Update Policy
          </Dropdown.Item>
        </Dropdown.Menu> 
          }
        </Dropdown>
      </td>
      <Delete
        show={modalShow}
        setModalShow={setModalShow}
        onHide={() => setModalShow(false)}
      />
    </tr>
  );
})

const filteredpolicy = filtereddata.slice(itemOffset, endOffset).map((data, index) => {
  return (
    <tr key={index}>
      <td>{itemOffset + 1 + index++}</td>
      <td>{data.policy.customer.lastname + ' ' + data.policy.customer.firstname}</td>
      <td className="policy-underwriter">{data.policy.vehicle_category}</td>
      {/* <td>{data.policy_number}</td> */}
      <td className="policy-underwriter">{data.underwriter === null ? 'null' : data.underwriter.name}</td>
      <td>{data.policy.total_amount}</td>
      <td>{
        data.policy.policy_number === undefined ? 'Nil' : data.policy.policy_number
        }</td>
      <td>{data.policy.start}</td>
      <td>{data.policy.end}</td>
      <td className={`${data.policy.status === 'Pending' ? 'text-warning' : data.policy.status === 'Active' ? 'text-success' : data.policy.status === 'Incomplete' ? 'text-warning' : 'text-danger'}`}>{data.policy.status}</td>
      <td>
        <Dropdown>
          <Dropdown.Toggle
            variant=""
            id="dropdown-basic"
            className="buy-policy"
          >
            Action
          </Dropdown.Toggle>
          {
            data.policy.start === 'nil' && data.policy.status === 'pending' ? 
            <Dropdown.Menu>
            <Dropdown.Item
              as='div'
              className="healthdropdown"
            >
              Contact admin
            </Dropdown.Item>
          </Dropdown.Menu> :
          data.policy.status === 'Incomplete' ? 
          <Dropdown.Menu>
            <Dropdown.Item
              as='div'
              className="healthdropdown"
              onMouseOver={() => setInfo({
                ...info,
                vehicle_make: data.policy.vehicle_make,
                vehicle_model: data.policy.vehicle_model,
                vehicle_class: data.policy.vehicle_class ,
                flood_cover: data.policy.flood_cover  
              }) }
              onClick={() => completepayment(data.user_vehicle_id)}
            >
              Complete Payment
            </Dropdown.Item>
            <Dropdown.Item
              as='div'
              className="healthdropdown"
              onClick={() => Resend_Email(data.user_vehicle_id, setEmailModal)}
            >
              Resend Payment Link
            </Dropdown.Item>
            <Dropdown.Item
              as='div'
              className="healthdropdown"
              onMouseOver={() => { setQuote({...quote, price: data.policy.amount}, setUserId(data.user_vehicle_id))}}
              onClick={() => setTransferModal(true)}
            >
              Send Payment to Paddycover
            </Dropdown.Item>
          </Dropdown.Menu> :
          data.policy.status === 'Success' ? 
          <Dropdown.Menu>
            <Dropdown.Item
              as='div'
              className="healthdropdown"
              onMouseOver={() => {setId(data.user_vehicle_id)}}
              onClick={() => {setCancelModal(true); }}
            >
              Cancel Policy
            </Dropdown.Item>
            <Dropdown.Item
              as='div'
              className="healthdropdown"
            >
              Renew Policy
            </Dropdown.Item>
            <Dropdown.Item
              as='div'
              className="healthdropdown"
              onMouseOver={() => {setId(data.user_vehicle_id)}}
              onClick={() => makeclaims(data.user_vehicle_id)}
            >
              <Link to='/make-claims' className="text-dark text-decoration-none">
                Make Claims
              </Link>
            </Dropdown.Item>
            <Dropdown.Item
              as='div'
              className="healthdropdown"
              onMouseOver={() => {setRepayment(data.repayment); setInfo({...info, vehicle_category: data.policy.vehicle_category })} }
              onClick={() => setShowlog(true)}
            >
              Repayment Logs
            </Dropdown.Item>
          </Dropdown.Menu> :
          data.policy.status === 'Active' ?
          <Dropdown.Menu>
            <Dropdown.Item
              as='div'
              className="healthdropdown"
              onClick={() => {setId(data.user_vehicle_id); setCancelModal(true); }}
            >
              Cancel Policy
            </Dropdown.Item>
            <Dropdown.Item
              as='div'
              className="healthdropdown"
            >
              Renew Policy
            </Dropdown.Item>
            <Dropdown.Item
              as='div'
              className="healthdropdown"
              onMouseOver={() => {setId(data.user_vehicle_id)}}
            >
              <Link to='/make-claims' className="text-dark text-decoration-none">
                Make Claims
              </Link>
            </Dropdown.Item>
            <Dropdown.Item
              as='div'
              className="healthdropdown"
            >
              Repayment Logs
            </Dropdown.Item>
          </Dropdown.Menu> :
          data.policy.status === 'Pending' ? 
          <Dropdown.Menu>
            <Dropdown.Item
              as='div'
              className="healthdropdown"
            >
              Contact Admin 
            </Dropdown.Item>
          </Dropdown.Menu> :
          data.policy.status === 'Cancel Initiated' ? 
          <Dropdown.Menu>
            <Dropdown.Item
              as='div'
              className="healthdropdown"
            >
              Contact admin
            </Dropdown.Item>
          </Dropdown.Menu> :
          <Dropdown.Menu>
          <Dropdown.Item
            as='div'
            className="healthdropdown"
          >
            Update Policy
          </Dropdown.Item>
        </Dropdown.Menu> 
          }
        </Dropdown>
      </td>
      <Delete
        show={modalShow}
        setModalShow={setModalShow}
        onHide={() => setModalShow(false)}
      />
      <TransferPayment show={transfermodal} quote={quote} userid={userid} onHide={() => setTransferModal(false)} />
    </tr>
  );
})

  useEffect(() => {
    if (getinfo !== undefined) {
      let info = JSON.parse(atob(getinfo))
      // console.log(info);
      individual_vehiclepolicy(info.agent_user_id)
  } else {
    policy()
  }
  }, [getinfo, individual_vehiclepolicy, policy])
  return (
    <div>
      <div className="navigate-back">
        <Link onClick={() => navigate(-1)}>
          <Arrow />
        </Link>
      </div>
      <div className="vehicle-policy-container">
        <h4 className="dashboard-title">Vehicle Policy</h4>
        <div>
          <div className="policy-search policy-search-group">
            <input type="text" placeholder="Search by Name" onChange={searchcustomer}/>
            <select onChange={searchstatus}>
              <option>Select Status</option>
              <option>Active</option>
              <option>Success</option>
              <option>Pending</option>
              <option>Incomplete</option>
            </select>
            <div>Filter</div>
          </div>
          <div className="policy-tab-container">
            <Table className="">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Customer</th>
                  <th className="policy-underwriter">Policy Type</th>
                  {/* <th>Policy Number</th> */}
                  <th className="policy-underwriter">Underwriters</th>
                  <th>Amount</th>
                  <th>Policy Number</th>
                  <th>Start</th>
                  <th>End</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {
                  filtereddata.length !== 0 ? filteredpolicy : allpolicy
                }
              </tbody>
      <TransferPayment show={transfermodal} quote={quote} userid={userid} check={'policy'} onHide={() => setTransferModal(false)} />
              <Repaymentlogs show={showlog} data={repayment} info={info}  onHide={() => setShowlog(false)}/>
              <PreviewBuyvehicle show={show} quote={info} config={config} onHide={() => setShow(false)}/>
            </Table>

            {
              filtereddata.length !== 0 ? 
              <ReactPaginate
              breakLabel="..."
              previousLabel={<Previcon />}
              nextLabel={<Nexticon />}
              pageCount={pageCount1}
              onPageChange={handlePageClick1}
              containerClassName={"paginatecontainer"}
              previousLinkClassName={"prevbtn"}
              pageClassName={"pageli"}
              nextLinkClassName={"nextbtn"}
              disabledClassName={"paginationdisabled"}
              activeClassName={"paginationactive"}
              breakClassName={"breakli"}
              renderOnZeroPageCount={null}
              pageRangeDisplayed={3}
              marginPagesDisplayed={2}
            /> :
            <ReactPaginate
              breakLabel="..."
              previousLabel={<Previcon />}
              nextLabel={<Nexticon />}
              pageCount={pageCount}
              onPageChange={handlePageClick}
              containerClassName={"paginatecontainer"}
              previousLinkClassName={"prevbtn"}
              pageClassName={"pageli"}
              nextLinkClassName={"nextbtn"}
              disabledClassName={"paginationdisabled"}
              activeClassName={"paginationactive"}
              breakClassName={"breakli"}
              renderOnZeroPageCount={null}
              pageRangeDisplayed={3}
              marginPagesDisplayed={2}
            />
            }
          </div>
          <Emailsent show={emailmodal} onHide={() => setEmailModal(false)} />
          <Cancel show={cancelmodal} vehicle_id={id} onHide={() => setCancelModal(false)}  />
        </div>
        {isLoading ? <Loader /> : '' }
      </div>
    </div>
  );
}

export default Vechiclepolicy;
