import React from 'react'
import { ReactComponent as Logo } from '../Assets/icons/logo.svg'
import Homeimg from '../Assets/Images/mapimg.png'
import { Link } from 'react-router-dom'

function Forgotpassword() {
  return (
    <div className='home-container'>
        <div className='logo-container'>
            <Logo />
            {/* <Link>Forgot Password</Link> */}
        </div>
        <div className='row justify-content-evenly'>
            <div className='col-lg-5 col-xl-4 image-container'>
                <div>
                    <h4>
                    Pay as you go insurance you control.
                    </h4>
                    <p>
                    You can use PaddyCover to get monthly Home Content Insurance from as low as N250 per week OR monthly Vehicle Insurance
                    </p>
                </div>
                <img src={Homeimg} alt='Map' />
            </div>
            <div className='col-lg-4 col-xl-4 pc-form-container'>
                <div className='reset-password-title'>
                    <h4>
                        Reset Password
                    </h4>
                    <p>
                        Enter the email address you registered with
                    </p>
                </div>
                <form className='reset-password-form'>
                    <div>
                        <label>
                            Email Address
                        </label>
                        <input type='email' />
                    </div>
                    <div className='forgot-password'>
                        <Link to='/'>
                            Remember password?
                        </Link>
                    </div>
                    <div className='login-button'>
                        <button>
                            Reset password
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
    )
}

export default Forgotpassword