import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useCustomer } from "../../Hooks/customer";
import Loader from "../Loader/Loader";

function Addcustomer(props) {
    const { addcustomer, isLoading, error } = useCustomer();
    const [lga, setLga] = useState([]);
    const [data, setData] = useState({
        firstname: "",
        lastname: "",
        email: "",
        phone: "",
        address: "",
        state_id: 0,
        lga_id: 0,
        date_of_birth: "",
        gender_id: ""
    });


    const adduser = async (e) => {
        e.preventDefault();
        await addcustomer(data, props.onHide);
    };

    const stateOnchange = (e) => {
        setData({ ...data, state_id: JSON.parse(e.target.value) });
        const searchObject = props.states.find(
            (state) => state.id === JSON.parse(e.target.value)
        );
        // console.log(searchObject);
        setLga(searchObject.lgas);
    };

    const ValidatePhone = (e) => {
        const inputValue = e.target.value;
        // Define a regular expression to match a valid phone number format
        const phoneRegex = /^[0-9]{0,11}$/; // Assuming a 10-digit phone number
    
        if (phoneRegex.test(inputValue)) {
          // If the input matches the regex, update the state
          setData({ ...data, [e.target.name]: inputValue })
        } 
      
      };

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Body>
                <form className="form-tab">
                    <div className="input-group">
                        <div>
                            <label>First Name</label>
                            <input
                                type="text"
                                placeholder="Tobi"
                                name="firstname"
                                onChange={(e) =>
                                    setData({ ...data, [e.target.name]: e.target.value })
                                }
                            />
                        </div>
                        <div>
                            <label>Last Name</label>
                            <input
                                type="text"
                                placeholder="Obasa"
                                name="lastname"
                                onChange={(e) =>
                                    setData({ ...data, [e.target.name]: e.target.value })
                                }
                            />
                        </div>
                    </div>
                    <div className="input-group">
                        <div>
                            <label>Email</label>
                            <input
                                type="email"
                                placeholder="email@email.com"
                                name="email"
                                onChange={(e) =>
                                    setData({ ...data, [e.target.name]: e.target.value })
                                }
                            />
                        </div>
                        <div>
                            <label>Phone Number</label>
                            <input
                                type="tel"
                                placeholder="08012345678"
                                name="phone"
                                value={data.phone || ''}
                                onChange={ValidatePhone}
                            />
                        </div>
                    </div>
                    <div className="input-group">
                        <div>
                            <label>States of residence</label>
                            <select onChange={stateOnchange}>
                                <option>Select state</option>
                                {props.states.map((state, index) => {
                                    return (
                                        <option key={index} value={state.id}>
                                            {state.name}
                                        </option>
                                    );
                                })}
                            </select>
                        </div>
                        <div>
                            <label>LGA</label>
                            <select onChange={(e) => setData({...data, lga_id: JSON.parse(e.target.value)})}>
                                <option>Select lga</option>
                                {lga.map((lga, index) => {
                                    return (
                                        <option key={index} value={lga.id}>
                                            {lga.name}
                                        </option>
                                    );
                                })}
                            </select>
                        </div>
                    </div>
                    <div className="input-group">
                        <div>
                            <label>Date of Birth</label>
                            <input type="date" onChange={(e) => setData({...data, date_of_birth: e.target.value})} />
                            {/* <select onChange={stateOnchange}>
                                <option>Select state</option>
                                {props.states.map((state, index) => {
                                    return (
                                        <option key={index} value={state.id}>
                                            {state.name}
                                        </option>
                                    );
                                })}
                            </select> */}
                        </div>
                        <div>
                            <label>Gender</label>
                            <select onChange={(e) => setData({...data, gender_id: JSON.parse(e.target.value)})}>
                                <option>Select gender</option>
                                {props.genders.map((gender, index) => {
                                    return (
                                        <option key={index} value={gender.id}>
                                            {gender.name}
                                        </option>
                                    );
                                })}
                            </select>
                        </div>
                    </div>
                    <div>
                            <label>address</label>
                            <textarea
                                placeholder="Enter address"
                                rows={3}
                                name="address"
                                onChange={(e) =>
                                    setData({ ...data, [e.target.name]: e.target.value })
                                }
                            />
                        </div>

                    <div>
                        <p>{error}</p>
                    </div>
                    <div className="customer-button">
                        <button onClick={adduser}>Add customer</button>
                    </div>
                </form>
            </Modal.Body>
            {isLoading ? <Loader /> : ""}
        </Modal>
    );
}

export default Addcustomer;
