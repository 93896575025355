import React from 'react'
import Modal from 'react-bootstrap/Modal';

function metaData(props) {
    console.log(props.data.current)

    return (
        <Modal
            {...props}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Body >
                <div className='p-3'>
                    <div className="quotaion-title w-100">
                        <h6>Policy Details</h6>
                    </div>
                    <div className="input-group underwriter-group flex-nowrap">
                        <div>
                            <label>Hospital</label>
                            <p>{props.data.current.metadata?.hospital} </p>
                        </div>
                        <div>
                            <label>Name</label>
                            <p>{props.data.current.enrollee?.name}</p>
                        </div>
                        <div>
                            <label>dob</label>
                            <p>{props.data.current.metadata?.dob}</p>
                        </div>
                        <div>
                            <label>Email</label>
                            <p>{props.data.current.enrollee?.email}</p>
                        </div>
                    </div>
                    <div className="input-group underwriter-group flex-nowrap">
                        <div>
                            <label>Address</label>
                            <p>{props.data.current.metadata?.address}</p>
                        </div>
                        <div>
                            <label>Occupation</label>
                            <p>{props.data.current.metadata?.occupation}</p>
                        </div>
                        <div>
                            <label>Country</label>
                            <p>{props.data.current.metadata?.country}</p>
                        </div>
                        <div>
                            <label>Blood group</label>
                            <p>{props.data.current.metadata?.blood_group}</p>
                        </div>
                    </div>
                    <div className="input-group underwriter-group flex-nowrap">
                        <div>
                            <label>Plan</label>
                            <p>{props.data.current.metadata?.plan}</p>
                        </div>
                        <div>
                            <label>Marital Status</label>
                            <p>{props.data.current.metadata?.marital_status}</p>
                        </div>
                        <div>
                            <label>Genotype</label>
                            <p>{props.data.current.metadata?.genotype}</p>
                        </div>
                        <div>
                            <label>Medical History</label>
                            <p>{props.data.current.metadata?.medical_history}</p>
                        </div>
                    </div>
                    <div className="input-group underwriter-group flex-nowrap">
                        <div>
                            <label>State Of Residence </label>
                            <p>{props.data.current.metadata?.state_of_residence}</p>
                        </div>
                        <div>
                            <label>Payment Frequency</label>
                            <p>{props.data.current.metadata?.payment_frequency}</p>
                        </div>
                        <div>
                            <label>Account Name</label>
                            <p>{props.data.current.metadata?.virtual_account_name}</p>
                        </div>
                        <div>
                            <label>Account Number</label>
                            <p>{props.data.current.metadata?.virtual_account_number}</p>
                        </div>
                    </div>
                    <div className="input-group underwriter-group flex-nowrap">
                        <div>
                            <label>Bank Name</label>
                            <p>{props.data.current.metadata?.virtual_bank_name}</p>
                        </div>
                        <div>
                            <label></label>
                            <p></p>
                        </div>
                        <div>
                            <label></label>
                            <p></p>
                        </div>
                        <div>
                            <label></label>
                            <p></p>
                        </div>
                    </div>

                </div>
            </Modal.Body>
        </Modal>
    )
}

export default metaData