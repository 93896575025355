import React from 'react'
import Modal from 'react-bootstrap/Modal';

function Termsmodal(props) {

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header className='terms-header' closeButton>
          <Modal.Title>PaddyCover Terms and conditions</Modal.Title>
        </Modal.Header>
            <Modal.Body>
                <div>
                    <ul className="list-disc px-4 list">
                        <li className="mt-1">The Customer shall repay the principal and interest in Twelve (12) equal monthly instalments.</li>
                        <li className="mt-1">The Customer hereby authorizes PaddyCover to debit [his/her] account for the monthly loan obligations.</li>
                        <li className="mt-1">Reserved interest rate chargeable on the Customer’s account shall be Paddy Cover’s Lender’s prime lending rate from time to time.</li>
                        <li className="mt-1">Interest will be charged on all amounts owed by Customer.</li>
                        <li className="mt-1">In the event of default by the Customer in making any repayment on due date, the insurance cover will be terminated immediately.</li>
                        <li className="mt-1">PaddyCover shall advise the Customer of any change in prime lending rate, charges, fees or any other terms and conditions of the loan by a notice at its branches in Nigeria or by notes in customer statements.</li>
                        <li className="mt-1">The Customer authorizes PaddyCover to instruct the Assurer to cancel the policy where the loan remains unpaid for a period of 7 days after due date of repayment.</li>
                        <li className="mt-1">The Customer authorizes PaddyCover to obtain and retain on the Credit Risk Management System of the Central Bank of Nigeria, all information relating to the Customer’s Bank Verification Numbers and the status of indebtedness.</li>
                        <li className="mt-1">The Customer hereby irrevocably undertakes to fully indemnify PaddyCover against all cost and expenses (including legal fees, collection commission et cetera), arising in any way in connection with the Customer’s accounts; in enforcing the terms and conditions herein; or from the recovery of any amounts due to PaddyCover or incurred by PaddyCover in any legal proceedings of whatever nature.</li>
                        <li className="mt-1">The terms and conditions contained binds the Customer and is not assignable.</li>
                        <li className="mt-1">The Customer covenants and warrants that, in making any repayment on due date in the event that the loan repayment period or day falls on a weekend or public holiday, the effective loan repayment day shall be the preceding business day.</li>
                    </ul>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default Termsmodal