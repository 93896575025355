import React, { useState } from 'react'
import Homeimg from '../Assets/Images/mapimg.png'
import { ReactComponent as Logo } from '../Assets/icons/logo.svg'
// import { Link } from 'react-router-dom'
import '../Stylesheets/home.css'
import { ReactComponent as Eye } from '../Assets/icons/eye.svg'
import { ReactComponent as Eyeslash } from '../Assets/icons/eye-slash.svg'
import { Link } from 'react-router-dom'
import { useLogin } from '../Hooks/login'
import Loader from '../Components/Loader/Loader'

function Home() {
    const [view, setView] = useState(false)
    const [data, setData] = useState({
        "email": "",
        "password": ""
    })
    const { login, isLoading, error } = useLogin()
    const passwordview = () => {
        const element = document.querySelector('#password')
        element.setAttribute("type", "text");
        setView(true)
    }

    const passwordhide = () => {
        const element = document.querySelector('#password')
        element.setAttribute("type", "password");
        setView(false)
    }

    const handleLogin = async (e) => {
        e.preventDefault()
        await login(data)
    }
    return (
        <>
            
            <div className='home-container'>
                <div className='logo-container'>
                    <Logo />
                    {/* <Link>Forgot Password</Link> */}
                </div>
                <div className='row justify-content-evenly'>
                    <div className='col-lg-6 col-xl-4 image-container'>
                        <div>
                            <h4>
                                Pay as you go insurance you control.
                            </h4>
                            <p>
                                You can use PaddyCover to get monthly Home Content Insurance from as low as N250 per week OR monthly Vehicle Insurance
                            </p>
                        </div>
                        <img src={Homeimg} alt='Map' />
                    </div>
                    <div className='col-lg-5 col-md-11 col-xl-4 pc-form-container'>
                        <div>
                            <h4>
                                Welcome back to PaddyCover, 👏🏽
                            </h4>
                            <p>
                                Log in to your dashboard to continue
                            </p>
                        </div>
                        <form>
                            <div>
                                <label>
                                    Email Address
                                </label>
                                <input type='email' onChange={(e) => setData({ ...data, "email": e.target.value })} />
                            </div>
                            <div>
                                <label>
                                    Password
                                </label>
                                <div className='login-password'>
                                    <input type='password' id='password' onChange={(e) => setData({ ...data, "password": e.target.value })} />
                                    <span onClick={passwordview} className={`${view ? 'd-none' : ''}`}><Eyeslash /></span>
                                    <span onClick={passwordhide} className={`${view ? '' : 'd-none'}`}><Eye /></span>
                                </div>
                            </div>
                            <div className='forgot-password'>
                            <p className='errormessage-ab'>{error}</p>
                                <Link to='/forgotpassword'>
                                    Forgot password?
                                </Link>
                            </div>
                            
                            <div className='login-button'>
                                <button onClick={handleLogin}>
                                    Login
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
                {isLoading ? <Loader /> : '' }
            </div>
        </>
    )
}

export default Home