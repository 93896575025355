import React, { useRef, useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import { ReactComponent as Upload } from "../../Assets/icons/upload.svg";
import { usePolicy } from '../../Hooks/policy';
import Loader from '../Loader/Loader';

function TransferPayment(props) {
    const click = useRef()
    // const [data, setData] = useState()
    const [confirm, setConfirm] = useState(false)
    const [copied, setCopied] = useState(false)
    const [cop, setCop] = useState(false)
    const [filename, setFilename] = useState('')
    const file = () => {
        click.current.click();
    };
    const {verifypaymentreciept, buyLoading} = usePolicy()

    const copy = (props) => {
        if(props === 'account') {
            let account = document.querySelector(".account").innerHTML;
            navigator.clipboard.writeText(account);
            setCopied(true)
            setCop(false)
        } else {
            let account = document.querySelector(".amount").innerHTML;
            navigator.clipboard.writeText(account);
            setCop(true)
            setCopied(false)
        }
        
        // console.log(navigator.clipboard.writeText(account));
    }

    let [data, setData] = useState({
        "user_vehicle_id" : props.userid,
        "receipt_image":""
        })

    // console.log(props.userid, data);

    function encodeImageFileAsURL(e) {
        let file = e.target.files[0];
    let reader = new FileReader();
    setFilename( file.name );
    setData({ ...data, user_vehicle_id : props.userid});
    reader.onloadend = function () {
      setData({ ...data, [e.target.name]: reader.result });
    };
    reader.readAsDataURL(file);
      }

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Body>
                <div className="quotaion-title">
                    <h6>Transfer Payment</h6>
                    {/* <p>
                        Health Cover
                    </p> */}
                </div>
                <div className={`transfer-group ${confirm ? "d-none" : ""}`}>
                    <div>
                        <label>BANK NAME</label>
                        <p>Guaranty Trust Bank(Paddy Cover Limited)</p>
                    </div>
                    <div>
                        <label>ACCOUNT NUMBER</label>
                        <div className='transfer-pair'>
                            <p className='account'>0451191565</p>
                            <span onClick={() => copy('account')}>{!copied ? 'COPY' : 'COPIED'}</span>
                        </div>
                    </div>
                    <div>
                        <label>AMOUNT</label>
                        <div className='transfer-pair'>
                            <p className='amount'>{props.quote.price}</p>
                            <span onClick={() => copy('amount')}>{!cop ? 'COPY' : 'COPIED'}</span>
                        </div>
                    </div>
                </div>
                <div className={`transfer-instruct ${!confirm ? '' : 'd-none'}`}>
                    <p>
                        <span>INSTRUCTION:</span> Click 'I HAVE MADE PAYMENT' when you have completed the transaction and upload reciept
                    </p>
                </div>
                <div className={`upload-container ${!confirm ? "d-none" : ""}`}>
                    <label>
                        Upload Reciept
                    </label>
                    <input type='file' ref={click} name='receipt_image' onChange={encodeImageFileAsURL} hidden />
                    <div className='text-center'>
                        <Upload onClick={file} />
                        <p>Upload reciept <span className='text-primary' onClick={file} >here</span></p>
                        <p>{filename}</p>
                        {/* <a href = {Example} download = "file">Download Sample of Excel File</a> */}
                    </div>
                </div>
                <div className={`next-box text-center `}>
                    <div onClick={() => {!confirm ? setConfirm(true) : verifypaymentreciept(props.userid, data, props.check)}} >{!confirm ? "I HAVE MADE PAYMENT" : "Submit"}</div>
                </div>
                <div className={`text-center ${confirm ? '' : 'd-none'}`}>
                    <p className='show-acct' onClick={() => setConfirm(false)}>
                        Show account number
                    </p>
                </div>
            </Modal.Body>
      {buyLoading ? <Loader /> : ""}
        </Modal>
    )
}

export default TransferPayment