import {useCallback, useState} from 'react'
import axios from 'axios'
import {Cookies} from 'react-cookie';
import {API} from '../Helpers/action';

export const useGadget = () => {
    const [error, setError] = useState(null)
    const [isLoading, setIsLoading] = useState(null)

    const buyGadget = useCallback(
        async (data, setEmailModal) => {
            setIsLoading(true)
            setError(null)

            const cookies = new Cookies();
            let token = cookies.get('xhrToken')

            await axios.post(`${API}/gadget/policy/create`, data,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                }).then(async (response) => {
                console.log(response.data.data);
                // setQuote(response.data.data)
                let data = {
                    "user_gadget_id": response.data.data.user_gadget_id
                }
                await axios.post(`${API}/broker/gadget/email/payment`, data,
                    {
                        headers: {
                            'Authorization': `Bearer ${token}`,
                            'Content-Type': 'application/json'
                        }
                    }).then((response) => {
                    console.log(response.data.data);
                    // setQuote(response.data.data)
                    setEmailModal(true)
                    setIsLoading(false)

                }).catch((err) => {
                    setIsLoading(false)
                    setError(err.response.data.message)
                    // console.log(err.response.data.message)
                })
                // setEmailModal(true)
                // setIsLoading(false)

            }).catch((err) => {
                setIsLoading(false)
                setError(err.response.data.message)
                // console.log(err.response.data.message)
            })
        }, []
    )

    const [policy, setPolicy] = useState([])
    const getAllPolicies = useCallback(
        async () => {
            setIsLoading(true)
            setError(null)

            const cookies = new Cookies();
            let token = cookies.get('xhrToken')

            await axios.get(`${API}/gadget/policy`,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                }).then((response) => {
                // console.log(response);
                setPolicy(response.data.data)
                setIsLoading(false)
            }).catch((err) => {
                setIsLoading(false)
                setError(err.response.data.message)
                // console.log(err.response.data.message)
            })
        }, []
    )
    return {getAllPolicies, isLoading, error, buyGadget, policy}
}
