import { useCallback, useState } from 'react'
import axios from 'axios'
import { Cookies } from 'react-cookie';
import { API } from '../Helpers/action';
import { useNavigate } from 'react-router-dom';

export const useBuyVehiclePolicy = () => {
    const [error, setError] = useState(null)
    const [isLoading, setIsLoading] = useState(null)
    const [paymode, setMode] = useState([])
    const [quote, setQuote] = useState({})
    let navigate = useNavigate()

    const thirdpary_quote = useCallback(
        async (data, setPreviewQuote) => {
            setIsLoading(true)
            setError(null)

            const cookies = new Cookies();
            let token =cookies.get('xhrToken')
    
            await axios.post(`${API}/vehicle/thirdparty/quote`, data,
            {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            } ).then((response) => {
                // console.log(response);
                setQuote(response.data.data)
                setPreviewQuote(true)
                    setIsLoading(false)
                
            }).catch((err) => {
                setIsLoading(false)
                setError(err.response.data.message)
                // console.log(err.response.data.message)
            })
        }, []
    )

    const comprehensive_quote = useCallback(
        async (data, setPreviewQuote) => {
            setIsLoading(true)
            setError(null)

            const cookies = new Cookies();
            let token =cookies.get('xhrToken')
    
            await axios.post(`${API}/vehicle/comprehensive/quote`, data,
            {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            } ).then((response) => {
                // console.log(response);
                setQuote(response.data.data)
                setPreviewQuote(true)
                    setIsLoading(false)
                
            }).catch((err) => {
                setIsLoading(false)
                setError(err.response.data.message)
                // console.log(err.response.data.message)
            })
        }, []
    )

    const verifypolicy = useCallback(
        async (data) => {
            setIsLoading(true)
            setError(null)

            let reference = {
                "reference": data
            }

            const cookies = new Cookies();
            let token =cookies.get('xhrToken')
    
            await axios.post(`${API}/vehicle/payment/verify`, reference,
            {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            } ).then((response) => {
                // console.log(response);
                navigate('/policy/vehicle')
                    setIsLoading(false)
                
            }).catch((err) => {
                setIsLoading(false)
                setError(err.response.data.message)
                // console.log(err.response.data.message)
            })
        }, [navigate]
    )

    const paymentmode = useCallback(
        async () => {
            setIsLoading(true)
            setError(null)
        

            const cookies = new Cookies();
            let token =cookies.get('xhrToken')
    
            await axios.get(`${API}/broker/payement/method`,
            {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            } ).then((response) => {
                // console.log(response.data.data);
                setMode(response.data.data)
                // navigate('/policy/vehicle')
                    setIsLoading(false)
                
            }).catch((err) => {
                setIsLoading(false)
                setError(err.response.data.message)
                // console.log(err.response.data.message)
            })
        }, []
    )

    return { thirdpary_quote, paymentmode, comprehensive_quote, quote, verifypolicy, isLoading, error, paymode}
}
