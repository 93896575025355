import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import Table from "react-bootstrap/Table";
import { ReactComponent as Nexticon } from "../../Assets/icons/nextarrw.svg";
import { ReactComponent as Previcon } from "../../Assets/icons/prevarrw.svg";
import ReactPaginate from 'react-paginate';

function Repaymentlogs(props) {
    const [itemOffset, setItemOffset] = useState(0);
    let itemsPerPage = 7
    const endOffset = itemOffset + itemsPerPage;
    const pageCount = Math.ceil(props.data.length / itemsPerPage);

    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % props.data.length;

        setItemOffset(newOffset);
    };

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Body>
                <div className="policy-tab-container">
                    <Table className="">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Payment period</th>
                                <th className="policy-underwriter">Policy Title</th>
                                <th className="policy-underwriter">Amount</th>
                                <th>Status</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {props.data.slice(itemOffset, endOffset).map((data, index) => {
                                return (
                                    <tr key={index}>
                                        <td>{itemOffset + 1 + index++}</td>
                                        <td>{data.end}</td>
                                        <td className="policy-underwriter">{props.info.vehicle_category}</td>
                                        <td className="policy-underwriter">{data.amount}</td>
                                        <td>{data.status}</td>
                                        <td>

                                        </td>

                                    </tr>
                                );
                            })}
                        </tbody>
                    </Table>

                    <ReactPaginate
                        breakLabel="..."
                        previousLabel={<Previcon />}
                        nextLabel={<Nexticon />}
                        pageCount={pageCount}
                        onPageChange={handlePageClick}
                        containerClassName={"paginatecontainer"}
                        previousLinkClassName={"prevbtn"}
                        pageClassName={"pageli"}
                        nextLinkClassName={"nextbtn"}
                        disabledClassName={"paginationdisabled"}
                        activeClassName={"paginationactive"}
                        breakClassName={"breakli"}
                        renderOnZeroPageCount={null}
                        pageRangeDisplayed={3}
                        marginPagesDisplayed={2}
                    />
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default Repaymentlogs