import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal';
// import { usePolicy } from '../Hooks/policy';
// import Loader from './Loader';

function VirtualModal(props) {
    console.log(props.virtualdetails.current)
    const [copied, setCopied] = useState(false)
    const [cop, setCop] = useState(false)


    const copy = (props) => {
        if(props === 'account') {
            let account = document.querySelector(".account").innerHTML;
            navigator.clipboard.writeText(account);
            setCopied(true)
            setCop(false)
        } else {
            let account = document.querySelector(".amount").innerHTML;
            navigator.clipboard.writeText(account);
            setCop(true)
            setCopied(false)
        }

        // console.log(navigator.clipboard.writeText(account));
    }

    // let [data, setData] = useState({
    //     "user_vehicle_id" : props.userid,
    //     "receipt_image":""
    // })

    //

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Body>
                <div className="quotaion-title">
                    <h6>Transfer Payment</h6>
                    {/* <p>
                        Health Cover
                    </p> */}
                </div>
                <div className={`transfer-group `}>
                    <div>
                        <label>BANK NAME</label>
                        <p>{props.virtualdetails.current?.virtual_account_details?.bank_name}</p>
                    </div>
                    <div>
                        <label>ACCOUNT NUMBER</label>
                        <div className='transfer-pair'>
                            <p className='account'>{props.virtualdetails.current?.virtual_account_details?.account_number}</p>
                            <span onClick={() => copy('account')}>{!copied ? 'COPY' : 'COPIED'}</span>
                        </div>
                    </div>
                    <div>
                        <label>AMOUNT</label>
                        <div className='transfer-pair'>
                            <p className='amount'>₦{props.virtualdetails.current?.total_quote/ 100}</p>
                            <span onClick={() => copy('amount')}>{!cop ? 'COPY' : 'COPIED'}</span>
                        </div>
                    </div>
                </div>
                <div className={`transfer-instruct`}>
                    <p>
                        <span>INSTRUCTION:</span> A policy can only be active when payment have been made
                    </p>
                </div>
                <div className={`next-box text-center `}>
                    <div onClick={() => props.onHide()} >Close</div>
                </div>

            </Modal.Body>
            {/*{buyLoading ? <Loader /> : ""}*/}
        </Modal>
    )
}

export default VirtualModal