import React, { createContext, useState, useContext } from 'react';
import Toast from "../Components/Notification/Notification";

const ToastContext = createContext();

export const useToast = () => {
    const context = useContext(ToastContext);
    if (!context) {
        throw new Error('useToast must be used within a ToastProvider');
    }
    return context;
};

export const ToastProvider = ({ children }) => {
    const [toast, setToast] = useState(null);

    const showToast = (title, message, type) => {
        console.log(title, message, type)
        setToast({ title, message, type });
        setTimeout(() => {
            setToast(null);
        }, 2500); // Hide toast after 5 seconds
    };

    return (
        <ToastContext.Provider value={{ showToast }}>
            {children}
            {toast && <Toast title={toast.title} message={toast.message} type={toast.type} />}
        </ToastContext.Provider>
    );
};
