import React, { useRef, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Loader from "../Loader/Loader";
import { useUnderwriter } from "../../Hooks/underwriter";
import { usePolicy } from "../../Hooks/policy";

function UpdateHealth(props) {
    const {
        hmo_lga,
        lga,
        hmo_hospital,
        error,
        hospital,
        hmo_residence_lga,
        residencelga,
    } = useUnderwriter();
    const { isLoading, err, update_hmo_policy } = usePolicy();
    const click = useRef("");
    const [file_name, setFilename] = useState("");
    // const [residence, setResidence] = useState([])
    const [updatedata, setUpdateData] = useState({
        underwriter_id: 1,
        passport: "",
        state_of_residence_id: "",
        state_of_origin_id: "",
    });

    function formatDate(inputDate) {
        const date = new Date(inputDate);

        const day = date.getDate().toString().padStart(2, "0"); // Get day and add leading zero if needed
        const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Get month (add 1 as months are 0-based) and add leading zero if needed
        const year = date.getFullYear();

        return `${day}/${month}/${year}`;
    }
    console.log(updatedata, props.customerinfo);
    const getstatelga = async (e) => {
        setUpdateData({
            ...updatedata,
            [e.target.name]: JSON.parse(e.target.value),
        });
        let StateID = e.target.value;
        await hmo_lga(StateID);
    };

    const getresidencelga = async (e) => {
        setUpdateData({
            ...updatedata,
            [e.target.name]: JSON.parse(e.target.value),
        });
        let StateID = e.target.value;
        await hmo_residence_lga(StateID);
    };

    const gethospitalbylga = async (e) => {
        // let dataset = e.target.options[e.target.selectedIndex].dataset;
        let data = {
            PlanId: props.healthData.PlanId,
            StateId: `${updatedata.state_of_residence_id}`,
            LgaId: e.target.value,
        };
        setUpdateData({
            ...updatedata,
            [e.target.name]: JSON.parse(e.target.value),
        });
        await hmo_hospital(data);
    };

    const updatesubmit = async () => {
        let formData = new FormData();
        formData.append("passport", updatedata.passport);
        formData.append("underwriter_id", updatedata.underwriter_id);
        formData.append("hospital_id", updatedata.hospital_id);
        formData.append("state_of_residence_id", updatedata.state_of_residence_id);
        formData.append("state_of_origin_id", updatedata.state_of_origin_id);
        formData.append("lga_origin_id", updatedata.lga_origin_id);
        formData.append("lga_id", updatedata.lga_id);
        formData.append("dob", updatedata.dob);
        formData.append("user_hmo_id", props.customerinfo.user_health_id);

        await update_hmo_policy(formData);
        props.onHide();
        props.update();
        setFilename("");
    };

    const file = () => {
        click.current.click();
    };

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Body>
                <form className="form-tab">
                    <h5>
                        Update{" "}
                        {props.customerinfo?.othername +
                            " " +
                            props.customerinfo?.firstname}{" "}
                        Data
                    </h5>
                    <div className="input-group">
                        <div className="w-100">
                            <label>Date Of Birth</label>
                            <input
                                type="date"
                                name="dob"
                                onChange={(e) =>
                                    setUpdateData({
                                        ...updatedata,
                                        [e.target.name]: formatDate(e.target.value),
                                    })
                                }
                            />
                        </div>
                    </div>
                    <div className="input-group">
                        <div>
                            <label>State of Origin </label>
                            <select name="state_of_origin_id" onChange={getstatelga}>
                                <option defaultValue="" disabled selected>
                                    Select state of origin
                                </option>
                                {props.state.map((data, index) => {
                                    return (
                                        <option key={index} value={data.StateID}>
                                            {data.Name}
                                        </option>
                                    );
                                })}
                            </select>
                        </div>
                        <div>
                            <label>Lga of origin</label>
                            <select
                                name="lga_origin_id"
                                onChange={(e) =>
                                    setUpdateData({
                                        ...updatedata,
                                        [e.target.name]: JSON.parse(e.target.value),
                                    })
                                }
                                disabled={updatedata.state_of_origin_id === ""}
                            >
                                <option>Select lga of origin</option>
                                {lga.map((data, index) => {
                                    return (
                                        <option key={index} value={data.LgaID}>
                                            {data.Name}
                                        </option>
                                    );
                                })}
                            </select>
                        </div>
                    </div>
                    <div className="input-group">
                        <div>
                            <label>State of Residence </label>
                            <select name="state_of_residence_id" onChange={getresidencelga}>
                                <option defaultValue="" disabled selected>
                                    Select state of residence
                                </option>
                                {props.state.map((data, index) => {
                                    return (
                                        <option key={index} value={data.StateID}>
                                            {data.Name}
                                        </option>
                                    );
                                })}
                            </select>
                        </div>
                        <div>
                            <label>Lga of Residence</label>
                            <select
                                name="lga_id"
                                onChange={gethospitalbylga}
                                disabled={updatedata.state_of_residence_id === ""}
                            >
                                <option>Select lga of residence</option>
                                {residencelga.map((data, index) => {
                                    return (
                                        <option key={index} value={data.LgaID}>
                                            {data.Name}
                                        </option>
                                    );
                                })}
                            </select>
                        </div>
                    </div>
                    <div className="input-group">
                        <div>
                            <label>Choose preferred hospital</label>
                            <select
                                name="hospital_id"
                                onChange={(e) =>
                                    setUpdateData({
                                        ...updatedata,
                                        [e.target.name]: e.target.value,
                                    })
                                }
                            >
                                <option defaultValue="" disabled selected>
                                    Select hospital
                                </option>
                                {hospital.map((data, index) => {
                                    return (
                                        <option key={index} value={data.ID}>
                                            {data.HospitalName}
                                        </option>
                                    );
                                })}
                            </select>
                            <p className="text-danger">{error}</p>
                        </div>
                        <div>
                            <label>Passport</label>
                            <div>
                                <input
                                    type="file"
                                    name="passport"
                                    ref={click}
                                    onChange={(e) => {
                                        setUpdateData({
                                            ...updatedata,
                                            [e.target.name]: e.target.files[0],
                                        });
                                        setFilename(e.target.files[0].name);
                                    }}
                                    hidden
                                />
                                <span className="fileinput" onClick={file}>
                                    Choose File
                                </span>
                                <span className="filename">{file_name}</span>
                            </div>
                        </div>
                    </div>
                    <p>{err}</p>
                    <div className="customer-button">
                        <button type="button" onClick={updatesubmit}>
                            Submit
                        </button>
                    </div>
                </form>
            </Modal.Body>
            {isLoading ? <Loader /> : ""}
        </Modal>
    );
}

export default UpdateHealth;
