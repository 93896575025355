import React, {useCallback, useEffect, useRef, useState} from "react";
import { ReactComponent as Arrow } from "../Assets/icons/back-arrow.svg";
import { Link, useNavigate } from "react-router-dom";
import { useCustomer } from "../Hooks/customer";
import Table from "react-bootstrap/Table";
import { useUnderwriter } from "../Hooks/underwriter";
import { usePolicy } from "../Hooks/policy";
import PreviewBuyHealth from "../Components/Health/Preview-buy-health";
import Loader from "../Components/Loader/Loader";
import Dropdown from "react-bootstrap/Dropdown";
import { ReactComponent as Nexticon } from "../Assets/icons/nextarrw.svg";
import { ReactComponent as Previcon } from "../Assets/icons/prevarrw.svg";
import ReactPaginate from "react-paginate";
import { Cookies } from "react-cookie";
// import Health from './Health';
import Emailsent from "../Components/modal/Email-modal";
import VirtualModal from "../Components/modal/VirtualModal";
import TableLoader from "../Components/Loader/TableLoader";
import ButttonLoader from "../Components/Loader/ButttonLoader";
import {SearchNormal1} from "iconsax-react";

function Buyhealth() {
    const navigate = useNavigate();
    const [modal, setModal] = useState(false);
    const click = useRef("");
    const cookies = new Cookies();
    const [emailmodal, setEmailModal] = useState(false);
    const [mode, setMode] = useState("");
    const [page, setPage] = useState("");
    const { customer, data, isLoading: customerLoading } = useCustomer();
    const { buy_health_policy, healthconfig, isLoading, err, virtualInfo } = usePolicy();
    const {
        getunderwriter,
        underwriters,
        health_resourses,
        gender,
        healthplan,
        hmo_plan,
        hmo_states,
        state,
        hmo_lga,
        lga,
        hmo_hospital,
        hospital,
        hmo_residence_lga,
        residencelga,
        error
        // health_hospital
    } = useUnderwriter();
    const [customerinfo, setCustomerInfo] = useState({
        firstname: "",
        lastname: "",
        address: "",
        phone: "",
        email: "",
        state: "",
        preferredhospital: "",
        id: ''
    });
    const [healthdata, setHealthData] = useState({
        user_id: "",
        enrollee_id: "",
        underwriter_id: "",
        title: "",
        passport: "",
        country: "",
        plan_id: "",
        email: "",
        phone: "",
        dob: "",
        gender: "",
        marital_status: "",
        occupation: "",
        religion: "",
        blood_group: "",
        genotype: "",
        company: "",
        nationality: "",
        state_of_origin: "",
        lga_of_origin: "",
        payment_frequency: "",
        state_of_residence: "",
        lga_of_residence: "",
        address: "",
        medical_history: "",
        hospital_id: "",
    });
    let userid = JSON.parse(atob(cookies.get("xhrUserxx")));

    const [filename, setFilename] = useState("");

    let getinfo = cookies.get("xirhnfo");
    const provider = async (e) => {
        if (getinfo !== undefined) {
            let info = JSON.parse(atob(getinfo));
            setCustomerInfo({
                ...customerinfo,
                firstname: info.firstname,
                lastname: info.lastname,
                email: info.email,
                phone: info.phone,
                address: info.address,
            });
            setHealthData({
                ...healthdata,
                [e.target.name]: JSON.parse(e.target.value),
                enrollee_id: info.agent_user_id,
                firstname: info.firstname,
                surname: info.lastname,
                email: info.email,
                phone: info.phone,
                user_id: userid.userid,
                address: info.address,
            });
        } else {
            setHealthData({
                ...healthdata,
                [e.target.name]: JSON.parse(e.target.value),
                firstname: customerinfo.firstname,
                surname: customerinfo.lastname,
                email: customerinfo.email,
                phone: customerinfo.phone,
                address: customerinfo.address,
            });
        }
        if (e.target.value === "1") {
            await hmo_plan();
            await health_resourses();
            await hmo_states();
        }
    };

    const hideemail = () => {
        setEmailModal(false);
        setShowModal(false)
        navigate("/policy/health/all");
    };

    const dateformat = (date) => {
        var tempDate = new Date(date);
        let day = tempDate.getDate() < 10 ? '0' + tempDate.getDate() : tempDate.getDate()
        let mnth = tempDate.getMonth() + 1
        let month = tempDate.getMonth() + 1 < 10 ? '0' + mnth : tempDate.getMonth() + 1
        var formattedDate = [day, month, tempDate.getFullYear()].join('/');
        return formattedDate
    }

    const plan = (e) => {
        let dataset = e.target.options[e.target.selectedIndex].dataset;
        setHealthData({
            ...healthdata,
            [e.target.name]: e.target.value,
        })
        setCustomerInfo({ ...customerinfo, id: dataset.id })
    }

    const gethospitalbylga = async (e) => {
        let dataset = e.target.options[e.target.selectedIndex].dataset;
        let data = {
            PlanId: customerinfo.id,
            StateId: `${healthdata.state_of_residence}`,
            LgaId: dataset.id,
        };
        setHealthData({
            ...healthdata,
            [e.target.name]: JSON.parse(e.target.value),
        });

        await hmo_hospital(data);
    };

    const sethospital = (e) => {
        let dataset = e.target.options[e.target.selectedIndex].dataset;
        setHealthData({
            ...healthdata,
            [e.target.name]: e.target.value,
        });
        setCustomerInfo({ ...customerinfo, preferredhospital: dataset.name });
    };

    const file = () => {
        click.current.click();
    };

    function encodeImageFileAsURL(e) {
        let file = e.target.files[0];
        let reader = new FileReader();
        setFilename(file.name);
        reader.onloadend = function () {
            setHealthData({ ...healthdata, [e.target.name]: reader.result });
        };
        reader.readAsDataURL(file);
    }

    const buypolicy = async () => {
        if(mode === 'pat') {
           let data=  await buy_health_policy(healthdata, setModal, mode, setEmailModal, setShowModal);
            console.log('pat', data, virtualInfo)
            // setShowModal(true)
        }else {
            await buy_health_policy(healthdata, setModal, mode, setEmailModal);

        }
    };

    const getlga = async (e) => {
        setHealthData({
            ...healthdata,
            [e.target.name]: JSON.parse(e.target.value),
        });
        let StateID = e.target.value;
        await hmo_lga(StateID);
    };

    const getresidencelga = async (e) => {
        let dataset = e.target.options[e.target.selectedIndex].dataset;
        setHealthData({
            ...healthdata,
            [e.target.name]: JSON.parse(e.target.value),
        });
        setCustomerInfo({ ...customerinfo, state: dataset.name })
        let StateID = e.target.value;
        await hmo_residence_lga(StateID);
    };

    const [itemOffset, setItemOffset] = useState(0);
    let itemsPerPage = 20;
    const endOffset = itemOffset + itemsPerPage;
    const pageCount = Math.ceil(data.length / itemsPerPage);

    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % data.length;

        setItemOffset(newOffset);
    };
    const [showModal, setShowModal] = useState(false)
    const [filteredItemOffset, setFilteredItemOffset] = useState(0);
    const {searchCustomer, data: searchedData, isLoading: loader} = useCustomer()
    const [searchInput, setSearchInput] = useState('')
    const search = useCallback(async () => {
        try {
            await searchCustomer(searchInput);
        } catch (err) {
            console.error(err);
        }
    }, [searchCustomer, searchInput]);
    // console.log(searchedData);

    const filteredEndOffset = filteredItemOffset + itemsPerPage;
    const filteredPageCount = Math.ceil(searchedData.length / itemsPerPage);
    const handleFilteredPageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % searchedData.length;

        setFilteredItemOffset(newOffset);
    };

    const normalPost = data.slice(itemOffset, endOffset).map((data, index) => {
        return (
            <tr key={index}>
                <td>{itemOffset + 1 + index++}</td>
                <td>{data.lastname + " " + data.firstname}</td>
                <td>{data.email}</td>
                <td colSpan={2}>{data.address}</td>
                <td>{data.phone}</td>
                <td>
                    <Dropdown>
                        <Dropdown.Toggle
                            variant=""
                            id="dropdown-basic"
                            className="buy-policy buy-policy-but"
                            onMouseOver={() => {
                                setCustomerInfo({
                                    ...customerinfo,
                                    firstname: data.firstname,
                                    lastname: data.lastname,
                                    email: data.email,
                                    phone: data.phone,
                                    enrollee_id: data.id,
                                    address: data.address,
                                });
                                setHealthData({
                                    ...healthdata,
                                    enrollee_id: data.id,
                                    user_id: userid.userid,
                                });
                            }}
                            onClick={() => setPage("startform")}
                        >
                            Buy
                        </Dropdown.Toggle>
                    </Dropdown>
                </td>
            </tr>
        );
    })
    const filteredPost = searchedData.slice(filteredItemOffset, filteredEndOffset).map((data, index) => {
        return (
            <tr key={index}>
                <td>{itemOffset + 1 + index++}</td>
                <td>{data.lastname + " " + data.firstname}</td>
                <td>{data.email}</td>
                <td colSpan={2}>{data.address}</td>
                <td>{data.phone}</td>
                <td>
                    <Dropdown>
                        <Dropdown.Toggle
                            variant=""
                            id="dropdown-basic"
                            className="buy-policy buy-policy-but"
                            onMouseOver={() => {
                                setCustomerInfo({
                                    ...customerinfo,
                                    firstname: data.firstname,
                                    lastname: data.lastname,
                                    email: data.email,
                                    phone: data.phone,
                                    enrollee_id: data.id,
                                    address: data.address,
                                });
                                setHealthData({
                                    ...healthdata,
                                    enrollee_id: data.id,
                                    user_id: userid.userid,
                                });
                            }}
                            onClick={() => setPage("startform")}
                        >
                            Buy
                        </Dropdown.Toggle>
                    </Dropdown>
                </td>
            </tr>
        );
    })

    useEffect(() => {
        if (getinfo !== undefined) {
            setPage("startform");
        }
        customer();
        getunderwriter();
    }, [getinfo, customer, getunderwriter]);
    return (
        <div>
            <div className="navigate-back">
                <Link onClick={() => navigate(-1)}>
                    <Arrow />
                </Link>
            </div>
            <div className="vehicle-policy-container">
                <h4 className="dashboard-title">Buy Health Policy</h4>
                <div className="claims-table-container buy-vehicle-box">
                    <form className="health-form buy-form" autoComplete="off">
                        <div
                            className={`${getinfo !== undefined ? "d-none" : page !== "" ? "d-none" : ""
                                }`}
                        >
                            <div className="lists-cust">
                                <div className={`row justify-content-between align-items-center  py-3`}>
                                    <h5 className={`col-2`}>Customer</h5>
                                    <div className={`col-4 justify-content-end`}>
                                        <div className={`d-flex`}>
                                            <input placeholder='search customer'
                                                   onChange={(e) => setSearchInput(e.target.value)}
                                                   className={`focus-visible:outline-none searchinputCustomer rounded-start`}/>
                                            <button type='button' className='p-2 border-0 buy-policy rounded-end d-flex'
                                                    onClick={() => loader ? '' : search()}>
                                                {
                                                    loader ? <ButttonLoader/> : <SearchNormal1 size="24" color="white"/>
                                                }
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <Table className="">
                                    <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Name</th>
                                        <th>Email</th>
                                        <th colSpan={2}>Address</th>
                                        <th>Phone Number</th>
                                        <th></th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {loader || customerLoading ?
                                        <tr>
                                            <td className='border-0' colSpan={6} rowSpan={6}>
                                                <div className='d-flex justify-content-center py-5'>
                                                    <TableLoader/>
                                                </div>
                                            </td>
                                        </tr> :
                                        searchedData.length !== 0 ? filteredPost : normalPost}

                                    </tbody>
                                </Table>
                                {loader || customerLoading ? <div></div> : searchedData.length !== 0 ?
                                    <ReactPaginate
                                        breakLabel="..."
                                        previousLabel={<Previcon/>}
                                        nextLabel={<Nexticon/>}
                                        pageCount={filteredPageCount}
                                        onPageChange={handleFilteredPageClick}
                                        containerClassName={"paginatecontainer"}
                                        previousLinkClassName={"prevbtn"}
                                        pageClassName={"pageli"}
                                        nextLinkClassName={"nextbtn"}
                                        disabledClassName={"paginationdisabled"}
                                        activeClassName={"paginationactive"}
                                        breakClassName={"breakli"}
                                        renderOnZeroPageCount={null}
                                        pageRangeDisplayed={3}
                                        marginPagesDisplayed={2}
                                    />
                                    :
                                    <ReactPaginate
                                        breakLabel="..."
                                        previousLabel={<Previcon/>}
                                        nextLabel={<Nexticon/>}
                                        pageCount={pageCount}
                                        onPageChange={handlePageClick}
                                        containerClassName={"paginatecontainer"}
                                        previousLinkClassName={"prevbtn"}
                                        pageClassName={"pageli"}
                                        nextLinkClassName={"nextbtn"}
                                        disabledClassName={"paginationdisabled"}
                                        activeClassName={"paginationactive"}
                                        breakClassName={"breakli"}
                                        renderOnZeroPageCount={null}
                                        pageRangeDisplayed={3}
                                        marginPagesDisplayed={2}
                                    />
                                }
                            </div>
                        </div>
                        <div className={`${page === "startform" ? "" : "d-none"}`}>
                            <div className="input-group underwriter-group">
                                <div>
                                    <label>Choose your preferred Insurance provider?</label>
                                    <select
                                        name="underwriter_id"
                                        defaultValue="Select Customer"
                                        onChange={provider}
                                    >
                                        <option defaultValue="" disabled selected>
                                            Select Provider
                                        </option>
                                        {underwriters.slice(0, 1).map((data, index) => {
                                            return (
                                                <option key={index} value={data.id}>
                                                    {data.name}
                                                </option>
                                            );
                                        })}
                                    </select>
                                </div>
                                <div>
                                    <label>Available Plans </label>
                                    <select
                                        name="plan_id"
                                        onChange={plan}
                                    >
                                        <option defaultValue="" disabled selected>
                                            Select Provider
                                        </option>
                                        {healthplan.map((data, index) => {
                                            return (
                                                <option key={index} value={data.ID} data-id={data.PlanId}>
                                                    {data.Name} {data.MonthlyCost}/{data.AnnualCost}
                                                </option>
                                            );
                                        })}
                                    </select>
                                </div>
                                <div>
                                    <label>Title</label>
                                    <select
                                        name="title"
                                        onChange={(e) =>
                                            setHealthData({
                                                ...healthdata,
                                                [e.target.name]: e.target.value,
                                            })
                                        }
                                    >
                                        <option defaultValue="" disabled selected>
                                            Select Title
                                        </option>
                                        <option>Master</option>
                                        <option>Miss</option>
                                        <option>Chief</option>
                                        <option>Dr.</option>
                                        <option>Mr</option>
                                        <option>Mrs</option>
                                    </select>
                                </div>
                            </div>
                            <div className="input-group underwriter-group">
                                <div>
                                    <label>Surname</label>
                                    <input type="text" value={customerinfo.lastname} readOnly />
                                </div>
                                <div>
                                    <label>First Name</label>
                                    <input type="text" value={customerinfo.firstname} readOnly />
                                </div>
                                <div>
                                    <label>Email</label>
                                    <input type="text" value={customerinfo.email} readOnly />
                                </div>
                            </div>
                            <div className="input-group underwriter-group">
                                <div>
                                    <label>Phone</label>
                                    <input type="text" value={customerinfo.phone} readOnly />
                                </div>
                                <div>
                                    <label>Address</label>
                                    <input type="text" value={customerinfo.address} readOnly />
                                </div>
                                <div>
                                    <label>Gender</label>
                                    <select
                                        name="gender"
                                        onChange={(e) =>
                                            setHealthData({
                                                ...healthdata,
                                                [e.target.name]: JSON.parse(e.target.value),
                                            })
                                        }
                                    >
                                        <option>Select Gender</option>
                                        {gender.map((data, index) => {
                                            return (
                                                <option key={index} value={data.id}>
                                                    {data.name}
                                                </option>
                                            );
                                        })}
                                    </select>
                                </div>
                            </div>
                            <div className="input-group underwriter-group">
                                <div>
                                    <label>Date of Birth</label>
                                    <input
                                        type="date"
                                        name="dob"
                                        onChange={(e) =>
                                            setHealthData({
                                                ...healthdata,
                                                [e.target.name]: dateformat(e.target.value),
                                            })
                                        }
                                    />
                                </div>
                                <div>
                                    <label>Blood Group</label>
                                    <select
                                        name="blood_group"
                                        onChange={(e) =>
                                            setHealthData({
                                                ...healthdata,
                                                [e.target.name]: e.target.value,
                                            })
                                        }
                                    >
                                        <option defaultValue="" selected disabled>
                                            Select Blood Group
                                        </option>
                                        <option>ABO</option>
                                        <option>AB+</option>
                                        <option>A</option>
                                        <option>O-</option>
                                        <option>B+</option>
                                        <option>AB-</option>
                                        <option>O</option>
                                        <option>O+</option>
                                        <option>AB</option>
                                    </select>
                                </div>
                                <div>
                                    <label>Genotype</label>
                                    <select
                                        name="genotype"
                                        onChange={(e) =>
                                            setHealthData({
                                                ...healthdata,
                                                [e.target.name]: e.target.value,
                                            })
                                        }
                                    >
                                        <option defaultValue="" selected disabled>
                                            Select Genotype
                                        </option>
                                        <option>AA</option>
                                        <option>AS</option>
                                        <option>AC</option>
                                        <option>SC</option>
                                        <option>B+</option>
                                        <option>SS</option>
                                    </select>
                                </div>
                            </div>
                            <div className="input-group underwriter-group">
                                <div>
                                    <label>Marital Status</label>
                                    <select
                                        name="marital_status"
                                        onChange={(e) =>
                                            setHealthData({
                                                ...healthdata,
                                                [e.target.name]: e.target.value,
                                            })
                                        }
                                    >
                                        <option defaultValue="" selected disabled>
                                            Select State
                                        </option>
                                        <option>Single</option>
                                        <option>Married</option>
                                        <option>Divorced</option>
                                        <option>widow</option>
                                    </select>
                                </div>
                                <div>
                                    <label>Religion</label>
                                    <input
                                        type="text"
                                        name="religion"
                                        onChange={(e) =>
                                            setHealthData({
                                                ...healthdata,
                                                [e.target.name]: e.target.value,
                                            })
                                        }
                                    />
                                </div>
                                <div>
                                    <label>Occupation</label>
                                    <input
                                        type="text"
                                        name="occupation"
                                        onChange={(e) =>
                                            setHealthData({
                                                ...healthdata,
                                                [e.target.name]: e.target.value,
                                            })
                                        }
                                    />
                                </div>
                            </div>
                            <div className="input-group underwriter-group">
                                <div>
                                    <label>Country</label>
                                    <input
                                        type="text"
                                        name="country"
                                        onChange={(e) =>
                                            setHealthData({
                                                ...healthdata,
                                                [e.target.name]: e.target.value,
                                            })
                                        }
                                    />
                                </div>
                                <div>
                                    <label>Nationality</label>
                                    <input
                                        type="text"
                                        name="nationality"
                                        onChange={(e) =>
                                            setHealthData({
                                                ...healthdata,
                                                [e.target.name]: e.target.value,
                                            })
                                        }
                                    />
                                </div>
                                <div>
                                    <label>State of origin</label>
                                    <select name="state_of_origin" onChange={getlga}>
                                        <option>Select State</option>
                                        {state.map((data, index) => {
                                            return (
                                                <option key={index} value={data.StateID}>
                                                    {data.Name}
                                                </option>
                                            );
                                        })}
                                    </select>
                                </div>
                            </div>

                            <div className="input-group underwriter-group">
                                <div>
                                    <label>LGA of Orgin</label>
                                    <select
                                        name="lga_of_origin"
                                        onChange={(e) =>
                                            setHealthData({
                                                ...healthdata,
                                                [e.target.name]: JSON.parse(e.target.value),
                                            })
                                        }
                                    >
                                        <option>Select LGA</option>
                                        {lga.map((data, index) => {
                                            return (
                                                <option
                                                    key={index}
                                                    value={data.LgaID}
                                                    data-id={data.LgaID}
                                                >
                                                    {data.Name}
                                                </option>
                                            );
                                        })}
                                    </select>
                                </div>
                                <div>
                                    <label>State of Residence</label>
                                    <select name="state_of_residence" onChange={getresidencelga}>
                                        <option>Select State</option>
                                        {state.map((data, index) => {
                                            return (
                                                <option
                                                    key={index}
                                                    value={data.StateID}
                                                    data-name={data.Name}
                                                >
                                                    {data.Name}
                                                </option>
                                            );
                                        })}
                                    </select>
                                </div>
                                <div>
                                    <label>LGA of Residence</label>
                                    <select name="lga_of_residence" onChange={gethospitalbylga}>
                                        <option>Select LGA</option>
                                        {residencelga.map((data, index) => {
                                            return (
                                                <option
                                                    key={index}
                                                    value={data.LgaID}
                                                    data-id={data.LgaID}
                                                >
                                                    {data.Name}
                                                </option>
                                            );
                                        })}
                                    </select>
                                </div>
                            </div>

                            <div className="input-group underwriter-group">
                                <div>
                                    <label>Company Name</label>
                                    <input
                                        type="text"
                                        name="company"
                                        onChange={(e) =>
                                            setHealthData({
                                                ...healthdata,
                                                [e.target.name]: e.target.value,
                                            })
                                        }
                                    />
                                </div>
                                <div>
                                    <label>Choose preferred hospital</label>
                                    <select name="hospital_id" onChange={sethospital}>
                                        <option>Select Hospital</option>
                                        {hospital.map((data, index) => {
                                            return (
                                                <option
                                                    key={index}
                                                    value={data.ID}
                                                    data-name={data.HospitalName}
                                                >
                                                    {data.HospitalName}
                                                </option>
                                            );
                                        })}
                                    </select>
                                    <p className="text-danger">
                                        <em>
                                            {error === "unable to get hospitals" ? "No available hospital" : error}
                                        </em>
                                    </p>
                                </div>
                                <div>
                                    <label>Payment frequency</label>
                                    <select
                                        name="payment_frequency"
                                        onChange={(e) =>
                                            setHealthData({
                                                ...healthdata,
                                                [e.target.name]: JSON.parse(e.target.value),
                                            })
                                        }
                                    >
                                        <option>Select Payment frequency</option>
                                        <option value="1">1 Month</option>
                                        <option value="2">2 Months</option>
                                        <option value="3">3 Months</option>
                                        <option value="4">4 Months</option>
                                        <option value="5">5 Months</option>
                                        <option value="6">6 Months</option>
                                        <option value="7">7 Months</option>
                                        <option value="8">8 Months</option>
                                        <option value="9">9 Months</option>
                                        <option value="10">10 Months</option>
                                        <option value="11">11 Months</option>
                                        <option value="12">12 Months</option>

                                    </select>
                                </div>
                            </div>

                            <div className="input-group underwriter-group">
                                <div>
                                    <label>Pre-Exisiting Medical History</label>
                                    <textarea
                                        rows={1}
                                        name="medical_history"
                                        onChange={(e) =>
                                            setHealthData({
                                                ...healthdata,
                                                [e.target.name]: e.target.value,
                                            })
                                        }
                                    />
                                </div>
                                <div>
                                    <label className='pb-3'>Upload Passport</label>
                                    <input
                                        type="file"
                                        name="passport"
                                        ref={click}
                                        onChange={encodeImageFileAsURL}
                                        hidden
                                    />
                                    <span className="fileinput" onClick={file}>
                                        Choose File
                                    </span>
                                    <span className="filename">{filename}</span>
                                </div>
                                <div>
                                    <label>Payment mode</label>
                                    <select onChange={(e) => setMode(e.target.value)}>
                                        <option>Select State</option>
                                        <option value='pae'>Send Payment Link</option>
                                        <option value='pat'>Pay with Virtual Account</option>
                                    </select>
                                </div>
                            </div>
                            <div>
                                <p className="text-danger"><em>{err}</em></p>
                            </div>
                            <PreviewBuyHealth
                                show={modal}
                                healthdata={healthdata}
                                setModal={setModal}
                                customer={customerinfo}
                                config={healthconfig}
                                onHide={() => setModal(false)}
                            />
                            <Emailsent show={emailmodal} onHide={hideemail} />
                            <VirtualModal show={showModal} virtualdetails={virtualInfo} onHide={hideemail} />
                        </div>
                        <div className="page-control">
                            {page !== "" ? (
                                <span onClick={() => setPage("")}>Back</span>
                            ) : (
                                <span></span>
                            )}
                            {page !== "" ? (
                                <div className={`next-box ${mode === '' ? 'non-active' : '' }`}>
                                    <div onClick={() => mode !== '' && buypolicy()}>Buy</div>
                                </div>
                            ) : (
                                <div className={`next-box `}></div>
                            )}
                        </div>
                    </form>
                </div>
                {isLoading ? <Loader /> : ""}
            </div>
        </div>
    );
}

export default Buyhealth;
