import { useCallback, useState } from 'react'
import axios from 'axios'
import { Cookies } from 'react-cookie';
import { API } from '../Helpers/action';
import { useNavigate } from 'react-router-dom';
import {useToast} from "../Store/NotificationContext";

export const useCustomer = () => {
    const [error, setError] = useState(null)
    const { showToast } = useToast();
    const [isLoading, setIsLoading] = useState(null)
    const [data, setData] = useState([])
    const [liststate, setStates] = useState([])
    const [listgenders, setGender] = useState([])
    let navigate = useNavigate()

    const customer = useCallback(
        async () => {
            setIsLoading(true)
            setError(null)

            const cookies = new Cookies();
            let token =cookies.get('xhrToken')
    
            await axios.get(`${API}/broker/enrollee`,
            {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            } ).then((response) => {
                // console.log(response);
                setData(response.data.data.records)
                    setIsLoading(false)
                
            }).catch((err) => {
                setIsLoading(false)
                setError(err.response.data.message)
                // console.log(err.response.data.message)
            })
        }, []
    )
    
    const addcustomer = useCallback(
        async (data, setAddmodal) => {
            setIsLoading(true)
            setError(null)

            const cookies = new Cookies();
            let token =cookies.get('xhrToken')
    
            await axios.post(`${API}/broker/customer`, data,
            {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            } ).then((response) => {
                // console.log(response);
                setData(response.data.data)
                window.location.reload(true)
                navigate('/customer')
                setAddmodal()
                    setIsLoading(false)
                
            }).catch((err) => {
                setIsLoading(false)
                setError(err.response.data.message)
                // console.log(err.response.data.message)
            })
        }, [navigate]
    )

    const addbulkcustomer = useCallback(
        async (data, setAddmodal) => {
            setIsLoading(true)
            setError(null)

            const cookies = new Cookies();
            let token =cookies.get('xhrToken')
    
            await axios.post(`${API}/broker/customer/bulk`, data,
            {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data'
                }
            } ).then((response) => {
                // console.log(response);
                window.location.reload(true)
                setAddmodal()
                setIsLoading(false)
                
            }).catch((err) => {
                setIsLoading(false)
                setError(err.response.data.message)
                // console.log(err.response.data.message)
            })
        }, []
    )

    const states = useCallback(
        async () => {
            setIsLoading(true)
            setError(null)

            const cookies = new Cookies();
            let token =cookies.get('xhrToken')
    
            await axios.get(`${API}/states`,
            {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    // 'Content-Type': 'multipart/form-data'
                }
            } ).then((response) => {
                // console.log(response);
                setStates(response.data.data.states)
                setIsLoading(false)
                
            }).catch((err) => {
                setIsLoading(false)
                setError(err.response.data.message)
                // console.log(err.response.data.message)
            })
        }, []
    )

    const gender = useCallback(
        async () => {
            setIsLoading(true)
            setError(null)

            const cookies = new Cookies();
            let token =cookies.get('xhrToken')
    
            await axios.get(`${API}/gender`,
            {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    // 'Content-Type': 'multipart/form-data'
                }
            } ).then((response) => {
                // console.log(response);
                setGender(response.data.data)
                setIsLoading(false)
                
            }).catch((err) => {
                setIsLoading(false)
                setError(err.response.data.message)
                // console.log(err.response.data.message)
            })
        }, []
    )

    const searchCustomer = useCallback(
        async (name) => {
            setIsLoading(true)
            setError(null)
            setData([])

            const cookies = new Cookies();
            let token =cookies.get('xhrToken')

            await axios.post(`${API}/broker/customer/search?search=${name}`, {},
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                } ).then((response) => {
                console.log(response.data.data);
                setData(response.data.data.records)
                setIsLoading(false)
                return response.data.data
            }).catch((err) => {
                setIsLoading(false)
                if (err.response && err.response.status === 400) {
                    setData([])
                    console.log(err.response.data.message)
                    setError(err.response.data.message);
                    return err.response.data.message;
                } else {
                    // Handle other errors
                    console.error(err);
                    setError('An error occurred while processing your request.');
                }
            })
        }, []
    )

    const editCustomer = useCallback(
        async (data) => {
            setIsLoading(true)
            setError(null)

            const cookies = new Cookies();
            let token =cookies.get('xhrToken')

            await axios.post(`${API}/broker/customer/edit`, data,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                } ).then((response) => {
                // console.log(response);
                setData(response.data.data)
                showToast('Customer', response.data.message, 'success');
                // window.location.reload(true)
                setIsLoading(false)

            }).catch((err) => {
                setIsLoading(false)
                setError(err.response.data.message)
                // console.log(err.response.data.message)
            })
        }, [showToast]
    )

    return {editCustomer, customer, searchCustomer, data, states, gender, liststate, listgenders, addcustomer, addbulkcustomer, isLoading, error}
}
