import React from 'react'
import '../../Stylesheets/loader.css'

function Loader() {
  return (
    <div className='load-2'>
        <div className='Loader'></div>
        <div className='loader-container'>
        <div className="lds-ripple"><div></div><div></div></div>
        </div>
    </div>
  )
}

export default Loader