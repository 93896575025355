import React, {useCallback, useEffect, useRef, useState} from "react";
import { ReactComponent as Arrow } from "../Assets/icons/back-arrow.svg";
import { Link, useNavigate } from "react-router-dom";
import Table from "react-bootstrap/Table";
import { useCustomer } from "../Hooks/customer";
import { useUnderwriter } from "../Hooks/underwriter";
import { useBuyVehiclePolicy } from "../Hooks/buy-vehiclepolicy";
import Loader from "../Components/Loader/Loader";
import { usePolicy } from "../Hooks/policy";
import Dropdown from "react-bootstrap/Dropdown";
import PreviewBuyvehicle from "../Components/Vehicle/Preview-buy-vehicle";
import { ReactComponent as Nexticon } from "../Assets/icons/nextarrw.svg";
import { ReactComponent as Previcon } from "../Assets/icons/prevarrw.svg";
import ReactPaginate from "react-paginate";
import { Cookies } from "react-cookie";
import "../Stylesheets/buyhealth.css";
import PreviewQuote from "../Components/Vehicle/Preview-quote";
import Emailsent from "../Components/modal/Email-modal";
import CurrencyInput from "react-currency-input-field";
import TransferPayment from "../Components/modal/Transfermodal";
import {SearchNormal1} from "iconsax-react";
import ButttonLoader from "../Components/Loader/ButttonLoader";
import TableLoader from "../Components/Loader/TableLoader";

function Buyvehicle() {
  const navigate = useNavigate();
  const click = useRef("");
  const click1 = useRef("");
  const [modal, setModal] = useState(false);
  const [emailModal, setEmailModal] = useState(false);
  const [transferModal, setTransferModal] = useState(false);
  const [mode, setMode] = useState("");
  const [previewQuote, setPreviewQuote] = useState(false);
  const { customer, data, isLoading: customerLoading } = useCustomer();
  const cookies = new Cookies();
  const {
    buy_comprehensive_policy,
    buy_thirdparty_policy,
    config,
    buyLoading,
    error,
    buy_vehicle_policy,
    userid
  } = usePolicy();
  // console.log(userid);
  const {
    thirdpary_quote,
    comprehensive_quote,
    quote,
    isLoading,
    paymentmode,
    paymode,
  } = useBuyVehiclePolicy();
  const {
    getunderwriter,
    underwriters,
    aiico_resourses,
    chi_resourses,
    resourcesloding,
    resources,
  } = useUnderwriter();
  const [customerinfo, setCustomerInfo] = useState({
    firstname: "",
    lastname: "",
    address: "",
    phone: "",
    email: "",
  });
  let getUser = cookies.get("xhrUserxx");
  getUser = JSON.parse(atob(getUser));
  const [vehicledata, setVehicleData] = useState({
    agent_id: getUser.userid,
    underwriter_id: "",
    agent_user_id: "",
    vehicle_make_id: "",
    vehicle_model_id: "",
    vehicle_class_id: "",
    vehicle_usage_id: "",
    vehicle_category_id: "",
    driver_license_number: "",
    driver_license_issue_date: "",
    vehicle_color: "",
    // vehicle_value: "",
    occupation: "",
    date_of_birth: "",
    plate_number: "",
    state_id: "",
    lga_id: "",
    gender_id: "",
    idmeans_id: "",
    id_number: "",
    flood_cover: 0,
    excess_buyback: 1,
    validid_image: "",
    payment_frequency_id: "",
    regno_image: "",
    bvn: "",
    engine_no: "",
    chasis_no: "",
    year: "",
    reg_state_id: "",
    address: customerinfo.address,
  });
  const [vehiclemodel, setVehiclemodel] = useState([]);
  const [lga, setLga] = useState([]);
  const [filename, setFilename] = useState({
    validid_image: "",
    regno_image: "",
  });
  const [page, setPage] = useState("");
  let getinfo = cookies.get("xirhnfo");
  const provider = async (e) => {
    if (getinfo !== undefined) {
      let info = JSON.parse(atob(getinfo));
      setCustomerInfo({
        ...customerinfo,
        firstname: info.firstname,
        lastname: info.lastname,
        email: info.email,
        phone: info.phone,
        address: info.address,
        agent_user_id: info.agent_user_id,
      });
      setVehicleData({
        ...vehicledata,
        [e.target.name]: JSON.parse(e.target.value),
        agent_user_id: info.agent_user_id,
        address: info.address,
      });
    } else {
      setVehicleData({
        ...vehicledata,
        [e.target.name]: JSON.parse(e.target.value),
        address: customerinfo.address,
      });
    }
    if (e.target.value === "2") {
      await aiico_resourses();
    } else {
      await chi_resourses();
    }
  };
  const currency = (value) => {
    let v = value;
    // console.log(value);
    if (value !== undefined)
      setVehicleData({
        ...vehicledata,
        vehicle_value: JSON.parse(v) * 100,
      });
  };
  const hideEmail = () => {
    setEmailModal(false);
    navigate("/policy/vehicle/all");
  };
  const getVehicleModel = (e) => {
    setVehicleData({
      ...vehicledata,
      [e.target.name]: JSON.parse(e.target.value),
    });
    if (e.target.value === "0") {
      setVehiclemodel([]);
    } else {
      const searchObject = resources.vehicle_make.find(
        (model) => model.id === JSON.parse(e.target.value)
      );
      setVehiclemodel(searchObject.models);
    }
  };
  const getLga = (e) => {
    setVehicleData({
      ...vehicledata,
      [e.target.name]: JSON.parse(e.target.value),
    });
    if (vehicledata.underwriter_id === 1) {
      const searchObject = resources.states.find(
        (state) => state.id === JSON.parse(e.target.value)
      );
      setLga(searchObject.lgas);
    } else if (vehicledata.underwriter_id === 2) {
      const searchObject = resources.states.find(
        (state) => state.id === JSON.parse(e.target.value)
      );
      setLga(searchObject.models);
    }
  };
  const getQuote = () => {
    
    if (vehicledata.vehicle_category_id === 1) {
      let data = {
        underwriter_id: vehicledata.underwriter_id,
        agent_user_id: vehicledata.agent_user_id,
        // agent_id: vehicledata.agent_id,
        vehicle_make_id: vehicledata.vehicle_make_id,
        vehicle_model_id: vehicledata.vehicle_model_id,
        vehicle_class_id: vehicledata.vehicle_class_id,
        vehicle_usage_id: vehicledata.vehicle_usage_id,
        vehicle_category_id: vehicledata.vehicle_category_id,
        flood_cover: vehicledata.flood_cover,
        excess_buyback: vehicledata.excess_buyback,
        payment_frequency_id: vehicledata.payment_frequency_id,
        // vehicle_value: vehicledata.vehicle_value,
      };
      thirdpary_quote(data, setPreviewQuote);
    } else {
      let data = {
        underwriter_id: vehicledata.underwriter_id,
        agent_user_id: vehicledata.agent_user_id,
        vehicle_make_id: vehicledata.vehicle_make_id,
        vehicle_model_id: vehicledata.vehicle_model_id,
        vehicle_class_id: vehicledata.vehicle_class_id,
        vehicle_usage_id: vehicledata.vehicle_usage_id,
        vehicle_category_id: vehicledata.vehicle_category_id,
        flood_cover: vehicledata.flood_cover,
        excess_buyback: vehicledata.excess_buyback,
        payment_frequency_id: vehicledata.payment_frequency_id,
        vehicle_value: vehicledata.vehicle_value,
      };
      comprehensive_quote(data, setPreviewQuote);
    }
  };
  const file = () => {
    click.current.click();
  };
  const file1 = () => {
    click1.current.click();
  };

  function encodeImageFileAsURL(e) {
    let file = e.target.files[0];
    let reader = new FileReader();
    setFilename({ ...filename, [e.target.name]: file.name });
    reader.onloadend = function () {
      setVehicleData({ ...vehicledata, [e.target.name]: reader.result });
    };
    reader.readAsDataURL(file);
  }
  const buyPolicy = async () => {
    if(mode === "tta"){
      buy_vehicle_policy(vehicledata, setTransferModal, )
      // setTransferModal(true)
    } else {
      if (vehicledata.vehicle_category_id === 1) {
        await buy_thirdparty_policy(vehicledata, setModal, mode, setEmailModal);
      } else {
        await buy_comprehensive_policy(
          vehicledata,
          setModal,
          mode,
          setEmailModal
        );
      }
    }
    
  };

  const [itemOffset, setItemOffset] = useState(0);
  const [filteredItemOffset, setFilteredItemOffset] = useState(0);
  let itemsPerPage = 20;
  const endOffset = itemOffset + itemsPerPage;
  const pageCount = Math.ceil(data.length / itemsPerPage);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % data.length;

    setItemOffset(newOffset);
  };
  useEffect(() => {
    customer();
    getunderwriter();
    if (getinfo !== undefined) {
      paymentmode()
  }
  }, [ customer, getunderwriter, getinfo, paymentmode]);
  const {searchCustomer, data: searchedData, isLoading: loader} = useCustomer()
  const [searchInput, setSearchInput] = useState('')
  const search = useCallback(async () => {
    try {
      await searchCustomer(searchInput);
    } catch (err) {
      console.error(err);
    }
  }, [searchCustomer, searchInput]);
  // console.log(searchedData);

  const filteredEndOffset = filteredItemOffset + itemsPerPage;
  const filteredPageCount = Math.ceil(searchedData.length / itemsPerPage);
  const handleFilteredPageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % searchedData.length;

    setFilteredItemOffset(newOffset);
  };

  const normalPost = data.slice(itemOffset, endOffset).map((data, index) => {
    return (
        <tr key={index}>
          <td>{itemOffset + 1 + index++}</td>
          <td>{data.lastname + " " + data.firstname}</td>
          <td>{data.email}</td>
          <td colSpan={2}>{data.address}</td>
          <td>{data.phone}</td>
          <td>
            <Dropdown>
              <Dropdown.Toggle
                  variant=""
                  id="dropdown-basic"
                  className="buy-policy buy-policy-but"
                  onMouseOver={() => {
                    setCustomerInfo({
                      ...customerinfo,
                      firstname: data.firstname,
                      lastname: data.lastname,
                      email: data.email,
                      phone: data.phone,
                      agent_user_id: data.id,
                      address: data.address,
                    });
                    setVehicleData({
                      ...vehicledata,
                      agent_user_id: data.id,
                    });
                  }}
                  onClick={() => {
                    paymentmode();
                    setPage("startform");
                  }}
              >
                Buy
              </Dropdown.Toggle>
            </Dropdown>
          </td>
        </tr>
    );
  })
  const filteredPost = searchedData.slice(filteredItemOffset, filteredEndOffset).map((data, index) => {
    return (
        <tr key={index}>
          <td>{itemOffset + 1 + index++}</td>
          <td>{data.lastname + " " + data.firstname}</td>
          <td>{data.email}</td>
          <td colSpan={2}>{data.address}</td>
          <td>{data.phone}</td>
          <td>
            <Dropdown>
              <Dropdown.Toggle
                  variant=""
                  id="dropdown-basic"
                  className="buy-policy buy-policy-but"
                  onMouseOver={() => {
                    setCustomerInfo({
                      ...customerinfo,
                      firstname: data.firstname,
                      lastname: data.lastname,
                      email: data.email,
                      phone: data.phone,
                      agent_user_id: data.id,
                      address: data.address,
                    });
                    setVehicleData({
                      ...vehicledata,
                      agent_user_id: data.id,
                    });
                  }}
                  onClick={() => {
                    paymentmode();
                    setPage("startform");
                  }}
              >
                Buy
              </Dropdown.Toggle>
            </Dropdown>
          </td>
        </tr>
    );
  })
  return (
    <div>
      <div className="navigate-back">
        <Link onClick={() => navigate(-1)}>
          <Arrow />
        </Link>
      </div>
      <div className="vehicle-policy-container">
        <h4 className="dashboard-title">Buy Vehicle Policy</h4>
        <div className="claims-table-container buy-vehicle-box">
          <form className="health-form buy-form" autoComplete="off">
            <div
              className={`${
                getinfo !== undefined ? "d-none" : page !== "" ? "d-none" : ""
              }`}
            >
              <div className="lists-cust">
                <div className={`row justify-content-between align-items-center  py-3`}>
                  <h5 className={`col-2`}>Customer</h5>
                  <div className={`col-4 justify-content-end`}>
                    <div className={`d-flex`}>
                      <input placeholder='search customer' onChange={(e) => setSearchInput(e.target.value)} className={`focus-visible:outline-none searchinputCustomer rounded-start`}/>
                      <button type='button' className='p-2 border-0 buy-policy rounded-end d-flex' onClick={() => loader ? '' : search()}>
                        {
                          loader ? <ButttonLoader /> : <SearchNormal1 size="24" color="white"/>
                        }
                      </button>
                    </div>
                  </div>
                </div>
                <Table className="">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Name</th>
                      <th>Email</th>
                      <th colSpan={2}>Address</th>
                      <th>Phone Number</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                  { loader || customerLoading ?
                      <tr>
                        <td className='border-0' colSpan={6} rowSpan={6}>
                          <div className='d-flex justify-content-center py-5'>
                            <TableLoader/>
                          </div>
                        </td>
                      </tr> :
                      searchedData.length !== 0 ? filteredPost : normalPost}

                  </tbody>
                </Table>
                { loader || customerLoading ? <div></div> : searchedData.length !== 0 ?
                    <ReactPaginate
                        breakLabel="..."
                        previousLabel={<Previcon/>}
                        nextLabel={<Nexticon />}
                        pageCount={filteredPageCount}
                        onPageChange={handleFilteredPageClick}
                        containerClassName={"paginatecontainer"}
                        previousLinkClassName={"prevbtn"}
                        pageClassName={"pageli"}
                        nextLinkClassName={"nextbtn"}
                        disabledClassName={"paginationdisabled"}
                        activeClassName={"paginationactive"}
                        breakClassName={"breakli"}
                        renderOnZeroPageCount={null}
                        pageRangeDisplayed={3}
                        marginPagesDisplayed={2}
                    />
                    :
                    <ReactPaginate
                    breakLabel="..."
                    previousLabel={<Previcon/>}
                    nextLabel={<Nexticon />}
                    pageCount={pageCount}
                    onPageChange={handlePageClick}
                    containerClassName={"paginatecontainer"}
                    previousLinkClassName={"prevbtn"}
                    pageClassName={"pageli"}
                    nextLinkClassName={"nextbtn"}
                    disabledClassName={"paginationdisabled"}
                    activeClassName={"paginationactive"}
                    breakClassName={"breakli"}
                    renderOnZeroPageCount={null}
                    pageRangeDisplayed={3}
                    marginPagesDisplayed={2}
                />
                }
              </div>
            </div>
            <div
              className={`${
                getinfo !== undefined && page === ""
                  ? ""
                  : getinfo !== undefined && page !== "startform"
                  ? "d-none"
                  : getinfo === undefined && page !== "startform"
                  ? "d-none"
                  : ""
              }`}
            >
              <div className="input-group">
                <div>
                  <label>Choose your preferred Insurance provider?</label>
                  <select
                    name="underwriter_id"
                    defaultValue="Select Customer"
                    onChange={provider}
                  >
                    <option value="0">Select Provider</option>
                    {underwriters.map((data, index) => {
                      return (
                        <option key={index} value={data.id}>
                          {data.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div>
                  <label>Available Plans</label>
                  <select
                    name="vehicle_category_id"
                    defaultValue="Select Customer"
                    onChange={(e) =>
                      setVehicleData({
                        ...vehicledata,
                        [e.target.name]: JSON.parse(e.target.value),
                      })
                    }
                  >
                    <option>
                      {resourcesloding ? "Loading..." : "Select Plan"}
                    </option>
                    {resources.vehicle_category.map((data, index) => {
                      return (
                        <option key={index} value={data.id}>
                          {data.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              <div className="page-control">
                {getinfo === undefined ? (
                  <span onClick={() => setPage("")}>Back</span>
                ) : (
                  <span></span>
                )}
                <div
                  className={`next-box ${
                    vehicledata.agent_user_id === "" ||
                    vehicledata.underwriter_id === "" ||
                    vehicledata.vehicle_category_id === ""
                      ? "non-active"
                      : ""
                  }`}
                >
                  <div
                    onClick={() => {
                      vehicledata.agent_user_id === "" ||
                      vehicledata.underwriter_id === "" ||
                      vehicledata.vehicle_category_id === ""
                        ? setPage("startform")
                        : setPage("quotationform");
                      getinfo = undefined;
                    }}
                  >
                    Next
                  </div>
                </div>
              </div>
            </div>
            <div className={`${page === "quotationform" ? "" : "d-none"}`}>
              <div className="quotaion-title">
                <h6>Quotation Form</h6>
                <p>
                  {vehicledata.vehicle_category_id === 1
                    ? "Third party"
                    : vehicledata.vehicle_category_id === 2
                    ? "Comprehensive"
                    : "Chi-Prime"}{" "}
                  Vehicle Cover
                </p>
              </div>
              <div className="input-group quote-group">
                <div>
                  <label>Vehicle Make</label>
                  <select name="vehicle_make_id" onChange={getVehicleModel}>
                    <option value="0">Select Vechicle</option>
                    {resources.vehicle_make.map((data, index) => {
                      return (
                        <option key={index} value={data.id}>
                          {data.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div>
                  <label>Vehicle Model</label>
                  <select
                    name="vehicle_model_id"
                    defaultValue="Select Customer"
                    onChange={(e) =>
                      setVehicleData({
                        ...vehicledata,
                        [e.target.name]: JSON.parse(e.target.value),
                      })
                    }
                  >
                    <option value="0">Select Vehicle Model</option>
                    {vehiclemodel.map((data, index) => {
                      return (
                        <option key={index} value={data.id}>
                          {data.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="xs-mobile-none">
                  <label>Vehicle Type</label>
                  <select
                    name="vehicle_class_id"
                    onChange={(e) =>
                      setVehicleData({
                        ...vehicledata,
                        [e.target.name]: JSON.parse(e.target.value),
                      })
                    }
                  >
                    <option value="0">Select Vehicle Type</option>
                    {resources.vehicle_class.map((data, index) => {
                      return (
                        <option key={index} value={data.id}>
                          {data.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="mobile-none">
                  <label>Vehicle Usage</label>
                  <select
                    name="vehicle_usage_id"
                    onChange={(e) =>
                      setVehicleData({
                        ...vehicledata,
                        [e.target.name]: JSON.parse(e.target.value),
                      })
                    }
                  >
                    <option value="0">Select Vehicle Usage</option>
                    {resources.vehicle_usage.map((data, index) => {
                      return (
                        <option key={index} value={data.id}>
                          {data.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              <div className="input-group quote-group xs-mobile-show">
                <div className="">
                  <label>Vehicle Type</label>
                  <select
                    name="vehicle_class_id"
                    onChange={(e) =>
                      setVehicleData({
                        ...vehicledata,
                        [e.target.name]: JSON.parse(e.target.value),
                      })
                    }
                  >
                    <option value="0">Select Vehicle Type</option>
                    {resources.vehicle_class.map((data, index) => {
                      return (
                        <option key={index} value={data.id}>
                          {data.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="">
                  <label>Vehicle Usage</label>
                  <select
                    name="vehicle_usage_id"
                    onChange={(e) =>
                      setVehicleData({
                        ...vehicledata,
                        [e.target.name]: JSON.parse(e.target.value),
                      })
                    }
                  >
                    <option value="0">Select Vehicle Usage</option>
                    {resources.vehicle_usage.map((data, index) => {
                      return (
                        <option key={index} value={data.id}>
                          {data.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              <div className="input-group quote-group">
                <div className="mobile-show xs-mobile-none">
                  <label>Vehicle Usage</label>
                  <select
                    name="vehicle_usage_id"
                    onChange={(e) =>
                      setVehicleData({
                        ...vehicledata,
                        [e.target.name]: JSON.parse(e.target.value),
                      })
                    }
                  >
                    <option value="0">Select Vehicle Usage</option>
                    {resources.vehicle_usage.map((data, index) => {
                      return (
                        <option key={index} value={data.id}>
                          {data.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
                {vehicledata.vehicle_category_id !== 1 ? (
                  <div>
                    <label>Payment Frequency*</label>
                    <select
                      name="payment_frequency_id"
                      defaultValue="Select Customer"
                      onChange={(e) =>
                        setVehicleData({
                          ...vehicledata,
                          [e.target.name]: JSON.parse(e.target.value),
                        })
                      }
                    >
                      <option value="0">Select Payment frequency</option>
                      {resources.payment_frequency.map((data, index) => {
                        return (
                          <option key={index} value={data.id}>
                            {data.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                ) : (
                  <div>
                    <label>Payment Frequency*</label>
                    <select
                      name="payment_frequency_id"
                      defaultValue={"Select Customer"}
                      onChange={(e) =>
                        setVehicleData({
                          ...vehicledata,
                          [e.target.name]: JSON.parse(e.target.value),
                        })
                      }
                    >
                      <option value="0">Select Payment frequency</option>
                      {resources.payment_frequency
                        .slice(2, 3)
                        .map((data, index) => {
                          return (
                            <option key={index} value={data.id}>
                              {data.name}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                )}
                {/* <div>
                  <label>Payment Frequency*</label>
                  <select
                    name="payment_frequency_id"
                    defaultValue="Select Customer"
                    onChange={(e) =>
                      setVehicleData({
                        ...vehicledata,
                        [e.target.name]: JSON.parse(e.target.value),
                      })
                    }
                  >
                    <option value='0'>Select Payment Frequency</option>
                    {resources.payment_frequency.map((data, index) => {
                      return (
                        <option key={index} value={data.id}>
                          {data.name}
                        </option>
                      );
                    })}
                  </select>
                </div> */}
                {vehicledata.vehicle_category_id !== 1 ? (
                  <div>
                    <label>Car Value (in Naira)</label>
                    {/* <input
                    type="text"
                    name="vehicle_value"
                    value={formattedamount(vehicledata.vehicle_value)}
                    onChange={
                      (e) => setVehicleData({
                        ...vehicledata,
                        [e.target.name]: e.target.value,
                      })
                    }
                  /> */}
                    <CurrencyInput
                      id="input-example"
                      name="input-name"
                      placeholder="₦1,234,5678"
                      // defaultValue={0}
                      decimalsLimit={2}
                      prefix={"₦"}
                      onValueChange={(value) => currency(value)}
                    />
                    ;
                  </div>
                ) : (
                  <div>
                    <label></label>
                    <input hidden />
                  </div>
                )}
                <div>
                  <label></label>
                  <input hidden />
                </div>
                <div>
                  <label></label>
                  <input hidden />
                </div>
              </div>
              <div
                className={`flood-section ${
                  vehicledata.vehicle_category_id === 1 ? "d-none" : ""
                }`}
              >
                <h6>Do you want flood cover?(extra charges apply)</h6>
                <div className="flood-radio">
                  <input
                    type="radio"
                    id="flood_cover_yes"
                    name="flood_cover"
                    checked={vehicledata.flood_cover === 1 ? true : false}
                    value="1"
                    onChange={(e) =>
                      setVehicleData({
                        ...vehicledata,
                        [e.target.name]: JSON.parse(e.target.value),
                      })
                    }
                  />
                  <label htmlFor="html">Yes</label>
                </div>
                <div className="flood-radio">
                  <input
                    type="radio"
                    id="flood_cover_yes"
                    name="flood_cover"
                    checked={vehicledata.flood_cover === 0 ? true : false}
                    value="0"
                    onChange={(e) =>
                      setVehicleData({
                        ...vehicledata,
                        [e.target.name]: JSON.parse(e.target.value),
                      })
                    }
                  />
                  <label htmlFor="html">No</label>
                </div>
              </div>
              <div className="page-control">
                <span onClick={() => setPage("startform")}>Back</span>
                <div
                  className={`next-box ${
                    vehicledata.vehicle_make_id === "" ||
                    vehicledata.vehicle_model_id === "" ||
                    vehicledata.vehicle_class_id === "" ||
                    vehicledata.vehicle_usage_id === "" ||
                    vehicledata.payment_frequency_id === ""
                      ? "non-active"
                      : ""
                  }`}
                >
                  <div
                    onClick={() => {
                      vehicledata.vehicle_make_id === "" ||
                      vehicledata.vehicle_model_id === "" ||
                      vehicledata.vehicle_class_id === "" ||
                      vehicledata.vehicle_usage_id === "" ||
                      vehicledata.payment_frequency_id === ""
                        ? setPage("quotationform")
                        : getQuote();
                    }}
                  >
                    Get Quote
                  </div>
                </div>
              </div>
            </div>
            <PreviewQuote
              show={previewQuote}
              quote={quote}
              onPage={() => setPage("completeform")}
              onHide={() => setPreviewQuote(false)}
            />
            {/* <div className={`${page === "displayquote" ? "" : "d-none"}`}>
              <div className="quotaion-title">
                <h6>Quotation Form</h6>
                <p>
                  {vehicledata.vehicle_category_id === 1
                    ? "Third party"
                    : vehicledata.vehicle_category_id === 2
                      ? "Comprehensive"
                      : "Chi-Prime"}{" "}
                  Vehicle Cover
                </p>
              </div>
              <div className="input-group quote-group">
                <div>
                  <label>Vehicle Make</label>
                  <p>{quote.vehicle_make} </p>
                </div>
                <div>
                  <label>Vehicle Model</label>
                  <p>{quote.vehicle_model}</p>
                </div>
                <div>
                  <label>Vehicle Type</label>
                  <p>{quote.vehicle_class}</p>
                </div>
                <div>
                  <label></label>
                  <input hidden />
                </div>
              </div>
              <div className="input-group quote-group">
                <div>
                  <label>Excess Buyback</label>
                  <p>{quote.excess_buyback}</p>
                </div>
                <div>
                  <label>Flood Damage</label>
                  <p>{quote.flood_cover}</p>
                </div>
                <div>
                  <label></label>
                  <input hidden />
                </div>
                <div>
                  <label></label>
                  <input hidden />
                </div>
              </div>
              <div className="input-group">
                <div className="total-premium">
                  <label>Total Premium</label>
                  <p>₦{quote.financed_premium}</p>
                </div>
                <div>
                  <label></label>
                  <input hidden />
                </div>
                <div>
                  <label></label>
                  <input hidden />
                </div>
                <div>
                  <label></label>
                  <input hidden />
                </div>
              </div>
              <div className="flood-section px-0">
                <div className="input-group">
                  <div className="total-premium">
                    <label>Monthly Payment</label>
                    <p>{quote.price}</p>
                  </div>
                  <div>
                    <label></label>
                    <input hidden />
                  </div>
                  <div>
                    <label></label>
                    <input hidden />
                  </div>
                  <div>
                    <label></label>
                    <input hidden />
                  </div>
                </div>
              </div>
              <div className="page-control">
                <span onClick={() => setPage("quotationform")}>Back</span>
                <div className="next-box">
                  <div onClick={() => setPage("completeform")}>Pay</div>
                </div>
              </div>
            </div> */}
            <div className={`${page === "completeform" ? "" : "d-none"}`}>
              <div className="quotaion-title">
                <h6>Quotation Form</h6>
                <p>
                  {vehicledata.vehicle_category_id === 1
                    ? "Third party"
                    : vehicledata.vehicle_category_id === 2
                    ? "Comprehensive"
                    : "Chi-Prime"}{" "}
                  Vehicle Cover
                </p>
              </div>
              <div>
                <div className="input-group quote-group">
                  <div>
                    <label>Plate Number</label>
                    <input
                      type="text"
                      name="plate_number"
                      onChange={(e) =>
                        setVehicleData({
                          ...vehicledata,
                          [e.target.name]: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div>
                    <label>Driver's License no</label>
                    <input
                      type="text"
                      name="driver_license_number"
                      onChange={(e) =>
                        setVehicleData({
                          ...vehicledata,
                          [e.target.name]: e.target.value,
                        })
                      }
                    />
                  </div>
                  {vehicledata.underwriter_id === 1 ? (
                    <div className="xs-mobile-none">
                      <label>Color of Vehicle</label>
                      <input
                        type="text"
                        name="vehicle_color"
                        onChange={(e) =>
                          setVehicleData({
                            ...vehicledata,
                            [e.target.name]: e.target.value,
                          })
                        }
                      />
                    </div>
                  ) : (
                    <div className="xs-mobile-none">
                      <label>Color of Vehicle</label>
                      <select
                        name="color_id"
                        onChange={(e) =>
                          setVehicleData({
                            ...vehicledata,
                            [e.target.name]: JSON.parse(e.target.value),
                          })
                        }
                      >
                        <option value="0">Select Color</option>
                        {resources.color.map((data, index) => {
                          return (
                            <option key={index} value={data.id}>
                              {data.name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  )}
                  <div className="mobile-none">
                    <label>Chasis Number</label>
                    <input
                      type="text"
                      name="chasis_no"
                      onChange={(e) =>
                        setVehicleData({
                          ...vehicledata,
                          [e.target.name]: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>
                <div className="input-group quote-group xs-mobile-show">
                  {vehicledata.underwriter_id === 1 ? (
                    <div>
                      <label>Color of Vehicle</label>
                      <input
                        type="text"
                        name="vehicle_color"
                        onChange={(e) =>
                          setVehicleData({
                            ...vehicledata,
                            [e.target.name]: e.target.value,
                          })
                        }
                      />
                    </div>
                  ) : (
                    <div>
                      <label>Color of Vehicle</label>
                      <select
                        name="color_id"
                        onChange={(e) =>
                          setVehicleData({
                            ...vehicledata,
                            [e.target.name]: JSON.parse(e.target.value),
                          })
                        }
                      >
                        <option value="0">Select Color</option>
                        {resources.color.map((data, index) => {
                          return (
                            <option key={index} value={data.id}>
                              {data.name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  )}
                  <div className="">
                    <label>Chasis Number</label>
                    <input
                      type="text"
                      name="chasis_no"
                      onChange={(e) =>
                        setVehicleData({
                          ...vehicledata,
                          [e.target.name]: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>
                <div className="input-group quote-group">
                  <div className="mobile-show xs-mobile-none">
                    <label>Chasis Number</label>
                    <input
                      type="text"
                      name="chasis_no"
                      onChange={(e) =>
                        setVehicleData({
                          ...vehicledata,
                          [e.target.name]: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div>
                    <label>BVN</label>
                    <input
                      type="number"
                      name="bvn"
                      onChange={(e) =>
                        setVehicleData({
                          ...vehicledata,
                          [e.target.name]: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div>
                    <label>Engine no</label>
                    <input
                      type="text"
                      name="engine_no"
                      onChange={(e) =>
                        setVehicleData({
                          ...vehicledata,
                          [e.target.name]: e.target.value,
                        })
                      }
                    />
                  </div>
                  {vehicledata.underwriter_id === 1 ? (
                    <div className="mobile-none">
                      <label>Year of make</label>
                      <input
                        type="text"
                        name="year"
                        onChange={(e) =>
                          setVehicleData({
                            ...vehicledata,
                            [e.target.name]: e.target.value,
                          })
                        }
                      />
                    </div>
                  ) : (
                    <div className="mobile-none">
                      <label>Year of make</label>
                      <select
                        name="year_id"
                        onChange={(e) =>
                          setVehicleData({
                            ...vehicledata,
                            [e.target.name]: JSON.parse(e.target.value),
                          })
                        }
                      >
                        <option value="0">Select Year</option>
                        {resources.year.map((data, index) => {
                          return (
                            <option key={index} value={data.id}>
                              {data.name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  )}
                  <div>
                    <label></label>
                    <input hidden />
                  </div>
                </div>
                <div className=" input-group quote-group mobile-show">
                  {vehicledata.underwriter_id === 1 ? (
                    <div className="">
                      <label>Year of make</label>
                      <input
                        type="text"
                        name="year"
                        onChange={(e) =>
                          setVehicleData({
                            ...vehicledata,
                            [e.target.name]: e.target.value,
                          })
                        }
                      />
                    </div>
                  ) : (
                    <div className="">
                      <label>Year of make</label>
                      <select
                        name="year_id"
                        onChange={(e) =>
                          setVehicleData({
                            ...vehicledata,
                            [e.target.name]: JSON.parse(e.target.value),
                          })
                        }
                      >
                        <option value="0">Select Year</option>
                        {resources.year.map((data, index) => {
                          return (
                            <option key={index} value={data.id}>
                              {data.name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  )}
                  <div>
                    <label></label>
                    <input hidden />
                  </div>
                  <div>
                    <label></label>
                    <input hidden />
                  </div>
                </div>
                <div className="personal-info-form">
                  <div id="first">
                    <div className="input-group personal-group">
                      <div>
                        <label>First Name</label>
                        <input
                          type="text"
                          value={customerinfo.firstname}
                          readOnly
                        />
                      </div>
                      <div>
                        <label>Last Name</label>
                        <input
                          type="text"
                          value={customerinfo.lastname}
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="input-group personal-single">
                      <div>
                        <label>Email address</label>
                        <input
                          type="email"
                          value={customerinfo.email}
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="input-group personal-single">
                      <div>
                        <label>Phone number</label>
                        <input type="tel" value={customerinfo.phone} readOnly />
                      </div>
                    </div>
                    <div className="input-group personal-single">
                      <div>
                        <label>Gender*</label>
                        <select
                          name="gender_id"
                          onChange={(e) =>
                            setVehicleData({
                              ...vehicledata,
                              [e.target.name]: JSON.parse(e.target.value),
                            })
                          }
                        >
                          <option value="0">Select Gender</option>
                          {resources.gender.map((data, index) => {
                            return (
                              <option key={index} value={data.id}>
                                {data.name}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                    <div className="input-group personal-single">
                      <div>
                        <label>Means of ID</label>
                        <select
                          name="idmeans_id"
                          onChange={(e) =>
                            setVehicleData({
                              ...vehicledata,
                              [e.target.name]: JSON.parse(e.target.value),
                            })
                          }
                        >
                          <option value="0">Select Means of id</option>
                          {resources.idmeans.map((data, index) => {
                            return (
                              <option key={index} value={data.id}>
                                {data.name}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                    <div className="input-group personal-single">
                      <div>
                        <label>ID Number</label>
                        <input
                          type="text"
                          name="id_number"
                          onChange={(e) =>
                            setVehicleData({
                              ...vehicledata,
                              [e.target.name]: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>
                    <div className="input-group personal-single">
                      <div>
                        <label>
                          Upload Valid ID (Driver’s licence, Permanent Voter’s
                          Card, Intl Passport )*
                        </label>
                        <input
                          type="file"
                          name="validid_image"
                          ref={click}
                          onChange={encodeImageFileAsURL}
                          hidden
                        />
                        <span className="fileinput" onClick={file}>
                          Choose File
                        </span>
                        <span className="filename">
                          {filename.validid_image}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div id="second">
                    <div className="input-group personal-single">
                      <div>
                        <label>Date of birth</label>
                        <input
                          type="date"
                          name="date_of_birth"
                          onChange={(e) =>
                            setVehicleData({
                              ...vehicledata,
                              [e.target.name]: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>
                    <div className="input-group personal-single">
                      <div>
                        <label>Enter occupation</label>
                        <input
                          type="text"
                          name="occupation"
                          onChange={(e) =>
                            setVehicleData({
                              ...vehicledata,
                              [e.target.name]: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>
                    <div className="input-group personal-group">
                      <div>
                        <label>State of residence</label>
                        <select name="state_id" onChange={getLga}>
                          <option value="0">Select State</option>
                          {resources.states.map((data, index) => {
                            return (
                              <option key={index} value={data.id}>
                                {data.name}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                      <div>
                        <label>LGA</label>
                        <select name="lga_id" onChange={provider}>
                          <option value="0">Select Lga</option>
                          {lga.map((data, index) => {
                            return (
                              <option key={index} value={data.id}>
                                {data.name}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                    {vehicledata.vehicle_category_id === 1 ? (
                      <></>
                    ) : (
                      <div className="input-group personal-single">
                        <div>
                          <label>
                            Upload vehicle picture showing the plate number of
                            the Vehicle *
                          </label>
                          <input
                            type="file"
                            ref={click1}
                            name="regno_image"
                            onChange={encodeImageFileAsURL}
                            hidden
                          />
                          <span className="fileinput" onClick={file1}>
                            Choose File
                          </span>
                          <span className="filename">
                            {filename.regno_image}
                          </span>
                        </div>
                      </div>
                    )}

                    <div className="input-group personal-single">
                      <div>
                        <label>State of vehicle Registration</label>
                        <select
                          name="reg_state_id"
                          onChange={(e) =>
                            setVehicleData({
                              ...vehicledata,
                              [e.target.name]: JSON.parse(e.target.value),
                            })
                          }
                        >
                          <option value="0">Select State</option>
                          {resources.states.map((data, index) => {
                            return (
                              <option key={index} value={data.id}>
                                {data.name}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                    <div className="input-group personal-single">
                      <div>
                        <label>Payment mode</label>
                        <select onChange={(e) => setMode(e.target.value)}>
                          <option value="0">Select mode</option>
                          {paymode.map((mode, index) => {
                            return (
                              <option
                                key={index}
                                value={
                                  mode.id === 1
                                    ? "pat"
                                    : mode.id === 2
                                    ? "pac"
                                    : mode.id === 3
                                    ? "tta"
                                    : ""
                                }
                              >
                                {mode.name === "PaymentLink"
                                  ? "Send payment link"
                                  : mode.name === "Card"
                                  ? "Pay with Card"
                                  : mode.name === "PaddyCoverTransfer"
                                  ? "Transfer to account"
                                  : ""}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                    <div
                      className={`input-group personal-single ${
                        vehicledata.underwriter_id === 2 ? "" : "d-none"
                      }`}
                    >
                      <div>
                        <label>License Issue Date</label>
                        <input
                          type="date"
                          name="driver_license_issue_date"
                          onChange={(e) =>
                            setVehicleData({
                              ...vehicledata,
                              [e.target.name]: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <PreviewBuyvehicle
                show={modal}
                quote={quote}
                config={config}
                onHide={() => setModal(false)}
              />
              <Emailsent show={emailModal} onHide={hideEmail} />
              <TransferPayment show={transferModal} quote={quote} userid={userid} check={'buyPolicy'} onHide={() => setTransferModal(false)} />
              <p className="text-danger">{error}</p>
              <div className="page-control">
                <span onClick={() => setPage("quotationform")}>Back</span>
                <div
                  className={`next-box ${
                    vehicledata.validid_image === "" ||
                    mode === "" ||
                    vehicledata.gender_id === "" ||
                    vehicledata.idmeans_id === "" ||
                    vehicledata.lga_id === "" ||
                    vehicledata.reg_state_id === ""
                      ? "non-active"
                      : ""
                  }`}
                >
                  <div
                    onClick={() => {
                      vehicledata.validid_image === "" ||
                      mode === "" ||
                      vehicledata.gender_id === "" ||
                      vehicledata.idmeans_id === "" ||
                      vehicledata.lga_id === "" ||
                      vehicledata.reg_state_id === ""
                        ? setPage("completeform")
                        : buyPolicy();
                    }}
                  >
                    Proceed
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      {isLoading ? <Loader /> : ""}
      {buyLoading ? <Loader /> : ""}
    </div>
  );
}

export default Buyvehicle;
