import React from "react";
import "../Stylesheets/policy.css";
import { ReactComponent as Vehicle } from "../Assets/icons/vehiclepolicy.svg";
import { ReactComponent as Health } from "../Assets/icons/healthpolicy.svg";
import { ReactComponent as Travel } from "../Assets/icons/travel.svg";
import { ReactComponent as Pet } from "../Assets/icons/pet.svg";
import { Link } from "react-router-dom";
import { Cookies } from "react-cookie";

function Policy() {
  const cookies = new Cookies()
  // let navigate = useNavigate()
  const removecookies = () => {
    // console.log("checking");
      cookies.remove('xirhnfo', {path: '/'})
      // navigate(`${props}`)
  }

  return (
    <div className="policy-container">
      <h4 className="dashboard-title">Policy</h4>
      <div className="admin-policy-card-container">
          <div  className="admin-policy-card">
            <div>
              <h4>Vehicle Policy</h4>
              <Vehicle />
              <p>
              For insurance on vehicles ranging from f...
              </p>
              <Link to='vehicle/all'  className="admin-policy-button" onClick={() => removecookies()}>View polices</Link>
              <Link to='vehicle' className="admin-policy-button manage-policy-button" onClick={removecookies}>Manage polices</Link>
            </div>
          </div>
          <div className="admin-policy-card">
            <div>
              <h4>Health Policy</h4>
              <Health />
              <p>
              For insurance on vehicles ranging from f...
              </p>
              <Link to='health/all' className="admin-policy-button" onClick={removecookies}>View polices</Link>
              <Link to='health' className="admin-policy-button manage-policy-button" onClick={removecookies}>Manage polices</Link>
            </div>
          </div>
          <div  className="admin-policy-card ">
            <div>
              <h4>Travel Policy</h4>
              <Travel />
              <p>
              For insurance on vehicles ranging from f...
              </p>
                <Link to='travel/all' className="admin-policy-button" onClick={removecookies}>View polices</Link>
                <Link to='travel' className="admin-policy-button manage-policy-button" onClick={removecookies}>Manage polices</Link>
            </div>
          </div>
          <div className="admin-policy-card">
            <div>
              <h4>Gadget Policy</h4>
              <Pet />
              <p>
              For insurance on vehicles ranging from f...
              </p>
                <Link to='gadget/all' className="admin-policy-button" onClick={removecookies}>View polices</Link>
                <Link to='gadget' className="admin-policy-button manage-policy-button" onClick={removecookies}>Manage polices</Link>
            </div>
          </div>
        </div>
    </div>
  );
}

export default Policy;