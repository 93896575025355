import React, { useState } from 'react';
import { Icon } from 'iconsax-react'; // Replace 'some-library' with the actual library you're using for icons
import '../../Stylesheets/Searchinput.css';

const DSelectSearch = ({
                           modelValue,
                           placeholder,
                           loading,
                           id,
                           icon,
                           disabled = false,
                           invalid = false,
                           error = false,
                           options = [],
                           data = [],
                           label = '',
                           selectedValue = '',
                           updateModelValue,
                           updateCustomer,
                       }) => {
    const [searchTerm, setSearchTerm] = useState(selectedValue);
    const [showDropdown, setShowDropdown] = useState(false);

    const search = () => {
        updateModelValue(searchTerm);

        setShowDropdown(true);
    };

    const selectData = (option) => {
        console.log(option)
        setSearchTerm(
            option.firstname !== undefined
                ? `${option.firstname} ${option.lastname}`
                : option.account_name !== undefined
                    ? option.account_name
                    : option.name
        );
        setShowDropdown(false);
        updateCustomer(option);
    };

    const onChange = () => {
        console.log(searchTerm)
        // Perform any necessary actions on change
    };

    const searchitem = () => {
        setShowDropdown(true);
    };

    return (
        <div className="position-relative">
            {icon && (
                <div className="position-absolute top-0 start-0 d-flex align-items-center pl-3 pointer-events-none">
                    <Icon size={20} color="#5584FF" className={`${invalid || error ? 'text-danger' : ''}`}
                          type="twotone"/>
                </div>
            )}

            {label && modelValue.length > 0 && (
                <span className="position-absolute text-xs px-2 bg-white top-neg-2 start-8 text-gray-500">{label}</span>
            )}

            <div
                className="border border-gray-300 text-gray-900 text-sm rounded-2 focus-ring focus-border d-flex w-100 p-2.5">
                <input
                    type="text"
                    className="w-100 p-2.5 border-0 searchselect"
                    placeholder={placeholder}
                    id={id}
                    disabled={disabled}
                    onClick={searchitem}
                    value={searchTerm}
                    onChange={(e) => {
                        setSearchTerm(e.target.value);
                        onChange();
                    }}
                />

                {loading ? (
                    <svg
                        className="w-6 mx-3 my-2 text-white right-0"
                        version="1.1"
                        id="loader-1"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 50 50"
                        style={{enableBackground: 'new 0 0 50 50', width: '30px', height: '30px'}}
                    >
                        <path
                            fill="#4a85ff"
                            d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z"
                        >
                            <animateTransform
                                attributeType="xml"
                                attributeName="transform"
                                type="rotate"
                                from="0 25 25"
                                to="360 25 25"
                                dur="0.6s"
                                repeatCount="indefinite"
                            ></animateTransform>
                        </path>
                    </svg>
                ) : (
                    <svg
                        onClick={search}
                        className="w-6 mx-3 text-white right-0 my-2 cursor-pointer"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 30 30"
                        width="30px"
                        height="30px"
                    >
                        <path
                            d="M 13 3 C 7.4889971 3 3 7.4889971 3 13 C 3 18.511003 7.4889971 23 13 23 C 15.396508 23 17.597385 22.148986 19.322266 20.736328 L 25.292969 26.707031 A 1.0001 1.0001 0 1 0 26.707031 25.292969 L 20.736328 19.322266 C 22.148986 17.597385 23 15.396508 23 13 C 23 7.4889971 18.511003 3 13 3 z M 13 5 C 17.430123 5 21 8.5698774 21 13 C 21 17.430123 17.430123 21 13 21 C 8.5698774 21 5 17.430123 5 13 C 5 8.5698774 8.5698774 5 13 5 z"/>
                    </svg>
                )}
            </div>
            <div className="position-absolute w-100 mt-2 bg-white rounded-lg shadow-lg"
                 style={{display: data.length && showDropdown ? 'block' : 'none', zIndex: 100}}>
                <ul className={`py-2 list-unstyled ${data.length > 2 ? 'overflow-scroll' : ''}`}
                    style={{maxHeight: data.length > 2 ? '150px' : ''}}>
                    {data.map((result, index) => (
                        <li key={index} className="hover-bg-gray-100/25 dropdownhovernew">
                            <button onClick={() => selectData(result)}
                                    className="border-0 w-100 text-gray-800 px-4 py-2 text-start small dropdownhoverbutton">
                                {result.firstname === undefined && result.account_name === undefined ? result.name : ''}{' '}
                                {result.firstname === undefined ? result.account_name : result.firstname}{' '}
                                {result.lastname === undefined ? result.bank_name : result.lastname} -{' '}
                                {result.lastname === undefined ? '' : result.email}
                            </button>
                        </li>
                    ))}
                </ul>
            </div>
            <p className='text-danger small fst-italic'>{error}</p>
        </div>
    );
};

export default DSelectSearch;
